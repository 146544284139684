import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const pet24OrgIdSchema = {
  externalOrgId: yup.string(),
};

function Pet24OrgIdPartial() {
  return (
    <TextFieldElement
      name="externalOrgId"
      fullWidth
      label="24Pet Org ID (optional)"
      variant="outlined"
      placeholder="Eg. USTX112"
      InputLabelProps={{ shrink: true }}
    />
  );
}
export default Pet24OrgIdPartial;
