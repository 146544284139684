import { Box, Grid, Typography } from '@mui/material';
import { TableRowsOutlined } from '@mui/icons-material';
import React from 'react';

export default function SmallScreenPlaceholder({ text = 'To view the data table, access Petcademy on a larger screen' }) {
  return (
    <Grid
      item
      xs={12}
      lg={0}
      sx={{
        display: {
          xs: 'block',
          md: 'none',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TableRowsOutlined sx={{ color: 'primary.main', mr: 2 }} />
        <Typography color="primary.main" variant="body1" display="inline" component="span">
          {text}
        </Typography>
      </Box>
    </Grid>
  );
}
