import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Drawer } from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const closedMixin = (theme: any) => ({
  width: 'calc(3.5rem + 1px)',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }: any) => ({
    width: 'calc(3.5rem + 1px)',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        visibility: 'visible !important',
        transform: 'translateX(0) !important',
      },
    }),
    ...(!open && {
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        visibility: 'visible !important',
        transform: 'translateX(0) !important',
      },
    }),
  }),
);

export default function CustomDrawer(props: any) {
  const {
    desktop,
    children,
    variant,
    open,
    onClose,
    onMouseEnter,
    onMouseLeave,
  } = props;
  if (desktop) {
    return (
      <DesktopDrawer
        PaperProps={{
          sx: {
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            border: 'none',
            width: 'inherit',
          },
        }}
        variant={variant}
        open={open}
        onClose={onClose}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </DesktopDrawer>
    );
  }
  return (
    <Drawer
      PaperProps={{
        sx: {
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          border: 'none',
          width: 'inherit',
          py: 7,
        },
      }}
      variant={variant}
      open={open}
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
}
