import React from 'react';
import Typography from '@mui/material/Typography';
import {
  AppBar, Box, Grid, Toolbar,
} from '@mui/material';
import Copyright from '../Copyright';

function ForgotPasswordSubmission() {
  return (
    <Grid container columns={10} component="main" sx={{ height: '100vh' }}>
      <AppBar
        elevation={0}
        sx={{
          bgcolor: 'primary.dark',
          display: {
            xs: 'block',
            sm: 'none',
          },
          top: 0,
          position: 'absolute',
        }}
      >
        <Toolbar>
          <Typography variant="h6" fontWeight="bold">Reset Password</Typography>
        </Toolbar>
      </AppBar>
      <Grid
        item
        sm={4}
        sx={{
          bgcolor: 'primary.dark',
        }}
      >
        <Box sx={{
          height: '93vh',
          pt: {
            xs: 12,
            sm: '25vh',
          },
          pl: {
            xs: 2,
            sm: '33%',
          },
          pr: 2,
        }}
        >
          <Typography
            display="inline"
            color="secondary.main"
            sx={{
              typography: {
                xs: 'h6bold',
                sm: 'h4bold',
              },
            }}
          >
            Check your email
          </Typography>
          <Typography
            display="inline"
            color="white"
            sx={{
              typography: {
                xs: 'h6bold',
                sm: 'h4bold',
              },
            }}
          >
            {' '}
            for password reset instructions 🐱🐶
          </Typography>
        </Box>
        <Box sx={{ height: '5vh', pb: '3vh', pl: '33%' }}>
          <Copyright color="white" />
        </Box>
      </Grid>
      <Grid
        item
        xs={10}
        sm={6}
        sx={{
          bgcolor: 'primary.dark',
        }}
      />
    </Grid>
  );
}

export default ForgotPasswordSubmission;
