import React from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import EnhancedTableHeader from '../EnhancedTableHeader';
import TablePaginationActions from '../TablePaginationActions';
import { GET_AWO_REPORTS } from '../../queries';
import SmallScreenPlaceholder from '../SmallScreenPlaceholder';

interface AwoReport {
  id: string;
  dateCreated: string;
  documentId: string;
  pdfMonkeyUrl: {
    url: string;
  };
}

interface AwoReportsCount {
  count: number;
}

const headCells = [{
  id: 'date_created',
  label: 'Date',
  width: '50%',
}, {
  id: 'title',
  label: 'Title',
  width: '50%',
  disableSorting: true,
}] as const;

const ROWS_PER_PAGE = 12;

function ReportsPanel() {
  const [filters, setFilters] = React.useState({
    limit: ROWS_PER_PAGE,
    offset: 0,
    order: 'desc',
    orderBy: 'date_created',
  });
  const { loading, data } = useQuery<{ awoReports: AwoReport[], awoReportsCount: AwoReportsCount }>(GET_AWO_REPORTS, {
    variables: filters,
  });

  const handleChangeOrder = (_:never, field: 'date_created') => {
    const isAsc = filters.orderBy === field && filters.order === 'asc';
    setFilters((prevState) => ({
      ...prevState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: field,
    }));
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setFilters((prevState) => ({
      ...prevState,
      limit: newRowsPerPage,
      offset: 0,
    }));
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setFilters((prevState) => ({
      ...prevState,
      offset: ROWS_PER_PAGE * page,
    }));
  };

  return (
    <>
      <Grid
        xs={0}
        lg={12}
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <Box sx={{ maxWidth: 768, margin: '0 auto' }}>
          <TableContainer>
            <Table aria-label="Reports Table">
              <EnhancedTableHeader
                headCells={headCells}
                order={filters.order}
                orderBy={filters.orderBy}
                handleChangeOrder={handleChangeOrder}
              />
              <TableBody>
                {
                  loading
                    ? <tr><td aria-label="Loading progress" colSpan={8}><LinearProgress /></td></tr>
                    : null
                }
                {
                  !loading && data?.awoReports.map((report) => (
                    <TableRow
                      key={report.id}
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => window.location.assign(report.pdfMonkeyUrl.url)}
                    >
                      <TableCell>{dayjs(report.dateCreated).format('M/D/YY')}</TableCell>
                      <TableCell>{`Monthly Engagement Report - ${dayjs(report.dateCreated).subtract(1, 'day').format('MMMM YYYY')}`}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={8}
                    rowsPerPageOptions={[ROWS_PER_PAGE]}
                    count={data?.awoReportsCount?.count || 0}
                    rowsPerPage={ROWS_PER_PAGE}
                    page={filters.offset / ROWS_PER_PAGE}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <SmallScreenPlaceholder />
    </>
  );
}

export default ReportsPanel;
