/* eslint no-unsafe-optional-chaining: 0 */
import React from 'react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useLazyQuery } from '@apollo/client';
import {
  HighlightOffOutlined,
  MarkChatReadOutlined,
  MarkEmailReadOutlined,
  PetsOutlined, SendOutlined,
  TaskOutlined,
  ThumbsUpDownOutlined,
  VolunteerActivismOutlined,
} from '@mui/icons-material';
import { Grid, Skeleton } from '@mui/material';
import { intersection, round } from 'lodash';
import { GET_DASHBOARD_STATS, GET_PRE_SURRENDER_DASHBOARD_STATS, GET_CO_SHELTERING_DASHBOARD_STATS } from '../../queries';
import { beforeCapture, getRate } from '../../utilities';
import DashboardStatCard from './DashboardStatCard';
import ErrorMessage from '../ErrorMessage';
import { useAwoContext } from '../AwoContextProvider';

function DashboardStats({ triggerRefetch, setRefetch, careType }) {
  const { awo, loading: awoLoading } = useAwoContext();
  const isPreSurrender = awo?.preSurrenderDashboard;
  const isCoSheltering = awo?.coSheltering;
  const awoStartDate = dayjs(awo?.dateCreated);
  const showEngagementRate = dayjs().diff(awoStartDate, 'day') > 30;

  let dashboardQuery = GET_DASHBOARD_STATS;
  if (isPreSurrender) {
    dashboardQuery = GET_PRE_SURRENDER_DASHBOARD_STATS;
  }
  if (isCoSheltering) {
    dashboardQuery = GET_CO_SHELTERING_DASHBOARD_STATS;
  }

  const [getDashboardStats, {
    loading, error, data, refetch,
  }] = useLazyQuery(dashboardQuery, {
    skip: awoLoading,
    variables: { careType },
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    getDashboardStats();
  }, [getDashboardStats]);

  React.useEffect(() => {
    if (triggerRefetch) {
      refetch({ variables: { careType } });
      setRefetch(false);
    }
  }, [triggerRefetch, refetch, setRefetch, careType]);

  if (error) throw error;

  const activeCategories = [
    (!isPreSurrender && !isCoSheltering && !data?.nps?.score) && 'general',
    isPreSurrender && 'pre-surrender',
    isCoSheltering && 'co-sheltering',
    data?.nps?.score && 'nps',
  ];
  const checkVisibility = (categories) => !!intersection(categories, activeCategories).length;
  const noFoster = !awo.catFoster && !awo.dogFoster && !awo.kittenFoster;

  const {
    count: tasks = 0,
  } = data?.taskCount || {};
  const {
    adoptersEngaged: adopters = 0,
    fostersEngaged: fosters = 0,
    totalEngaged = 0,
  } = data?.currentMonthEngagementStats || {};
  const {
    adoptersEngaged: prevAdopters = 0,
    fostersEngaged: prevFosters = 0,
    totalEngaged: prevTotalEngaged = 0,
  } = data?.previousMonthEngagementStats || {};
  const {
    invitedCount: dogSupport = 0,
  } = data?.dogSupportStats || {};
  const {
    invitedCount: prevDogSupport = 0,
  } = data?.previousMonthDogSupportStats || {};
  const {
    invitedCount: catSupport = 0,
  } = data?.catSupportStats || {};
  const {
    invitedCount: prevCatSupport = 0,
  } = data?.previousMonthCatSupportStats || {};
  const {
    invitedCount: support = 0,
    optOutCount: optOut = 0,
    responsiveCount: responses = 0,
  } = data?.supportStats || data?.totalSupportStats || {};
  const {
    invitedCount: prevSupport = 0,
    optOutCount: prevOptOut = 0,
    responsiveCount: prevResponses = 0,
  } = data?.previousMonthSupportStats || data?.totalPreviousMonthSupportStats || {};

  const cards = [
    {
      title: 'Open Tasks',
      text: loading ? <Skeleton /> : `${tasks === 0 ? 'No' : tasks.toLocaleString()} tasks`,
      subtextLabel: 'Pet parent feedback for review',
      icon: <TaskOutlined />,
      href: !isCoSheltering ? '/feedback' : null,
      outline: true,
      tooltipTitle: 'Pet parent actions and feedback that should be reviewed',
      visible: true,
    },
    {
      title: isCoSheltering ? 'Clients Receiving Support' : 'Pet Parents Receiving Support',
      text: loading ? <Skeleton /> : `${support.toLocaleString()} total`,
      subtextNumber: loading
        ? undefined
        : getRate(prevSupport, support),
      subtextLabel: loading ? '' : 'from last period',
      icon: <VolunteerActivismOutlined />,
      tooltipTitle: isCoSheltering ? 'Clients currently within the 30-day support period' : 'Pet parents currently within the 30-day support period',
      visible: true,
    },
    {
      title: 'Dog Parents Receiving Support',
      text: loading ? <Skeleton /> : `${dogSupport.toLocaleString()} dogs`,
      subtextNumber: loading
        ? undefined
        : getRate(prevDogSupport, dogSupport),
      subtextLabel: loading ? '' : 'from last period',
      icon: <PetsOutlined />,
      tooltipTitle: 'Dog parents currently within the 30-day support period',
      visible: checkVisibility(['pre-surrender']),
    },
    {
      title: 'Cat Parents Receiving Support',
      text: loading ? <Skeleton /> : `${catSupport.toLocaleString()} cats`,
      subtextNumber: loading
        ? undefined
        : getRate(prevCatSupport, catSupport),
      subtextLabel: loading ? '' : 'from last period',
      icon: <PetsOutlined />,
      tooltipTitle: 'Cat parents currently within the 30-day support period',
      visible: checkVisibility(['pre-surrender']),
    },
    {
      title: 'Engagement Rate',
      text: loading ? <Skeleton /> : `${round(totalEngaged * 100)}%`,
      subtextNumber: loading
        ? undefined
        : getRate(
          round(prevTotalEngaged * 100),
          round(totalEngaged * 100),
        ),
      subtextLabel: loading ? '' : 'from last period',
      icon: <SendOutlined />,
      tooltipTitle: 'Percentage of pet parents who are within the 30-day support period and have engaged with Petcademy via SMS, email, or online courses',
      visible: checkVisibility(['pre-surrender']),
    },
    {
      title: 'Engagement Rate: Adopters',
      // eslint-disable-next-line no-nested-ternary
      text: loading ? <Skeleton /> : (showEngagementRate ? `${round(adopters * 100)}%` : '-'),
      subtextNumber: loading || !showEngagementRate
        ? undefined
        : getRate(
          round(prevAdopters * 100),
          round(adopters * 100),
        ),
      subtextLabel: loading || !showEngagementRate ? '' : 'from last period',
      icon: <MarkEmailReadOutlined />,
      tooltipTitle: 'The percentage of adopters who have completed the program in the last 30 days and interacted with Petcademy via text message, email, or through our online courses',
      visible: checkVisibility(['general', 'nps']),
    },
    {
      title: 'Engagement Rate: Fosters',
      // eslint-disable-next-line no-nested-ternary
      text: loading ? <Skeleton /> : (showEngagementRate ? `${round(fosters * 100)}%` : '-'),
      subtextNumber: loading || !showEngagementRate
        ? undefined
        : getRate(
          round(prevFosters * 100),
          round(fosters * 100),
        ),
      subtextLabel: loading || !showEngagementRate ? '' : 'from last period',
      icon: <MarkEmailReadOutlined />,
      tooltipTitle: 'The percentage of fosters who have completed the program in the last 30 days and interacted with Petcademy via text message, email, or through our online courses',
      visible: checkVisibility(['general', 'nps']) && !noFoster,
    },
    {
      title: 'Unresponsive within 7 days of start',
      text: loading ? <Skeleton /> : `${Number.isNaN(round(((support - responses) / support) * 100))
        ? 0
        : round(((support - responses) / support) * 100)}%`,
      subtextNumber: loading
        ? undefined
        : getRate(
          round(((prevSupport - prevResponses) / prevSupport) * 100),
          round(((support - responses) / support) * 100),
        ),
      subtextLabel: loading ? '' : 'from last period',
      icon: <HighlightOffOutlined />,
      tooltipTitle: 'Percentage of pre-surrender pet parents who do not engage with Petcademy via SMS',
      visible: checkVisibility(['pre-surrender']),
    },
    {
      title: 'SMS Opt-In Rate',
      text: loading ? <Skeleton /> : `${Math.abs(getRate(support, optOut))}%`,
      subtextNumber: loading
        ? undefined
        : getRate(
          Math.abs(getRate(prevSupport, prevOptOut)),
          Math.abs(getRate(support, optOut)),
        ),
      subtextLabel: loading ? '' : 'from last period',
      icon: <MarkChatReadOutlined />,
      tooltipTitle: 'Percentage of pet parents opting-in for Petcademy support via SMS during the 30-day support period',
      visible: !checkVisibility(['co-sheltering']),
    },
    {
      title: 'Net Promoter Score (NPS)',
      text: loading ? <Skeleton /> : `${data?.nps?.score} / 100 average`,
      subtextLabel: 'Measures pet parent satisfaction',
      icon: <ThumbsUpDownOutlined />,
      tooltipTitle: 'Measures pet parent satisfaction. A score above 70 is amazing!',
      visible: checkVisibility(['nps']),
    },
  ];

  const gridSystem = (idx) => {
    if (isPreSurrender) {
      return idx < 4 ? 3 : 4;
    }
    if (isCoSheltering) {
      return 6;
    }
    return 4;
  };

  return (
    <Grid container spacing={3}>
      {cards.map((c, idx) => c.visible
        && (
          <Grid key={c.title} item xs={12} md={gridSystem(idx)}>
            <DashboardStatCard
              title={c.title}
              text={c.text}
              subtextNumber={c.subtextNumber}
              subtextLabel={c.subtextLabel}
              href={c.href}
              icon={c.icon}
              outline={c.outline}
              tooltipTitle={c.tooltipTitle}
            />
          </Grid>
        ))}
    </Grid>
  );
}

export default Sentry.withErrorBoundary(DashboardStats, { fallback: <ErrorMessage />, beforeCapture });
