import React from 'react';
import { Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { grey } from '@mui/material/colors';
import { Message } from '../../types/Message';
import { GET_MESSAGES } from '../../queries';
import StyledPaper from '../StyledPaper';

function MessageList({
  petParentId,
  petParentName,
  timezone,
}: { petParentId: string, petParentName: string, timezone: string }) {
  const { loading, data } = useQuery<{ messages: Message[] }>(GET_MESSAGES, {
    variables: {
      petParentId,
    },
  });

  if (loading || !data) return null;

  return (
    <>
      {data.messages.map((message, idx) => (
        <StyledPaper key={message.id} sx={{ mt: !idx ? 0 : 2 }} color={grey[50]}>
          <Typography variant="subtitle2" display="block">{message.direction === 'incoming' ? petParentName : 'Petcademy'}</Typography>
          <Typography
            sx={{
              position: 'absolute', right: 0, top: 0, m: 2,
            }}
            variant="caption"
          >
            {dayjs.utc(message.dateSent).tz(timezone).format('MM/DD/YYYY hh:mma z')}
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}>{message.text}</Typography>
        </StyledPaper>
      ))}
    </>
  );
}

export default MessageList;
