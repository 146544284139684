/* eslint-disable max-len */
import React from 'react';
import {
  Alert, AlertTitle, Box,
  Button, CardActions, CardContent, CardHeader, Dialog, Divider, Radio, Snackbar,
} from '@mui/material';
import { get, capitalize } from 'lodash';
import { useMutation } from '@apollo/client';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { PetParent } from '../../types/PetParent';
import { STOP_SUPPORT_UNSUBSCRIBE } from '../../queries';

type DialogProps = { open: boolean; setOpen: (state: boolean) => void; petParent: PetParent; };

function StopSupportDialog({
  open,
  setOpen,
  petParent,
}: DialogProps) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [checkedOption, setCheckedOption] = React.useState('stop_support');
  const [stopSupportUnsubscribe, { loading }] = useMutation(STOP_SUPPORT_UNSUBSCRIBE, {
    onCompleted: () => {
      setOpen(false);
    },
    onError: () => {
      setOpen(false);
      setSnackbarOpen(true);
      setSnackbarMessage('Error stopping support for pet parent');
    },
    refetchQueries: [
      'GetPetParent',
      'GetTypeformResponses',
    ],
  });

  const firstName = React.useMemo(() => capitalize(petParent.firstName), [petParent.firstName]);
  const lastName = React.useMemo(() => capitalize(petParent.lastName), [petParent.lastName]);

  const identity = useIdentityContext();
  const isAdmin = get(identity, 'user.app_metadata.roles[0]') === 'admin';
  const subheaders = [];
  const alerts = [];

  const animalString = petParent.activeConversation ? `${petParent.activeConversation.animal.name} (${petParent.activeConversation.animal.careType})` : '';
  const animalName = React.useMemo(() => capitalize(animalString), [animalString]);

  if (petParent.activeConversation) {
    subheaders.push(`${firstName} ${lastName} is currently receiving support for ${animalName}.`);
    alerts.push((
      <Alert
        sx={{ cursor: 'pointer' }}
        onClick={() => setCheckedOption('stop_support')}
        action={(
          <Radio
            checked={checkedOption === 'stop_support'}
            onChange={() => setCheckedOption('stop_support')}
            value="stop_support"
            name="support-radio"
            inputProps={{ 'aria-label': 'Stop radio button' }}
          />
        )}
        severity="info"
      >
        <AlertTitle>Stop support for this animal</AlertTitle>
        <Box>
          <p>{`Select this option if ${firstName} reports that they are no longer caring for or are not interested in receiving support for ${animalName}. This will turn off the email and SMS campaign related to ${animalName}.`}</p>
          <p>This cannot be undone.</p>
        </Box>
      </Alert>
    ));
  }
  if (isAdmin) {
    if (petParent.activeConversation) {
      subheaders.push('Select one option below and get approval if unsure.');
    } else {
      subheaders.push(`${firstName} ${lastName} does not have an active membership. As a result, your only option is to unsubscribe them from all future communication. Get approval if unsure.`);
    }
    alerts.push((
      <Alert
        sx={{ cursor: 'pointer' }}
        onClick={() => setCheckedOption('unsubscribe')}
        action={(
          <Radio
            checked={checkedOption === 'unsubscribe'}
            onChange={() => setCheckedOption('unsubscribe')}
            value="unsubscribe"
            name="support-radio"
            inputProps={{ 'aria-label': 'Unsubscribe radio button' }}
          />
      )}
        severity="warning"
      >
        <AlertTitle>Unsubscribe this pet parent</AlertTitle>
        <Box>
          <p>{`Select this option only if ${firstName} explicitly told Petcademy not to contact them again. This will stop all emails and SMS messages from ever being sent to ${firstName} unless they re-subscribe.`}</p>
          <p>This cannot be undone.</p>
        </Box>
      </Alert>
    ));
  }
  React.useEffect(() => {
    if (isAdmin && !petParent.activeConversation) {
      setCheckedOption('unsubscribe');
    }
  }, [isAdmin, petParent]);
  return (
    <>
      <Dialog
        open={open}
        scroll="paper"
        onClose={() => setOpen(false)}
        aria-labelledby="stop-support-dialog"
        aria-describedby="stop-support-dialog-description"
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '584px',
            maxWidth: '90vw',
          },
        }}
      >
        <CardHeader
          title="Stop support"
          sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.12)' }}
          subheader={subheaders.join('\n')}
        />
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {alerts}
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            variant="contained"
            disabled={!checkedOption || loading}
            onClick={() => stopSupportUnsubscribe({
              variables: {
                email: petParent.email,
                actionerEmail: get(identity, 'user.email'),
                action: checkedOption,
              },
            })}
          >
            Stop support
          </Button>
          <Button variant="text" onClick={() => setOpen(false)}>Cancel</Button>
        </CardActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        open={snackbarOpen}
        message={snackbarMessage}
        key={snackbarMessage}
      />
    </>
  );
}

export default StopSupportDialog;
