import React from 'react';
import {
  Box,
  Button,
  CardActions, CardContent,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

function ProfileFiltersDialog({
  open, setOpen, smsStatus, interests, setFilters,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="filter-dialog-title"
      aria-describedby="filter-dialog-description"
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: '90vw',
        },
      }}
    >
      <DialogTitle id="logout-dialog-title">
        <Typography variant="h6bold">Filters</Typography>
      </DialogTitle>
      <CardContent>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
          <FormGroup sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ color: 'secondary.text' }}>SMS Status</Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={smsStatus === 'optedIn'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, smsStatus: e.target.checked ? 'optedIn' : null }));
                  }}
                />
    )}
              label="Opted In"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={smsStatus === 'optedOut'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, smsStatus: e.target.checked ? 'optedOut' : null }));
                  }}
                />
    )}
              label="Opted Out"
            />
          </FormGroup>
          <FormGroup sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ color: 'secondary.text' }}>Interests</Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={interests === 'Donating'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, interests: e.target.checked ? 'Donating' : null }));
                  }}
                />
    )}
              label="Donating"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={interests === 'Fostering'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, interests: e.target.checked ? 'Fostering' : null }));
                  }}
                />
    )}
              label="Fostering"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={interests === 'Volunteering'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, interests: e.target.checked ? 'Volunteering' : null }));
                  }}
                />
    )}
              label="Volunteering"
            />
          </FormGroup>
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={() => setOpen(false)}>Apply Filters</Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </CardActions>
    </Dialog>
  );
}

export default ProfileFiltersDialog;
