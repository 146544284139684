import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const noteSchema = {
  note: yup.string(),
};

function NotePartial() {
  return (
    <TextFieldElement
      name="note"
      sx={{ marginTop: 2 }}
      fullWidth
      label="Note (Optional)"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
}
export default NotePartial;
