import React from 'react';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  Box, Grid, Paper, Skeleton, Tooltip, Typography,
} from '@mui/material';
import { get, isNaN, round } from 'lodash';
import { GET_COURSE_STATS } from '../../queries';
import { beforeCapture, PETCADEMY_SHADOW } from '../../utilities';
import ErrorMessage from '../ErrorMessage';

function CoursePerformanceCard({
  animalType, careType, daysAgo,
}) {
  const { loading, error, data } = useQuery(GET_COURSE_STATS, {
    variables: {
      careType,
      animalType,
      startDaysAgo: daysAgo >= 0 ? daysAgo * 2 : null,
      midDaysAgo: daysAgo >= 0 ? daysAgo : null,
      endDaysAgo: daysAgo >= 0 ? 0 : null,
    },
  });

  const humanizeDatePeriod = {
    7: 'previous week',
    30: 'previous month',
    180: 'previous 6 months',
    365: 'previous 12 months',
  };
  if (error) throw error;

  const handleNaN = (number) => (isNaN(number) ? 0 : number);

  const currentOnboardingPercent = get(data, 'current.onboardedCount', 0) / get(data, 'current.invitedCount', 0);
  const previousOnboardingPercent = get(data, 'previous.onboardedCount', 0) / get(data, 'previous.invitedCount', 0);
  const currentOnboardingRate = round(handleNaN(currentOnboardingPercent) * 100);
  const previousOnboardingRate = round(handleNaN(previousOnboardingPercent) * 100);
  const onboardingRateDiff = currentOnboardingRate - previousOnboardingRate;

  return (
    <Paper sx={{ p: 2 }} elevation={PETCADEMY_SHADOW}>
      <Grid rowSpacing={1} container>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{`${animalType} ${careType === 'Adopted' ? 'Adopters' : 'Fosters'} ${animalType === 'Dog' ? '🐶' : '🐱'}`}</Typography>
        </Grid>
        <Grid item container xs={12} columnSpacing={4} rowSpacing={2.5}>
          <Grid item xs={12} md={4}>
            <Tooltip title="Pet parents invited to take the course" placement="top-start" enterDelay={250} followCursor>
              <Box>
                <Typography variant="overline">Invited</Typography>
                {loading ? <Skeleton variant="text" width="50%" /> : <Typography variant="h6bold" component="h6">{data.current.invitedCount.toLocaleString()}</Typography>}
                {daysAgo === -1 || loading ? <br /> : <Typography variant="caption" sx={{ color: data.current.invitedCount - data.previous.invitedCount >= 0 ? 'success.main' : 'error.main' }}>{`vs. ${data.previous.invitedCount.toLocaleString()} ${humanizeDatePeriod[daysAgo]}`}</Typography>}
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="Pet parents who enrolled in the course" placement="top-start" enterDelay={250} followCursor>
              <Box>
                <Typography variant="overline">Enrolled</Typography>
                {loading ? <Skeleton variant="text" width="50%" /> : <Typography variant="h6bold" component="h6">{data.current.onboardedCount.toLocaleString()}</Typography>}
                {daysAgo === -1 || loading ? <br /> : <Typography variant="caption" sx={{ color: data.current.onboardedCount - data.previous.onboardedCount >= 0 ? 'success.main' : 'error.main' }}>{`vs. ${data.previous.onboardedCount.toLocaleString()} ${humanizeDatePeriod[daysAgo]}`}</Typography>}
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip title="Out of all invites sent, this is the percentage of pet parents that enrolled in the course" placement="top-start" enterDelay={250} followCursor>
              <Box>
                <Typography variant="overline">Enrollment Rate</Typography>
                {loading ? <Skeleton variant="text" width="50%" /> : <Typography variant="h6bold" component="h6">{`${currentOnboardingRate}%`}</Typography>}
                {daysAgo === -1 || loading ? <br /> : <Typography variant="caption" sx={{ color: onboardingRateDiff >= 0 ? 'success.main' : 'error.main' }}>{`${onboardingRateDiff >= 0 ? '+' : ''}${onboardingRateDiff}% from ${humanizeDatePeriod[daysAgo]}`}</Typography>}
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Sentry.withErrorBoundary(CoursePerformanceCard, { fallback: <ErrorMessage />, beforeCapture });
