import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

interface MediaDialogProps {
  dialogImg: string;
  dialogDate: string;
  imgDialogOpen: boolean;
  setImgDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function MediaDialog({
  dialogImg,
  dialogDate,
  imgDialogOpen,
  setImgDialogOpen,
}: MediaDialogProps) {
  return (
    <Dialog
      open={imgDialogOpen}
      onClose={() => setImgDialogOpen(false)}
      style={{ maxWidth: '100%', maxHeight: '100%' }}
      PaperProps={{
        style: {
          maxWidth: '800px',
          maxHeight: '100%',
        },
      }}
    >
      {dialogImg.includes('youtube') ? (
        <iframe
          style={{
            width: '100%',
            height: '100%',
            maxHeight: '88vh',
            maxWidth: '88vh',
          }}
          title="Video"
          src={dialogImg}
          frameBorder="0"
          allowFullScreen
        />
      ) : (
        <img
          style={{
            width: '100%',
            height: '100%',
            maxHeight: '88vh',
            maxWidth: '88vh',
          }}
          src={dialogImg}
          alt="a pet"
        />
      )}
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => setImgDialogOpen(false)}>Close</Button>
        <Typography variant="caption" sx={{ fontSize: '.875rem', lineHeight: '1.5', padding: '8px' }}>{dayjs(dialogDate).format('M/D/YYYY')}</Typography>
      </DialogActions>
    </Dialog>
  );
}

export default MediaDialog;
