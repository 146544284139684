import React from 'react';
import {
  Button, CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

function TaskFiltersDialog({
  open, setOpen, careType, setCareType, setAppliedCareType,
}: {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  careType: {
    adopted: boolean,
    fostered: boolean,
    preSurrender: boolean,
  } | null,
  setCareType: React.Dispatch<React.SetStateAction<{
    adopted: boolean,
    fostered: boolean,
    preSurrender: boolean,
  } | null>>,
  setAppliedCareType: React.Dispatch<React.SetStateAction<{
    adopted: boolean,
    fostered: boolean,
    preSurrender: boolean,
  } | null>>,
}) {
  const handleClose = () => {
    setOpen(false);
    setCareType(null);
  };

  const applyFilters = () => {
    setOpen(false);
    setAppliedCareType(careType);
    if (careType && Object.keys(careType).length > 0) {
      localStorage.setItem('notificationCareType', JSON.stringify(careType));
    } else {
      localStorage.removeItem('notificationCareType');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="filter-dialog-title"
      aria-describedby="filter-dialog-description"
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: '90vw',
        },
      }}
      maxWidth={false}
    >
      <DialogTitle id="logout-dialog-title">
        <Typography variant="h6bold">Filters</Typography>
      </DialogTitle>
      <CardContent>
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={careType?.adopted}
                onChange={(e) => setCareType((prev) => {
                  if (!prev) return null;
                  return { ...prev, adopted: e.target.checked };
                })}
              />
)}
            label="Adopted pets"
          />
          <FormControlLabel
            componentsProps={{ typography: { mr: 20 } }}
            control={(
              <Checkbox
                checked={careType?.fostered}
                onChange={(e) => setCareType((prev) => {
                  if (!prev) return null;
                  return { ...prev, fostered: e.target.checked };
                })}
              />
)}
            label="Fostered pets"
          />
          <FormControlLabel
            componentsProps={{ typography: { mr: 20 } }}
            control={(
              <Checkbox
                checked={careType?.preSurrender}
                onChange={(e) => setCareType((prev) => {
                  if (!prev) return null;
                  return { ...prev, preSurrender: e.target.checked };
                })}
              />
)}
            label="Pets at risk of surrender"
          />
        </FormGroup>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={applyFilters}>Save Changes</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </CardActions>
    </Dialog>
  );
}

export default TaskFiltersDialog;
