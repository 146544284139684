import React from 'react';
import {
  Box, Card, CardHeader, Typography,
} from '@mui/material';
import { colors } from '../../theme';

function OutlinedIconBadge({
  icon,
  title,
  subheader,
  background,
  iconBackground,
  iconColor,
  iconOpacity,
  onClick,
}: {
  icon: any;
  title: string;
  subheader: string;
  background: string;
  iconBackground: string;
  iconColor: string;
  iconOpacity: number;
  onClick: () => null;
}) {
  return (
    <Card variant="outlined" sx={{ border: 'none', background }} onClick={onClick}>
      <CardHeader
        avatar={(
          <Box sx={{
            background: iconBackground ?? colors.grey['200'],
            display: 'flex',
            width: '40px',
            height: '40px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
          }}
          >
            <Box sx={{ opacity: iconOpacity ?? 0.56, lineHeight: 0, color: iconColor }}>
              {icon}
            </Box>
          </Box>
        )}
        title={(
          <>
            <Typography variant="body1">{title}</Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.60)">{subheader}</Typography>
          </>
        )}
      />
    </Card>
  );
}

export default OutlinedIconBadge;
