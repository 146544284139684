import { createTheme } from '@mui/material/styles';
import LinkBehavior from './components/LinkBehavior';

export const cardShadow = {
  boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
};

export const colors = {
  primary: {
    text: 'rgba(0, 0, 0, 0.87)',
    textBlack: '#000',
    light: '#985EFF',
    50: '#F2E7FE',
    100: '#DBB2FF',
    200: '#BB86FC',
    300: '#985EFF',
    400: '#7F39FB',
    500: '#6200EE',
    600: '#5600E8',
    main: '#3700B3',
    800: '#30009C',
    dark: '#23036A',
  },
  secondary: {
    text: 'rgba(0, 0, 0, 0.6)',
    textContrastDark: '#fff',
    states: {
      outlinerBorder: 'rgba(1, 162, 153, 0.5)',
    },
    50: '#C8FFF4',
    100: '#70EFDE',
    200: '#03DAC6',
    300: '#00C4B4',
    400: '#00B3A6',
    main: '#01A299',
    600: '#019592',
    700: '#018786',
    800: '#017374',
    dark: '#005457',
  },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
  },
  black: {
    main: '#000',
    light: '#000',
    dark: '#000',
    contrastText: '#000',
    disabledText: 'rgba(0, 0, 0, 0.38)',
  },
};

export const nextTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      x200: 200,
      sm: 420,
      x400: 400,
      x600: 600,
      md: 768,
      x800: 800,
      x1000: 1000,
      x1200: 1200,
      lg: 1200,
      x1400: 1400,
      xl: 1536,
      x1600: 1600,
      x1800: 1800,
    },
  },
  palette: {
    ...colors,
    tooltip: {
      fill: '#3700B3E5',
    },
    error: {
      main: '#B00020',
      light: '#D32F2F',
    },
    disabled: {
      textContrastLight: 'rgba(0, 0, 0, 0.38)',
    },
    info: {
      main: '#0288D1',
    },
    warning: {
      main: 'rgba(237, 108, 2, 1)',
    },
    alert: {
      warningFill: 'rgba(253, 240, 230, 1)',
    },
  },
  typography: {
    h1bold: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 900,
      fontSize: '3rem',
      lineHeight: 1.5,
    },
    h2bold: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 800,
      fontSize: '2.5rem',
      lineHeight: 1.5,
    },
    h3bold: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.5,
    },
    h4bold: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 400,
      fontSize: '1.875rem',
      lineHeight: 1.235,
      letterSpacing: '0.01563rem',
    },
    h5bold: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.33,
    },
    h6bold: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.00938rem',
    },
    subtitle1: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938rem',
    },
    subtitle2: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00625rem',
    },
    body1: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938rem',
    },
    body2: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01063rem',
    },
    caption: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.025rem',
    },
    overline: {
      fontSize: '0.625rem',
      fontWeight: 500,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: colors.primary.textBlack,
    },
    sectionTitle: {
      fontFamily: '"Rasa","Helvetica","Arial",sans-serif',
      fontSize: '1.75rem',
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: '0.00938rem',
    },
  },
  components: {
    // old styles
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        invisible: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      },
    },
    MuiImageList: {
      styleOverrides: {
        root: {
          overflowY: 'hidden',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': {
            paddingBottom: '1rem',
          },
        },
      },
    },
    // new styles
    MuiFormLabel: {
      styleOverrides: {
        root: () => ({
          color: colors.secondary.text,
          lineHeight: '166%',
          letterSpacing: '0.4px',
          display: 'block',
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: '0px 9px',
          ...(
            ownerState.color === 'error' && {
              color: '#B00020',
              '& .MuiRadio-root': {
                color: '#B00020',
              },
            }),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(
            ownerState.color === 'error' && {
              color: '#B00020',
              '& .MuiFormLabel-root': {
                color: '#B00020',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B00020 !important',
              },
            }),
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginRight: '14px',
          marginTop: '3px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          '& .MuiCardHeader-title': {
            fontSize: '24px',
          },
          '& .MuiCardHeader-subheader': {
            fontSize: '12px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: '8px 16px',
        },
        root: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        head: {
          fontWeight: 500,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-outlined': {
            color: colors.primary.main,
            textTransform: 'uppercase',
            fontSize: '0.8125rem',
            fontWeight: 500,
            svg: {
              color: colors.primary.main,
            },
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.primary.main,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(58, 0, 214, 0.5)',
          },
          '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
            minHeight: 'auto',
            padding: '4px 9px',
            paddingRight: '36px',
            lineHeight: '1.75',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          '& .MuiTablePagination-selectLabel': {
            fontSize: '0.75rem',
            color: colors.secondary.text,
          },
          '& .MuiTablePagination-displayedRows': {
            fontSize: '0.75rem',
          },
          '& .MuiInputBase-root': {
            marginRight: '1.5rem',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'unset',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1bold: 'h1',
          h2bold: 'h2',
          h3bold: 'h3',
          h4bold: 'h4',
          h5bold: 'h5',
          h6bold: 'h6',
          sectionTitle: 'h2',
        },
      },
    },
  },
});

nextTheme.shadows.push('0px 0px 20px 0px #462CD51A');

export default nextTheme;
