import React from 'react';
import {
  IconButton, Tab, Tabs, TabProps, Box,
} from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import CustomContainer from './CustomContainer';

mixpanel.init('1753030ad5be742490513cb47e277e47', {
  debug: true,
  track_pageview: 'url-with-path',
});

function a11yProps(name: string) {
  return {
    id: `hub-tab-${name}`,
    'aria-controls': `hub-tabpanel-${name}`,
  };
}

function HubTab({ label, selected, ...props }: TabProps & { label: string, selected?: boolean }) {
  return (
    <Tab
      sx={{
        minWidth: 0, py: 1.5, px: 2, minHeight: 0, ...(selected ? { color: '#3700B3' } : {}),
      }}
      label={label}
      {...a11yProps(label)}
      {...props}
    />
  );
}

const tabsLinks = ['/hub/home', '/hub/my-pets', '/hub/ask'];

function HubContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const activeTab = tabsLinks.indexOf(location.pathname);
  const [tabValue, setTabValue] = React.useState(activeTab);
  const handleTabChange = (index: number) => {
    setTabValue(index);
    navigate(tabsLinks[index]);
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {
        activeTab >= 0 && (
          <Box>
            <CustomContainer>
              <Box sx={{ py: 1, mx: -1 }}>
                <IconButton
                  sx={{
                    borderRadius: '6px',
                    m: 0,
                  }}
                  edge="start"
                  color="inherit"
                  onClick={() => handleTabChange(0)}
                >
                  <img style={{}} alt="Petcademy Logo" src="/logo_hub.svg" />
                </IconButton>
              </Box>
            </CustomContainer>
            <CustomContainer>
              <Tabs sx={{ minHeight: 0, mx: -2 }} value={tabValue} onChange={(_, index) => handleTabChange(index)} aria-label="hub sections">
                <HubTab label="Home" />
                <HubTab label="My Pets" />
                <HubTab label="Ask a Trainer" />
              </Tabs>
            </CustomContainer>
          </Box>
        )
      }
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
      {
        activeTab >= 0 && (
          <Box
            component="footer"
            sx={{
              pt: 6, pb: 10, display: 'flex', justifyContent: 'center',
            }}
          >
            <img style={{ opacity: 0.55, filter: 'grayscale(100%)' }} alt="Petcademy Logo Footer" src="/logo_hub.svg" />
          </Box>
        )
      }
    </Box>
  );
}

export default HubContainer;
