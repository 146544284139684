import React from 'react';
import {
  FormControlLabel, FormHelperText, Radio, RadioGroup,
} from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';
import { RadioControl } from '../FormControls';

export const rescueTypeSchema = {
  rescueType: yup.string().required('Rescue type is required'),
};

function RescueTypePartial(
  { control, errors }:
    {
      control: Control<any, any>,
      errors: FieldErrors<{ rescueType: string | null }>
    },
) {
  return (
    <RadioControl
      fieldName="rescueType"
      control={control}
      render={({ onChange, ...props }) => (
        <>
          {errors.rescueType && (
          <FormHelperText
            style={{ marginLeft: 0, marginRight: 0 }}
            error
          >
            {errors.rescueType?.message}
          </FormHelperText>
            )}
          <RadioGroup
            {...props}
            onChange={(event, value) => onChange({ target: { name: 'rescueType', value } })}
            aria-labelledby="rescues-radio-group-label"
            row
            sx={{ justifyContent: 'space-between' }}
          >
            <FormControlLabel
              color={errors.rescueType ? 'error' : 'primary'}
              sx={{ flexGrow: 1 }}
              value="Rescue"
              control={<Radio />}
              label="Rescue"
            />
            <FormControlLabel
              color={errors.rescueType ? 'error' : 'primary'}
              sx={{ flexGrow: 1 }}
              value="Shelter"
              control={<Radio />}
              label="Shelter"
            />
          </RadioGroup>
        </>
        )}
    />
  );
}
export default RescueTypePartial;
