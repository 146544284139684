import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Box, ImageList, ImageListItem, ImageListItemBar, Skeleton, Typography,
} from '@mui/material';
import { GET_ANIMALS } from '../../queries';
import { Animal } from '../../types/Animal';
import AwoAutocomplete, { AutocompleteAwo } from '../AwoAutocomplete';

function PhotosContainer() {
  const [photosAwo, setPhotosAwo] = React.useState<AutocompleteAwo | null>(null);
  const { loading, error, data } = useQuery<{ animals: Animal[] }>(GET_ANIMALS, {
    variables: {
      awoId: photosAwo === null || photosAwo.id === null ? null : parseInt(photosAwo.id, 10),
    },
  });

  if (error) return `Error! ${error.message}`;

  return (
    <Box sx={{
      height: '100vh',
      position: 'relative',
      overflow: 'auto',
    }}
    >
      <Box
        sx={{
          paddingY: 2,
          paddingX: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <Typography variant="overline">Photos</Typography>
        <Box sx={{ width: 320 }}>
          <AwoAutocomplete
            defaultName="All"
            selectedAwo={photosAwo}
            setAwo={setPhotosAwo}
            size="small"
            onlyPhotoAwos
            shrink
          />
        </Box>
      </Box>
      <Box sx={{
        padding: 3,
      }}
      >
        {
          loading && (
            <ImageList variant="quilted" cols={4} gap={8} sx={{ width: '100%' }}>
              {
                Array.from(new Array(12)).map(() => (
                  <Skeleton key={Math.random()} sx={{ aspectRatio: '1/1' }} height="auto" variant="rectangular" width="100%" />
                ))
              }
            </ImageList>
          )
        }
        {
          !loading && data?.animals && (
            <ImageList variant="quilted" cols={4} gap={8} sx={{ width: '100%' }}>
              {data.animals.map((a) => (
                <React.Fragment key={a.id}>
                  {a.animalMedias.map((am) => (
                    <ImageListItem sx={{ aspectRatio: '1/1', backgroundColor: '#ccc' }} key={am.url}>
                      <img
                        src={am.url}
                        alt={a.name}
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title={a.name}
                        subtitle={a.awoName}
                      />
                    </ImageListItem>
                  ))}
                </React.Fragment>
              ))}
            </ImageList>
          )
        }
      </Box>
    </Box>
  );
}

export default PhotosContainer;
