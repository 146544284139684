import React from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow,
} from '@mui/material';
import { capitalize } from 'lodash';
import moment from 'moment';
import { getCourse, getEnrollment } from '../utilities';
import EnhancedTableHeader from './EnhancedTableHeader';
import TablePaginationActions from './TablePaginationActions';

function CoursesTable({
  rowsPerPage, rowsPerPageOptions, page, count, orderBy, order, handleChangeRowsPerPage, handleChangePage,
  handleChangeOrder, loading, petParents, networkStatus, setDrawerOpen, setUserId,
}) {
  const headCells = [{
    id: 'first_name',
    width: '10%',
    label: 'First Name',
  }, {
    id: 'last_name',
    width: '10%',
    label: 'Last Name',
  }, {
    id: 'email',
    width: '15%',
    label: 'Email',
  }, {
    id: 'course',
    width: '17.5%',
    label: 'Course',
    disableSorting: true,
  }, {
    id: 'mailchimp_sync_date',
    width: '10%',
    label: 'Date Invited',
  }, {
    id: 'course_status',
    width: '12.5%',
    label: 'Course Status',
    disableSorting: true,
  }];

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: '500px' }} aria-label="Courses Table" size="small">
        <EnhancedTableHeader
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          handleChangeOrder={handleChangeOrder}
        />
        {loading || networkStatus === NetworkStatus.refetch ? <LinearProgress sx={{ display: 'table-header-group' }} /> : null}
        <TableBody>
          {!loading && petParents.map((row) => (
            <TableRow
              key={`${row.id}-${row.animalType}-${row.careType}`}
              hover
              onClick={() => {
                setDrawerOpen(true);
                setUserId(row.id);
              }}
            >
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {capitalize(row.firstName)}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {capitalize(row.lastName)}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {row.email.toLowerCase()}
              </TableCell>
              <TableCell>{getCourse(row)}</TableCell>
              <TableCell>{row.mailchimpSyncDate ? moment(row.mailchimpSyncDate).format('MM/DD/YYYY') : 'N/A'}</TableCell>
              <TableCell>{getEnrollment(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={8}
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default CoursesTable;
