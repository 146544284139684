/* eslint eol-last: 0 */
import util from 'util';
import { isEmpty, isNil, round } from 'lodash';
import Hashids from 'hashids';
import dayjs from 'dayjs';
import {
  Awo, AwoCreateInput, AwoUpdateInput, NewAwoFormValues,
} from './types/Awo';
import { PetParent } from './types/PetParent';

export const hashids = new Hashids(process.env.REACT_APP_HASHID_SALT, 10);

export const automatedSMSNames = [
  'Shelterluv',
  'Shelter Buddy',
  'Animal Shelter Manager',
  'Pawlytics',
];

export const getCourse = (row: any) => {
  let course = 'Unknown course';
  if (row.careType === 'Foster') {
    if (row.animalType.toLowerCase() === 'dog') {
      course = 'Fostering Fundamentals For Dogs';
    } else if (row.animalType.toLowerCase() === 'cat') {
      course = 'Fostering Fundamentals For Cats';
    } else if (row.animalType.toLowerCase() === 'kitten') {
      course = 'Fostering Fundamentals For Kittens';
    }
  } else if (row.animalType.toLowerCase() === 'dog') {
    course = 'Welcoming Your Adopted Dog Home';
  } else if (row.animalType.toLowerCase() === 'cat') {
    course = 'Welcoming Your Adopted Cat Home';
  }
  return course;
};
export const isInactiveAnimal = (a: PetParent['animals'][0]) => a.careType === 'Foster' && dayjs().diff(a.dateCreated, 'day') > 30;
export const dashify = (value: any) => (isEmpty(value) || value === 'Invalid date' || value === 'Invalid Date'
  ? '-'
  : value);
export const getCareType = (careType: any) => {
  if (careType === 'Foster') {
    return 'Fostered';
  }
  return careType;
};

export const getExitDateTitle = (careType: any) => {
  let title = 'Contact date';
  if (careType === 'Adopted') {
    title = 'Adoption date';
  } else if (careType === 'Foster') {
    title = 'Foster start date';
  }
  return title;
};

export const getEnrollment = (row: any) => {
  if (row.activatedAt === null || row.onboardDate === null) {
    return 'Not Started';
  }

  return row.completedAt !== null ? 'Completed' : 'Started';
};

export const getTypeformAnswers = (
  typeformForm: any,
  typeformResponse: any,
) => {
  const firstResponse = typeformResponse?.items[0];
  const typeformResponseById = {} as any;
  firstResponse?.answers.forEach((a: any) => {
    if (a.type === 'number') {
      typeformResponseById[a.field.id] = a.number;
    } else if (a.type === 'choices') {
      typeformResponseById[a.field.id] = a.choices.labels;
    } else if (a.type === 'choice') {
      typeformResponseById[a.field.id] = a.choice.label;
    } else if (a.type === 'boolean') {
      typeformResponseById[a.field.id] = a.boolean;
    }
  });
  const qas = typeformForm.fields
    .slice(1)
    .map((f: any) => ({ ...f, value: typeformResponseById[f.id] }));
  return qas;
};

export const getRate = (previous: any, current: any) => {
  const diff = current - previous;
  let percent = 0;
  if (previous !== 0) {
    percent = diff / previous;
  } else if (current !== 0) {
    percent = 1;
  }
  return round((Number.isNaN(percent) ? 0 : percent) * 100);
};

export const PETCADEMY_USER_IDS = [
  '393972091791',
  '11264438743444',
  '15478845720852',
  '20573195554068',
];

// index of petcademy shadow in the theme.shadows array test
export const PETCADEMY_SHADOW = 25;

export const DEFAULT_START_DAYS_AGO = 30;
export const DEFAULT_END_DAYS_AGO = 0;

export const setMaxWidthOnImg = (html: any) => html.replaceAll('img', 'img style="max-width: 100%;"');

export const beforeCapture = (scope: any, error: any) => {
  console.log(
    util.inspect(scope, { showHidden: false, depth: null, colors: true }),
  );
  console.log(
    util.inspect(error, { showHidden: false, depth: null, colors: true }),
  );
  const userString = localStorage.getItem('ni.user');
  const userJSON = userString ? JSON.parse(userString) : { email: null };
  scope.setUser({ email: userJSON.email });
  if (error.graphQLErrors) {
    scope.setContext('graphql-errors', {
      errors: util.inspect(error.graphQLErrors, {
        showHidden: false,
        depth: null,
        colors: true,
      }),
    });
  }
  if (error.clientErrors) {
    scope.setContext('client-errors', {
      errors: util.inspect(error.clientErrors, {
        showHidden: false,
        depth: null,
        colors: true,
      }),
    });
  }
  if (error.networkError) {
    scope.setContext('network-error', {
      errors: util.inspect(error.networkError, {
        showHidden: false,
        depth: null,
        colors: true,
      }),
    });
  }
};

const formValues = (values: any, create: boolean): Record<string, any> => ({
  name: values.name,
  displayName: values.displayName,
  smsName: values.smsName?.value,
  token: values.token,
  apiUrl: values.apiUrl,
  apiKey: values.apiKey,
  note: values.note,
  orgType: values.rescueType,
  website: values.website,
  country: values.country?.value,
  streetAddress: values.streetAddress,
  city: values.city,
  state: values.state?.abbreviation,
  zipCode: values.postal ? String(values.postal) : undefined,
  timezone: values.timezone?.value,
  membershipType: create ? 'Plus' : undefined,
  resourcesUrl: values.googleDriveFolder,
  preSurrenderDashboard: values.preSurrenderDashboard,
  dogAdopt: values.dogAdopt,
  catAdopt: values.catAdopt,
  dogFoster: values.dogFoster,
  catFoster: values.catFoster,
  kittenFoster: values.kittenFoster,
  externalOrgName: values.externalOrgName,
  externalOrgId: values.externalOrgId,
  orgPhoneNumber: values.orgPhoneNumber,
  donationLink: values.donationLink,
  adoptionPacketLinkDogs: values.adoptionPacketLinkDogs,
  adoptionPacketLinkCats: values.adoptionPacketLinkCats,
  faqLink: values.faqLink,
  dogAdoptCourseId: create ? 1868559 : undefined,
  catAdoptCourseId: create ? 1872734 : undefined,
  dogFosterCourseId: create ? 640415 : undefined,
  catFosterCourseId: create ? 654641 : undefined,
  kittenFosterCourseId: create ? 663633 : undefined,
  automationEnabled:
    create
    || (values.activeStatus ? values.activeStatus === 'Active' : undefined),
  feedback1: !create ? values.feedback1 : undefined,
  feedback2: !create ? values.feedback2 : undefined,
  feedback3: !create ? values.feedback3 : undefined,
  feedback4: !create ? values.feedback4 : undefined,
  feedback5: !create ? values.feedback5 : undefined,
  asksQuestion: !create ? values.asksQuestion : undefined,
  emailTypo: !create ? values.emailTypo : undefined,
  phoneTypo: !create ? values.phoneTypo : undefined,
  adoptionReview: !create ? values.adoptionReview : undefined,
  fosterReview: !create ? values.fosterReview : undefined,
  unresponsive: !create ? values.unresponsive : undefined,
  completedSupport: !create ? values.completedSupport : undefined,
});

export const parseAwoCreateFormValues = (
  values: Required<NewAwoFormValues>,
): AwoCreateInput => ({
  ...(formValues(values, true) as AwoCreateInput),
  user: {
    firstName: values.firstTeamMemberFirstName,
    lastName: values.firstTeamMemberLastName,
    email: values.firstTeamMemberEmail,
    preSurrenderNotificationEmail: values.notifications,
    adoptionNotificationEmail: values.notifications,
    fosterNotificationEmail: values.notifications,
  },
});

export const parseAwoUpdateFormValues = (
  values: Partial<NewAwoFormValues>,
): AwoUpdateInput => {
  const parsedValues: AwoUpdateInput = formValues(
    values,
    false,
  ) as AwoUpdateInput;
  (Object.keys(parsedValues) as [keyof AwoUpdateInput]).forEach(
    (key) => isNil(parsedValues[key]) && delete parsedValues[key],
  );
  return parsedValues;
};

export const generateToken = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 32; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getMostRecentDate = (
  date1: string,
  date2: string,
  format: string,
) => {
  if (isNil(date1)) {
    return dayjs(date2).format(format);
  }
  if (isNil(date2)) {
    return dayjs(date1).format(format);
  }
  const dayjsDate1 = dayjs(date1);
  const dayjsDate2 = dayjs(date2);
  if (dayjsDate1.isAfter(dayjsDate2)) {
    return dayjsDate1.format(format);
  }
  return dayjsDate2.format(format);
};

export const phoneFormatter = (rawPhone?: string, country?: Awo['country']) => {
  if (!rawPhone) {
    return '';
  }

  let countryFormatter: [number, [RegExp, string]][] = [
    [1, [/(\d{1})(\d{3})(\d{3})(\d+)/, '($2) $3-$4']],
    [61, [/(\d{2})(\d{3})(\d{3})(\d+)/, '0$2 $3 $4']],
    [0, [/(\d{1})(\d{3})(\d{3})(\d+)/, '0$2 $3 $4']],
  ];
  const basicFormatter = [/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'] as [RegExp, string];

  const clearedValue = rawPhone.replace(/[^\d+]/g, '').replace('+', '');

  if (clearedValue.length < 8) {
    return rawPhone;
  }

  if (country && (country === 'USA' || country === 'Canada')) {
    countryFormatter = countryFormatter.slice(0, 1);
  }

  if (country && country === 'Australia') {
    countryFormatter = countryFormatter.slice(1);
  }

  const [, [regex, format]] = countryFormatter
    .find(([countryCode]) => clearedValue.startsWith(countryCode.toString())) ?? [0, basicFormatter];

  return clearedValue.replace(regex, format);
};

export const typeformMarkdownFormatter = (text: string) => text.replaceAll(/[*#_>]/g, '').trim();
export const getMembershipDaysLeft = (
  daysAgo: number,
  membershipType: string,
) => {
  let membershipDays = 7;
  if (membershipType.includes('Premium')) {
    membershipDays = 365;
  } else if (membershipType === 'Plus') {
    membershipDays = 30;
  }
  return membershipDays - daysAgo;
};

export const getUrlParameterByName = (name: string, url: string) => {
  const newName = name.replace(/[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const REVIEW_TAG_LIST = [
  'cat_behavioral_challenges_aggression',
  'cat_behavioral_challenges_chewing',
  'cat_behavioral_challenges_jumping',
  'cat_behavioral_challenges_vocalization',
  'cat_behavioral_challenges_other',
  'cat_behavioral_challenges_scratching',
  'cat_fear',
  'cat_health_wellness',
  'cat_litter_box',
  'cat_other',
  'dog_anxiety',
  'dog_behavioral_challenges_aggression',
  'dog_behavioral_challenges_barking',
  'dog_behavioral_challenges_chewing',
  'dog_behavioral_challenges_jumping',
  'dog_behavioral_challenges_leash_reactivity',
  'dog_behavioral_challenges_mouthiness_nipping',
  'dog_behavioral_challenges_resource_guarding',
  'dog_behavioral_challenges_digging',
  'dog_behavioral_challenges_prey_drive',
  'dog_behavioral_challenges_other',
  'dog_core_behaviors',
  'dog_fear',
  'dog_health_caring',
  'dog_leash_walking',
  'dog_house_training',
  'dog_socializing',
  'dog_other',
  'multi_animal_household_cats',
  'multi_animal_household_dogs',
  'multi_animal_household_dog_cat',
  'technical_support',
  'situational',
];
export const ELIGIBLE_TAG_LIST = [
  'cat_behavioral_challenges_aggression_handling',
  'cat_behavioral_challenges_aggression_play',
  'cat_behavioral_challenges_aggression_other',
  'cat_behavioral_challenges_chewing',
  'cat_behavioral_challenges_jumping',
  'cat_behavioral_challenges_vocalization',
  'cat_behavioral_challenges_other',
  'cat_behavioral_challenges_scratching',
  'cat_fear',
  'cat_health_and_wellness_medical_issue',
  'cat_health_and_wellness_food',
  'cat_health_and_wellness_getting_settled',
  'cat_health_and_wellness_grooming',
  'cat_health_and_wellness_other',
  'cat_litter_box_eliminating_outside_box',
  'cat_litter_box_marking',
  'cat_litter_box_other',
  'cat_other',
  'dog_anxiety_separation',
  'dog_anxiety_general',
  'dog_anxiety_seeking_info',
  'dog_anxiety_other',
  'dog_behavioral_challenges_aggression_people',
  'dog_behavioral_challenges_aggression_dogs',
  'dog_behavioral_challenges_aggression_other',
  'dog_behavioral_challenges_barking_watchdog',
  'dog_behavioral_challenges_barking_anxious',
  'dog_behavioral_challenges_barking_distance_increase',
  'dog_behavioral_challenges_barking_attention_demand',
  'dog_behavioral_challenges_barking_general',
  'dog_behavioral_challenges_chewing',
  'dog_behavioral_challenges_jumping',
  'dog_behavioral_challenges_leash_reactivity_dogs',
  'dog_behavioral_challenges_leash_reactivity_people',
  'dog_behavioral_challenges_leash_reactivity_other',
  'dog_behavioral_challenges_mouthiness_nipping',
  'dog_behavioral_challenges_resource_guarding_food_toys',
  'dog_behavioral_challenges_resource_guarding_people',
  'dog_behavioral_challenges_resource_guarding_other',
  'dog_behavioral_challenges_digging',
  'dog_behavioral_challenges_prey_drive',
  'dog_behavioral_challenges_other',
  'dog_core_behaviors_crate',
  'dog_core_behaviors_recall',
  'dog_core_behaviors_other',
  'dog_fear',
  'dog_health_caring_medical_issue',
  'dog_health_caring_food',
  'dog_health_caring_request_records',
  'dog_health_caring_getting_settled',
  'dog_health_caring_grooming',
  'dog_health_caring_other',
  'dog_leash_walking',
  'dog_house_training_general',
  'dog_house_training_marking',
  'dog_house_training_submissive_excited',
  'dog_house_training_other',
  'dog_socializing_kids',
  'dog_socializing_people',
  'dog_socializing_dogs',
  'dog_socializing_other',
  'dog_other',
  'multi_animal_household_cats',
  'multi_animal_household_dogs',
  'multi_animal_household_dog_cat',
  'technical_support_password',
  'technical_support_no_response_needed',
  'technical_support_compliment',
  'technical_support_name_change',
  'technical_support_change_start_date',
  'technical_support_other',
  'situational_escalate_to_awo',
  'situational_return_to_awo',
  'situational_rehomed',
  'situational_deceased',
  'situational_lost_pet',
  'situational_other',
  'situational_refer_back_to_awo',
];

export const FLAGGED = {
  tagName: 'flagged',
  unreviewedCount: 0,
  firstQuestion: { ticketId: '0' },
};

export function chunk<T>(array: T[], size: number): T[][] {
  return Array.from(
    { length: Math.ceil(array.length / size) },
    (_, index) => array.slice(index * size, index * size + size),
  );
}

export const showPet24Controls = (name?: string) => ['petpoint', 'chameleon'].includes(name?.toLowerCase() ?? '');
