import React from 'react';
import { Paper, SxProps, Theme } from '@mui/material';

function StyledPaper({ children, color, sx }: { children: React.ReactNode, color: string, sx?: SxProps<Theme> }) {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2, mt: 2, bgcolor: color, borderRadius: '8px', position: 'relative', ...sx,
      }}
    >
      {children}
    </Paper>
  );
}

export default StyledPaper;
