import { useState } from 'react';
import { OrganizationsTableFields } from '../../types/Tables';

export type UsePaginationReturn = {
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  orderBy: OrganizationsTableFields | null;
  order: 'desc' | 'asc' | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setOrderBy: React.Dispatch<React.SetStateAction<OrganizationsTableFields | null>>;
  setOrder: React.Dispatch<React.SetStateAction<'desc' | 'asc' | undefined>>;
  handleChangeOrder: (event: React.ChangeEvent<HTMLInputElement>, field: OrganizationsTableFields) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
};

export default function usePagination(): UsePaginationReturn {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<OrganizationsTableFields>(null);
  const [order, setOrder] = useState<'desc' | 'asc' | undefined>(undefined);

  const handleChangeOrder = (_: unknown, field: OrganizationsTableFields) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  return {
    setPage,
    setRowsPerPage,
    rowsPerPage,
    page,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    handleChangeOrder,
    handleChangeRowsPerPage,
    handleChangePage,
  };
}
