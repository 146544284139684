import React, { Dispatch, SetStateAction } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { ELIGIBLE_TAG_LIST } from '../../utilities';
import AwoAutocomplete, { AutocompleteAwo } from '../AwoAutocomplete';
import { AwoUser } from '../../types/Awo';
import AwoUserAutocomplete from '../AwoUserAutocomplete';

function TagCustomSearch({ setTicketSearch, setEnableShowDialog }: {
  setTicketSearch: Dispatch<SetStateAction<Record<string, any>>>,
  setEnableShowDialog: Dispatch<SetStateAction<boolean>>,
}) {
  const [tags, setTags] = React.useState<string[]>([]);
  const [reviewStatus, setReviewStatus] = React.useState<string[]>([]);
  const [careType, setCareType] = React.useState<string[]>([]);
  const [species, setSpecies] = React.useState<string[]>([]);
  const [awo, setAwo] = React.useState<AutocompleteAwo | null>(null);
  const [awoUser, setAwoUser] = React.useState<AwoUser | null>(null);

  React.useEffect(() => {
    setEnableShowDialog(
      tags.length > 0
      || reviewStatus.length > 0
      || careType.length > 0
      || species.length > 0
      || awo !== null
      || awoUser !== null,
    );
    setTicketSearch({
      tags: tags.length > 0 ? tags : undefined,
      reviewStatus: reviewStatus.length > 0 ? reviewStatus : undefined,
      careType: careType.length > 0 ? careType : undefined,
      species: species.length > 0 ? species : undefined,
      awoId: awo ? awo.id : null,
      awoUserId: awoUser ? awoUser.id : null,
    });
  }, [setTicketSearch, setEnableShowDialog, tags, reviewStatus, careType, species, awo, awoUser]);
  return (
    <Box>
      <Autocomplete
        sx={{ pb: 3 }}
        multiple
        options={ELIGIBLE_TAG_LIST}
        value={tags}
        onChange={(_, value) => setTags(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Eligible Tags"
          />
        )}
      />
      <Autocomplete
        sx={{ pb: 3 }}
        multiple
        options={['Reviewed', 'Not Reviewed', 'Flagged']}
        value={reviewStatus}
        onChange={(_, value) => setReviewStatus(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Review status"
          />
        )}
      />
      <Autocomplete
        sx={{ pb: 3 }}
        multiple
        options={['Adopted', 'Foster', 'Pre-Surrender']}
        value={careType}
        onChange={(_, value) => setCareType(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Care type"
          />
        )}
      />
      <Autocomplete
        sx={{ pb: 3 }}
        multiple
        options={['Dog', 'Cat']}
        value={species}
        onChange={(_, value) => setSpecies(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Species"
          />
        )}
      />
      <Box
        sx={{ pb: 3 }}
      >
        <AwoAutocomplete
          variant="standard"
          selectedAwo={awo}
          setAwo={setAwo}
          showDeactivated={false}
          shrink={undefined}
        />
      </Box>
      <AwoUserAutocomplete
        variant="standard"
        selectedAwoUser={awoUser}
        setAwoUser={setAwoUser}
      />
      <Box
        sx={{
          pt: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          disabled={awo === null && (tags.length + reviewStatus.length + careType.length + species.length === 0)}
          color="inherit"
          onClick={() => {
            setTags([]);
            setReviewStatus([]);
            setCareType([]);
            setSpecies([]);
            setAwo(null);
            setAwoUser(null);
          }}
        >
          Clear search
        </Button>
      </Box>
    </Box>
  );
}

export default TagCustomSearch;
