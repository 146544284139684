import React from 'react';
import {
  Alert,
  Box,
  Button,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Snackbar,
} from '@mui/material';
import { useMutation } from '@apollo/client';

import { AwoUser } from '../../types/Awo';
import { DELETE_AWO_USER } from '../../queries';

interface MembersRemoveDialogProps {
  setDialog: React.Dispatch<React.SetStateAction<boolean>>,
  member?: AwoUser,
  awoName: string,
  onSuccess: (message: string) => void,
}

function MembersRemoveDialog({
  setDialog,
  member,
  awoName,
  onSuccess,
}: MembersRemoveDialogProps) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [deleteAwoUser, { loading, error }] = useMutation(DELETE_AWO_USER, {
    onCompleted: (d) => {
      const { response } = d;
      if (!response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      } else {
        onSuccess(response.message);
      }
    },
  });

  if (error) {
    setSnackbarOpen(true);
    setSnackbarMessage('Error deleting a new member. We\'re working on it.');
  }

  const name = [member?.firstName, member?.lastName].join(' ');

  return (
    <>
      <Dialog
        open
        onClose={() => setDialog(false)}
        aria-labelledby="filter-dialog-title"
        aria-describedby="filter-dialog-description"
        maxWidth={false}
      >
        <Box sx={{ width: '585px', maxWidth: '82vw' }}>
          <CardHeader title={`Remove ${name}?`} />
          <DialogContent>
            <Alert severity="warning" icon={false}>
              {`Removing ${name} from Petcademy revokes their access to this
                organization. To gain access, they will need to be invited again.`}
            </Alert>
            <FormGroup sx={{ pt: 2 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={`Yes, I want to remove ${name}`}
              />
            </FormGroup>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'start' }}>
            <Button
              onClick={() => deleteAwoUser({
                variables: {
                  id: member?.id,
                  awoUser: member,
                  awoName,
                },
              })}
              variant="contained"
              disabled={!checked || loading}
            >
              {loading ? 'Removing' : 'Remove Member'}
            </Button>
            <Button onClick={() => setDialog(false)}>Cancel</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={snackbarMessage}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}

export default MembersRemoveDialog;
