import React from 'react';
import { Box, Link, Typography } from '@mui/material';

function ErrorPage() {
  return (
    <Box display="flex" width="100%" height="100vh" alignItems="center" justifyContent="center" flexDirection="column">
      <Box>
        <img alt="Dog Cat Error Logo" height="240px" width="240px" src="/dog_cat.png" onError={(e) => { e.target.style.display = 'none'; }} />
      </Box>
      <Box>
        <Typography variant="h5" color="primary.700" align="center">Oops! Something went wrong</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" color="primary.contrastLight" align="center">
          <Link
            href=""
            underline="always"
            color="primary.main"
            rel="noopener"
          >
            Reload the page
          </Link>
          {' '}
          or email us at support@petcademy.org if the issue continues
        </Typography>
      </Box>
    </Box>
  );
}

export default ErrorPage;
