import React from 'react';
import { Typography } from '@mui/material';

function ErrorMessage({ color }: { color?: string }) {
  return (
    <Typography color={`${color || 'primary.700'}`} variant="body1">{'Error loading this content. We\'re working on it.'}</Typography>
  );
}

export default ErrorMessage;
