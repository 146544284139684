import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const pet24OrgNameSchema = {
  externalOrgName: yup.string(),
};

function Pet24OrgNamePartial() {
  return (
    <TextFieldElement
      name="externalOrgName"
      fullWidth
      label="24Pet Org (optional)"
      variant="outlined"
      placeholder="Eg. Williamson County Regional Animal Shelter - Petcademy - 2024"
      InputLabelProps={{ shrink: true }}
    />
  );
}
export default Pet24OrgNamePartial;
