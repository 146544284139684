import React from 'react';
import { useQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { GET_AWO_USERS } from '../queries';
import ErrorMessage from './ErrorMessage';
import { beforeCapture } from '../utilities';
import { Awo, AwoUser } from '../types/Awo';

const PETCADEMY_AWO_ID = 172;

function AwoUserAutocomplete({
 selectedAwoUser, setAwoUser, size = 'medium', onlyPhotoAwos = false, variant = 'outlined',
}: {
  selectedAwoUser: AwoUser | null,
  setAwoUser: React.Dispatch<React.SetStateAction<AwoUser | null>>,
  size?: 'medium' | 'small',
  onlyPhotoAwos?: boolean,
  variant?: 'outlined' | 'standard',
  shrink?: true | undefined
}) {
  const { loading, error, data } = useQuery<{ awoUsers: AwoUser[] }>(GET_AWO_USERS, {
    variables: {
      awoId: PETCADEMY_AWO_ID,
      onlyReviewers: true,
    },
  });

  if (loading) return <span>Loading...</span>;
  if (error) throw error;
  return (
    <Autocomplete
      disablePortal
      id="awo-user-select"
      size={size}
      componentsProps={{
        popper: {
          placement: 'bottom',
          modifiers: [{
            name: 'flip',
            enabled: false,
          }],
        },
      }}
      options={data?.awoUsers || []}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      value={selectedAwoUser}
      onChange={(_, newValue) => {
        if (newValue !== null) {
          setAwoUser(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} variant={variant} label="Initial Reviewer" />}
    />
  );
}

export default Sentry.withErrorBoundary(AwoUserAutocomplete, { fallback: <ErrorMessage />, beforeCapture });
