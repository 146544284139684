import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem, MenuList,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SettingsOutlined } from '@mui/icons-material';
import { GET_PET_PARENTS } from '../../queries';
import CoursePerformanceCard from './CoursePerformanceCard';
import DateFilter from '../DateFilter';
import CoursesTable from '../CoursesTable';
import DownloadDataChip from '../DownloadDataChip';
import withProfile from '../withProfile';
import CourseFiltersDialog from '../CourseFiltersDialog';
import IconFilter from '../IconFilter';
import SmallScreenPlaceholder from '../SmallScreenPlaceholder';
import { useAwoContext } from '../AwoContextProvider';

function CoursesContainer({ setDrawerOpen, setUserId }) {
  const { awo } = useAwoContext();

  const [searchTextTemp, setSearchTextTemp] = React.useState('');
  const [filters, setFilters] = React.useState({
    limit: 25,
    page: 0,
    order: 'desc',
    orderBy: 'mailchimp_sync_date',
    onlyGroupByPetParent: true,
    searchText: '',
    searchField: 'email',
    onboardingStatus: null,
    courseStatus: null,
    animalType: null,
    careType: null,
    daysAgo: -1,
  });
  const [selectedDaysAgo, setSelectedDaysAgo] = React.useState(30);
  const [open, setOpen] = React.useState(false);

  const {
    loading, error, data, networkStatus, refetch,
  } = useQuery(GET_PET_PARENTS, {
    variables: {
      offset: 0,
      limit: 25,
      order: 'desc',
      orderBy: 'mailchimp_sync_date',
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch({
      offset: filters.page * filters.limit,
      ...filters,
    });
  }, [filters, refetch]);

  const handleChangeOrder = (_, field) => {
    const isAsc = filters.orderBy === field && filters.order === 'asc';
    setFilters((prevState) => ({
      ...prevState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: field,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setFilters((prevState) => ({
      ...prevState,
      limit: newRowsPerPage,
      page: 0,
    }));
  };

  const handleChangePage = (_, newPage) => {
    setFilters((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  if (error) throw error;

  const noFoster = !awo.catFoster && !awo.dogFoster && !awo.kittenFoster;

  const filtersModalHandler = () => {
    const isFilterSet = filters.animalType || filters.careType || filters.onboardingStatus || filters.courseStatus;
    if (isFilterSet) {
      setFilters((prevState) => ({
        ...prevState,
        courseStatus: null,
        onboardingStatus: null,
        animalType: null,
        careType: null,
      }));
    } else {
      setOpen(true);
    }
  };

  const settingsMenu = (
    <IconFilter
      id="search-label"
      labelId="search-label"
      icon={<SettingsOutlined />}
      disablePortal={false}
      onChange={(e) => setFilters((prevState) => ({
        ...prevState,
        searchField: e.target.value,
      }))}
    >
      {
        // eslint-disable-next-line no-shadow
        (open, handleClose, handleListKeyDown) => (
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
          >
            <MenuItem
              onClick={(e) => {
                setFilters((prevState) => ({
                  ...prevState,
                  searchField: 'email',
                }));
                handleClose(e);
              }}
              value="email"
            >
              Search by email
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                setFilters((prevState) => ({
                  ...prevState,
                  searchField: 'name',
                }));
                handleClose(e);
              }}
              value="name"
            >
              Search by name
            </MenuItem>
          </MenuList>
        )
      }
    </IconFilter>
  );

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 3,
      }}
      >
        <Typography variant="overline">Courses</Typography>
        <Box>
          <DateFilter
            selectedDate={selectedDaysAgo}
            onChange={(e) => setSelectedDaysAgo(e.target.value)}
          />
        </Box>
      </Box>
      <Grid
        container
        spacing={3}
        px={3}
      >
        <Grid
          item
          container
          xs={12}
          lg={noFoster ? 12 : 6}
          spacing={3}
        >
          {!awo.dogAdopt ? null : (
            <Grid item xs={12}>
              <CoursePerformanceCard
                animalType="Dog"
                careType="Adopted"
                daysAgo={selectedDaysAgo}
              />
            </Grid>
          )}
          {!awo.catAdopt ? null : (
            <Grid item xs={12}>
              <CoursePerformanceCard
                animalType="Cat"
                careType="Adopted"
                daysAgo={selectedDaysAgo}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={noFoster ? 0 : 6}
          sx={{
            display: noFoster ? 'none' : 'block',
          }}
          spacing={3}
        >
          {!awo.dogFoster ? null : (
            <Grid item sx={12}>
              <CoursePerformanceCard
                animalType="Dog"
                careType="Foster"
                daysAgo={selectedDaysAgo}
              />
            </Grid>
          )}
          {!awo.catFoster && !awo.kittenFoster ? null : (
            <Grid item sx={12}>
              <CoursePerformanceCard
                animalType="Cat"
                careType="Foster"
                daysAgo={selectedDaysAgo}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={0}
        lg={12}
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
          borderTop: 1,
          borderColor: 'primary.50',
          mt: 3,
          padding: 3,
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              spacing={2}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
                gap={2}
              >
                <Box width="30%" maxWidth="300px">
                  <TextField
                    fullWidth
                    margin="normal"
                    name="text-search"
                    type="text"
                    id="text-search"
                    value={searchTextTemp}
                    variant="standard"
                    onChange={(e) => setSearchTextTemp(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setFilters((prevState) => ({
                          ...prevState,
                          searchText: searchTextTemp,
                        }));
                      }
                    }}
                    label={`Search by ${filters.searchField}`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box>
                  {settingsMenu}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                gap={1}
              >
                <Box>
                  <Button
                    startIcon={
                     (filters.animalType || filters.careType || filters.onboardingStatus || filters.courseStatus)
                       ? <CloseIcon />
                       : <FilterAltOutlinedIcon />
                    }
                    variant="outlined"
                    size="small"
                    onClick={filtersModalHandler}
                  >
                    {`${(filters.animalType || filters.careType || filters.onboardingStatus || filters.courseStatus) ? 'Clear filters' : 'Filters'}`}
                  </Button>
                </Box>
                {filters.order === 'desc' && filters.orderBy === 'mailchimp_sync_date' ? (null) : (
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setFilters((prevState) => ({
                          ...prevState,
                          order: 'desc',
                          orderBy: 'mailchimp_sync_date',
                        }));
                      }}
                    >
                      Reset Sorting
                    </Button>
                  </Box>
                )}
                <Box>
                  <DownloadDataChip
                    onboardingStatus={filters.onboardingStatus}
                    courseStatus={filters.courseStatus}
                    animalType={filters.animalType}
                    careType={filters.careType}
                    daysAgo={filters.daysAgo}
                    order={filters.order}
                    orderBy={filters.orderBy}
                    searchText={filters.searchText}
                    searchField={filters.searchField}
                  />
                </Box>
                <Box>
                  <DateFilter
                    selectedDate={filters.daysAgo}
                    onChange={(e) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        daysAgo: e.target.value,
                      }));
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CoursesTable
              page={filters.page}
              rowsPerPage={filters.limit}
              rowsPerPageOptions={[25, 50, 100]}
              order={filters.order}
              orderBy={filters.orderBy}
              handleChangeOrder={handleChangeOrder}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              count={data?.petParentCount?.count || 0}
              loading={loading}
              petParents={data?.petParents || []}
              networkStatus={networkStatus}
              setDrawerOpen={setDrawerOpen}
              setUserId={setUserId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          padding: 3,
        }}
      >
        <SmallScreenPlaceholder />
      </Grid>
      <CourseFiltersDialog
        open={open}
        setOpen={setOpen}
        animalType={filters.animalType}
        careType={filters.careType}
        onboardingStatus={filters.onboardingStatus}
        setOnboardingStatus={filters.setOnboardingStatus}
        courseStatus={filters.courseStatus}
        setFilters={setFilters}
      />
    </Box>
  );
}

export default withProfile(CoursesContainer);
