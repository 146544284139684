import React from 'react';
import {
  Box, Button, CardActions,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';

function ChangeNameDialogForm({
  name = '',
  onSubmit,
  onClose,
}: {
  name?: string;
  onSubmit: SubmitHandler<{ name: string }>;
  onClose: () => null;
}) {
  const schema = yup.object({
    name: yup.string().required(),
  });
  const defaultValues = {
    name,
  };

  const {
    register, control, formState, setValue, getValues, ...rest
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <FormContainer
      formContext={{
        register, control, formState, setValue, getValues, ...rest,
      }}
      onSuccess={onSubmit}
    >
      <Box sx={{ px: 3, pb: 1, pt: 0 }}>
        <TextFieldElement
          name="name"
          fullWidth
          label="New pet name"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          autoFocus
        />
      </Box>
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          type="submit"
          variant="contained"
          size="small"
        >
          Save name
        </Button>
        <Button size="small" onClick={onClose}>
          Close
        </Button>
      </CardActions>
    </FormContainer>
  );
}

export default ChangeNameDialogForm;
