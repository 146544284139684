export default [
  {
    label: 'United States',
    value: 'USA',
    timezones: [
      {
        name: 'Eastern Standard Time (EST)',
        utc: 'UTC-05:00',
        label: 'UTC-05:00 Eastern Standard Time (EST)',
        value: 'US/Eastern',
      },
      {
        name: 'Central Standard Time (CST)',
        utc: 'UTC-06:00',
        label: 'UTC-06:00 Central Standard Time (CST)',
        value: 'US/Central',
      },
      {
        name: 'Mountain Standard Time (MST)',
        utc: 'UTC-07:00',
        label: 'UTC-07:00 Mountain Standard Time (MST)',
        value: 'US/Mountain',
      },
      {
        name: 'Pacific Standard Time (PST)',
        utc: 'UTC-08:00',
        label: 'UTC-08:00 Pacific Standard Time (PST)',
        value: 'US/Pacific',
      },
      {
        name: 'Alaska Standard Time (AKST)',
        utc: 'UTC-09:00',
        label: 'UTC-09:00 Alaska Standard Time (AKST)',
        value: 'US/Alaska',
      }, {
        name: 'Hawaii Standard Time (HST)',
        utc: 'UTC-10:00',
        label: 'UTC-10:00 Hawaii Standard Time (HST)',
        value: 'US/Hawaii',
      },
      {
        name: 'Samoa Standard Time (SST)',
        utc: 'UTC-11:00',
        label: 'UTC-11:00 Samoa Standard Time (SST)',
        value: 'US/Samoa',
      },
      {
        name: 'Chamorro Standard Time (ChST)',
        utc: 'UTC+10:00',
        label: 'UTC+10:00 Chamorro Standard Time (ChST)',
        value: 'Pacific/Guam',
      },
    ],
    states: [
      {
        label: 'Alabama',
        abbreviation: 'AL',
      },
      {
        label: 'Alaska',
        abbreviation: 'AK',
      },
      {
        label: 'American Samoa',
        abbreviation: 'AS',
      },
      {
        label: 'Arizona',
        abbreviation: 'AZ',
      },
      {
        label: 'Arkansas',
        abbreviation: 'AR',
      },
      {
        label: 'California',
        abbreviation: 'CA',
      },
      {
        label: 'Colorado',
        abbreviation: 'CO',
      },
      {
        label: 'Connecticut',
        abbreviation: 'CT',
      },
      {
        label: 'Delaware',
        abbreviation: 'DE',
      },
      {
        label: 'District Of Columbia',
        abbreviation: 'DC',
      },
      {
        label: 'Federated States Of Micronesia',
        abbreviation: 'FM',
      },
      {
        label: 'Florida',
        abbreviation: 'FL',
      },
      {
        label: 'Georgia',
        abbreviation: 'GA',
      },
      {
        label: 'Guam',
        abbreviation: 'GU',
      },
      {
        label: 'Hawaii',
        abbreviation: 'HI',
      },
      {
        label: 'Idaho',
        abbreviation: 'ID',
      },
      {
        label: 'Illinois',
        abbreviation: 'IL',
      },
      {
        label: 'Indiana',
        abbreviation: 'IN',
      },
      {
        label: 'Iowa',
        abbreviation: 'IA',
      },
      {
        label: 'Kansas',
        abbreviation: 'KS',
      },
      {
        label: 'Kentucky',
        abbreviation: 'KY',
      },
      {
        label: 'Louisiana',
        abbreviation: 'LA',
      },
      {
        label: 'Maine',
        abbreviation: 'ME',
      },
      {
        label: 'Marshall Islands',
        abbreviation: 'MH',
      },
      {
        label: 'Maryland',
        abbreviation: 'MD',
      },
      {
        label: 'Massachusetts',
        abbreviation: 'MA',
      },
      {
        label: 'Michigan',
        abbreviation: 'MI',
      },
      {
        label: 'Minnesota',
        abbreviation: 'MN',
      },
      {
        label: 'Mississippi',
        abbreviation: 'MS',
      },
      {
        label: 'Missouri',
        abbreviation: 'MO',
      },
      {
        label: 'Montana',
        abbreviation: 'MT',
      },
      {
        label: 'Nebraska',
        abbreviation: 'NE',
      },
      {
        label: 'Nevada',
        abbreviation: 'NV',
      },
      {
        label: 'New Hampshire',
        abbreviation: 'NH',
      },
      {
        label: 'New Jersey',
        abbreviation: 'NJ',
      },
      {
        label: 'New Mexico',
        abbreviation: 'NM',
      },
      {
        label: 'New York',
        abbreviation: 'NY',
      },
      {
        label: 'North Carolina',
        abbreviation: 'NC',
      },
      {
        label: 'North Dakota',
        abbreviation: 'ND',
      },
      {
        label: 'Northern Mariana Islands',
        abbreviation: 'MP',
      },
      {
        label: 'Ohio',
        abbreviation: 'OH',
      },
      {
        label: 'Oklahoma',
        abbreviation: 'OK',
      },
      {
        label: 'Oregon',
        abbreviation: 'OR',
      },
      {
        label: 'Palau',
        abbreviation: 'PW',
      },
      {
        label: 'Pennsylvania',
        abbreviation: 'PA',
      },
      {
        label: 'Puerto Rico',
        abbreviation: 'PR',
      },
      {
        label: 'Rhode Island',
        abbreviation: 'RI',
      },
      {
        label: 'South Carolina',
        abbreviation: 'SC',
      },
      {
        label: 'South Dakota',
        abbreviation: 'SD',
      },
      {
        label: 'Tennessee',
        abbreviation: 'TN',
      },
      {
        label: 'Texas',
        abbreviation: 'TX',
      },
      {
        label: 'Utah',
        abbreviation: 'UT',
      },
      {
        label: 'Vermont',
        abbreviation: 'VT',
      },
      {
        label: 'Virgin Islands',
        abbreviation: 'VI',
      },
      {
        label: 'Virginia',
        abbreviation: 'VA',
      },
      {
        label: 'Washington',
        abbreviation: 'WA',
      },
      {
        label: 'West Virginia',
        abbreviation: 'WV',
      },
      {
        label: 'Wisconsin',
        abbreviation: 'WI',
      },
      {
        label: 'Wyoming',
        abbreviation: 'WY',
      },
    ],
  }, {
    label: 'Canada',
    value: 'Canada',
    timezones: [
      {
        name: 'Pacific Standard Time (PST)',
        utc: 'UTC-08:00',
        label: 'UTC-08:00 Pacific Standard Time (PST)',
        value: 'Canada/Pacific',
      },
      {
        name: 'Mountain Standard Time (MST)',
        utc: 'UTC-07:00',
        label: 'UTC-07:00 Mountain Standard Time (MST)',
        value: 'Canada/Mountain',
      },
      {
        name: 'Central Standard Time (CST)',
        utc: 'UTC-06:00',
        label: 'UTC-06:00 Central Standard Time (CST)',
        value: 'Canada/Central',
      },
      {
        name: 'Eastern Standard Time (EST)',
        utc: 'UTC-05:00',
        label: 'UTC-05:00 Eastern Standard Time (EST)',
        value: 'Canada/Eastern',
      },
      {
        name: 'Atlantic Standard Time (AST)',
        utc: 'UTC-04:00',
        label: 'UTC-04:00 Atlantic Standard Time (AST)',
        value: 'Canada/Atlantic',
      },
      {
        name: 'Newfoundland Standard Time (NST)',
        utc: 'UTC-03:30',
        label: 'UTC-03:30 Newfoundland Standard Time (NST)',
        value: 'Canada/Newfoundland',
      },
    ],
    states: [
      {
        label: 'Alberta',
        abbreviation: 'AB',
      },
      {
        label: 'British Columbia',
        abbreviation: 'BC',
      },
      {
        label: 'Manitoba',
        abbreviation: 'MB',
      },
      {
        label: 'New Brunswick',
        abbreviation: 'NB',
      },
      {
        label: 'Newfoundland and Labrador',
        abbreviation: 'NL',
      },
      {
        label: 'Nova Scotia',
        abbreviation: 'NS',
      },
      {
        label: 'Northwest Territories',
        abbreviation: 'NT',
      },
      {
        label: 'Nunavut',
        abbreviation: 'NU',
      },
      {
        label: 'Ontario',
        abbreviation: 'ON',
      },
      {
        label: 'Prince Edward Island',
        abbreviation: 'PE',
      },
      {
        label: 'Quebec',
        abbreviation: 'QC',
      },
      {
        label: 'Saskatchewan',
        abbreviation: 'SK',
      },
      {
        label: 'Yukon',
        abbreviation: 'YT',
      },
    ],
  }, {
    label: 'Australia',
    value: 'Australia',
    timezones: [
      {
        name: 'Australian Western Standard Time (AWST)',
        utc: 'UTC+08:00',
        label: 'UTC+08:00 Australian Western Standard Time (AWST)',
        value: 'Australia/West',
      },
      {
        name: 'Australian Central Standard Time (ACST)',
        utc: 'UTC+09:30',
        label: 'UTC+09:30 Australian Central Standard Time (ACST)',
        value: 'Australia/ACT',
      },
      {
        name: 'Australian Eastern Standard Time (AEST)',
        utc: 'UTC+10:00',
        label: 'UTC+10:00 Australian Eastern Standard Time (AEST)',
        value: 'Australia/Sydney',
      },
    ],
    states: [
      {
        label: 'Australian Capital Territory',
        abbreviation: 'ACT',
      },
      {
        label: 'New South Wales',
        abbreviation: 'NSW',
      },
      {
        label: 'Northern Territory',
        abbreviation: 'NT',
      },
      {
        label: 'Queensland',
        abbreviation: 'QLD',
      },
      {
        label: 'South Australia',
        abbreviation: 'SA',
      },
      {
        label: 'Tasmania',
        abbreviation: 'TAS',
      },
      {
        label: 'Victoria',
        abbreviation: 'VIC',
      },
      {
        label: 'Western Australia',
        abbreviation: 'WA',
      },
    ],
  },
];
