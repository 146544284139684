import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import MessageTemplate from './MessageTemplate';
import { GET_CAMPAIGNS, UPDATE_GLOBAL_SETTINGS } from '../../queries';
import { colors } from '../../theme';
import EditableMessageTemplate from './EditableMessageTemplate';
import vMessageTemplatesVar from './VMessagesCache';
import { MessageTemplateType } from '../../types/MessageTemplate';

interface Campaign {
  id: string;
  name: string;
  sendHour: number;
  messageTemplates: MessageTemplateType[];
  vMessageTemplate: MessageTemplateType;
}

const hoursOfDay = ['12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'];
const campaignNameTranslation: Record<string, string> = {
  'Cat Adopted': 'Enterprise Plus - Adopted Cat',
  'Dog Adopted': 'Enterprise Plus - Adopted Dog',
  'Cat Foster': 'Enterprise Plus - Foster Cat',
  'Dog Foster': 'Enterprise Plus - Foster Dog',
  'Kitten Foster': 'Enterprise Plus - Foster Kitten',
  'Pre-Surrender Dashboard': 'Enterprise Plus - Pre-surrender',
  'Premium Dog': 'Premium Dog - Direct Purchase',
  'Premium Cat': 'Premium Cat - Direct Purchase',
  'Premium Dog & Cat': 'Premium Dog & Cat - Direct Purchase',
  'Premium Dog - LPM+': 'Premium Dog - LPM+',
  'Premium Cat - LPM+': 'Premium Cat - LPM+',
  'Premium Dog - ASPCA': 'Premium Dog - ASPCA',
  'Premium Cat - ASPCA': 'Premium Cat - ASPCA',
  'Premium Dog & Cat - ASPCA': 'Premium Dog & Cat - ASPCA',
  'Enterprise Plus - Adopted Cat - PetPlace': 'Enterprise Plus - Adopted Cat - PetPlace',
  'Enterprise Plus - Adopted Dog - PetPlace': 'Enterprise Plus - Adopted Dog - PetPlace',
  'Co-Sheltering - At Facility - Dog': 'Co-Sheltering - At Facility - Dog',
  'Co-Sheltering - At Facility - Cat': 'Co-Sheltering - At Facility - Cat',
  'Co-Sheltering - At Facility - Dog & Cat': 'Co-Sheltering - At Facility - Dog & Cat',
  'Co-Sheltering - After Care - Dog': 'Co-Sheltering - After Care - Dog',
  'Co-Sheltering - After Care - Cat': 'Co-Sheltering - After Care - Cat',
  'Co-Sheltering - After Care - Dog & Cat': 'Co-Sheltering - After Care - Dog & Cat',
};
const PRE_SURRENDER_INDICES = [5];

function SMSContainer() {
  const [timeOfDay, setTimeOfDay] = React.useState<string>('');
  const [selectedCampaign, setSelectedCampaign] = React.useState<number>(0);
  const [pendingCampaign, setPendingCampaign] = React.useState<number>();
  const [selectedMessage, setSelectedMessage] = React.useState<MessageTemplateType>();
  const [pendingSelectedMessage, setPendingSelectedMessage] = React.useState<MessageTemplateType>();
  const [globalSettingsDialogOpen, setGlobalSettingsDialogOpen] = React.useState(false);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState(null);
  const [editedText, setEditedText] = React.useState('');
  const [hasMadeEdit, setHasMadeEdit] = React.useState(false);

  const { loading, data } = useQuery<{ campaigns: Campaign[] }>(GET_CAMPAIGNS);
  const [updateGlobalSettings, { loading: globalSettingsLoading }] = useMutation(UPDATE_GLOBAL_SETTINGS, {
    onCompleted: (d) => {
      setSnackbarMessage(d.message);
      setSnackbarOpen(true);
      setGlobalSettingsDialogOpen(false);
    },
    refetchQueries: [
      'GetCampaigns',
    ],
  });

  const handleTimeOfDayChange = (event: SelectChangeEvent) => {
    setTimeOfDay(event.target.value as string);
  };

  const newMessageTemplate = data?.campaigns[selectedCampaign].vMessageTemplate;

  const handleNewMessageCreate = () => {
    const campaignId = data?.campaigns[selectedCampaign].id || '';
    vMessageTemplatesVar({
      id: '-1',
      campaignId,
      text: 'Create message',
      sendDelay: undefined,
      position: 0,
      note: '',
      numericalResponse: false,
    });
  };

  const handleSelectionReset = () => {
    setSelectedMessage(undefined);
  };

  const handleCampaignChange = (event: SelectChangeEvent) => {
    if (hasMadeEdit) {
      setUnsavedChangesDialogOpen(true);
      setPendingCampaign(Number(event.target.value));
    } else {
      setSelectedCampaign(Number(event.target.value));
      vMessageTemplatesVar(undefined);
      handleSelectionReset();
    }
  };

  const handleMessageTemplateClick = (messageTemplate: MessageTemplateType) => {
    if (hasMadeEdit) {
      setUnsavedChangesDialogOpen(true);
      setPendingSelectedMessage(messageTemplate);
    } else {
      setSelectedMessage(messageTemplate);
    }
  };

  useEffect(() => {
    if (newMessageTemplate) {
      setSelectedMessage(newMessageTemplate);
    }
  }, [newMessageTemplate]);

  if (loading) return null;

  const hourToSend = `${data!.campaigns[selectedCampaign].sendHour}`;

  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 2,
        paddingBottom: 2,
        borderBottom: 1,
        borderColor: 'primary.50',
        position: 'sticky',
        top: 0,
      }}
      >
        <Select
          variant="outlined"
          size="small"
          defaultValue={String(selectedCampaign)}
          value={String(selectedCampaign)}
          onChange={handleCampaignChange}
        >
          {data?.campaigns.slice(0, Object.keys(campaignNameTranslation).length).map((c, idx) => (
            <MenuItem key={c.id} value={idx}>
              {campaignNameTranslation[c.name]}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <Button
            sx={{
              mr: 1,
            }}
            disabled={PRE_SURRENDER_INDICES.includes(selectedCampaign)}
            onClick={handleNewMessageCreate}
          >
            New Message
          </Button>
          <Button onClick={() => setGlobalSettingsDialogOpen(true)}>Global settings</Button>
        </Box>
      </Box>
      <Grid
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          overflow: 'hidden',
        }}
        container
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: 3,
            borderRight: {
              xs: 0,
              md: 1,
            },
            borderColor: {
              xs: 'primary.50',
              md: 'primary.50',
            },
            overflow: 'auto',
            height: '100%',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
          >
            {
              newMessageTemplate && (
                <Box
                  key={newMessageTemplate.id}
                  style={{
                    color: colors.primary.text,
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleMessageTemplateClick(newMessageTemplate)}
                >
                  <MessageTemplate
                    active={selectedMessage?.id === newMessageTemplate.id}
                    key={newMessageTemplate.id}
                    text={selectedMessage?.id === newMessageTemplate.id ? editedText : newMessageTemplate.text}
                    sendDelay={newMessageTemplate.sendDelay}
                  />
                </Box>
              )
            }
            {
              data?.campaigns[selectedCampaign].messageTemplates?.map((template) => (
                <Box
                  key={template.id}
                  style={{
                    color: colors.primary.text,
                    textDecoration: 'none',
                    cursor: PRE_SURRENDER_INDICES.includes(selectedCampaign) ? 'auto' : 'pointer',
                  }}
                  onClick={() => {
                    if (!PRE_SURRENDER_INDICES.includes(selectedCampaign)) {
                      handleMessageTemplateClick(template);
                    }
                  }}
                >
                  <MessageTemplate
                    active={selectedMessage?.id === template.id}
                    key={template.id}
                    text={selectedMessage?.id === template.id ? editedText : template.text}
                    sendDelay={template.sendDelay}
                    disableHover={PRE_SURRENDER_INDICES.includes(selectedCampaign)}
                  />
                </Box>
              ))
            }
          </Box>
        </Grid>
        {
          selectedMessage ? (
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: 3,
                overflow: 'auto',
                height: '100%',
              }}
            >
              <Grid container rowGap={4}>
                <EditableMessageTemplate
                  messageTemplate={selectedMessage}
                  deleteHandler={handleSelectionReset}
                  setEditedText={setEditedText}
                  setHasMadeEdit={setHasMadeEdit}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                padding: 3,
              }}
            >
              <Typography variant="overline">
                {PRE_SURRENDER_INDICES.includes(selectedCampaign) ? 'Pre-surrender campaigns are not editable' : 'Select a message to edit'}
              </Typography>
            </Box>
          )
        }
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={snackbarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <Dialog
        open={globalSettingsDialogOpen}
        onClose={() => setGlobalSettingsDialogOpen(false)}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '584px',
            maxWidth: '90vw',
            overflowY: 'unset',
          },
        }}
      >
        <CardHeader title="Global settings" subheader="Modifies all campaigns" />
        <CardContent sx={{ overflowY: 'unset' }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="global-settings-select-label">Time of day to send</InputLabel>
            <Select
              labelId="global-settings-select-label"
              id="global-settings-select"
              defaultValue={hourToSend}
              label="Time of day to send"
              onChange={handleTimeOfDayChange}
            >
              {[...Array(24).keys()].map((hour) => (
                <MenuItem key={hour} value={`${hour}`}>{`${hoursOfDay[hour]} in local timezone`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            disabled={globalSettingsLoading}
            onClick={() => updateGlobalSettings({ variables: { hourToSend: timeOfDay } })}
          >
            Save changes
          </Button>
          <Button variant="text" onClick={() => setGlobalSettingsDialogOpen(false)}>Cancel</Button>
        </CardActions>
      </Dialog>
      <Dialog
        open={unsavedChangesDialogOpen}
        onClose={() => setUnsavedChangesDialogOpen(false)}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '444px',
            maxWidth: '90vw',
          },
        }}
      >
        <DialogTitle variant="h6">You have unsaved changes</DialogTitle>
        <DialogContent>
          <Typography>Do you want to discard changes to the message you were editing?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={globalSettingsLoading}
            onClick={() => setUnsavedChangesDialogOpen(false)}
          >
            Continue Editing
          </Button>
          <Button
            variant="text"
            onClick={() => {
              if (pendingSelectedMessage) {
                setSelectedMessage(pendingSelectedMessage);
                setPendingSelectedMessage(undefined);
              } else if (pendingCampaign) {
                setSelectedCampaign(pendingCampaign);
                vMessageTemplatesVar(undefined);
                handleSelectionReset();
                setPendingCampaign(undefined);
              }
              setUnsavedChangesDialogOpen(false);
              setHasMadeEdit(false);
            }}
          >
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SMSContainer;
