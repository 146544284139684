import React, { Dispatch, SetStateAction } from 'react';
import { PetParent } from '../../types/PetParent';

const EditingModeContext = React.createContext({
  petParent: {} as PetParent | null,
  editableFieldParentLabel: false as string | boolean,
  setEditableFieldParentLabel: (() => {}) as Dispatch<SetStateAction<string | boolean>>,
  refetch: (() => {}) as unknown,
});

export default EditingModeContext;
