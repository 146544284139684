import React from 'react';
import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material';

function EnhancedTableHeader({
  headCells, order, orderBy, handleChangeOrder,
}) {
  const createSortHandler = (property) => (event) => {
    handleChangeOrder(event, property);
  };

  return (
    <TableHead variant="head">
      <TableRow variant="head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 500,
              width: headCell.width,
              color: orderBy === headCell.id ? 'primary.main' : 'black',
              display: {
                xs: headCell.hideOnMobile ? 'none' : 'table-cell',
                md: 'table-cell',
              },
            }}
          >
            {headCell.disableSorting ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHeader;
