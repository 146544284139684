import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Alert, Button, Snackbar } from '@mui/material';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { FileDownloadOutlined } from '@mui/icons-material';
import { beforeCapture } from '../utilities';
import { GET_PET_PARENTS } from '../queries';

function ProfilesDownloadDataChip({
  smsStatus, interests, order, orderBy, searchText, searchField, onlyGroupByPetParent,
}) {
  const [downloading, setDownloading] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [getPetParents, { error, data }] = useLazyQuery(GET_PET_PARENTS, {
    onError: (e) => {
      Sentry.withScope((scope) => {
        beforeCapture(scope, e);
        Sentry.captureException(e);
      });
      setSnackbarOpen(true);
      setDownloading(false);
    },
  });

  const getSMSStatus = (pp) => {
    if (pp.smsSyncDate && pp.smsOptOutDate) {
      return 'Opted Out';
    } if (pp.smsSyncDate && !pp.smsOptOutDate) {
      return 'Opted In';
    }
    return 'N/A';
  };

  if (downloading && data && data.petParents) {
    const transformedData = data.petParents.map((pp) => ({
      'First Name': pp.firstName,
      'Last Name': pp.lastName,
      'Date Invited': pp.mailchimpSyncDate ? moment(pp.mailchimpSyncDate).format('MM/DD/YYYY') : 'N/A',
      Email: pp.email,
      Phone: pp.phoneNumber,
      'SMS Status': getSMSStatus(pp),
      Animals: pp.animalCount,
      Interests: pp.interests,
    }));
    csvDownload(transformedData, `Pet_Parents_${new Date().toISOString()}.csv`);
    setDownloading(false);
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={error?.message}
      >
        <Alert severity="error">{'Error downloading CSV. We\'re working on it.'}</Alert>
      </Snackbar>
      <Button
        disabled={downloading}
        size="small"
        variant="outlined"
        startIcon={<FileDownloadOutlined />}
        onClick={() => {
          getPetParents({
            variables: {
              offset: 0,
              limit: 10000,
              smsStatus,
              interests,
              order,
              orderBy,
              searchText,
              searchField,
              onlyGroupByPetParent,
            },
          });
          setDownloading(true);
        }}
      >
        Download
      </Button>
    </>
  );
}

export default ProfilesDownloadDataChip;
