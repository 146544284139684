import React, { ReactNode, createContext, useContext } from 'react';
import { AwoContextType } from '../types/Awo';

const Context = createContext<AwoContextType>({ awo: {}, loading: true });
export const useAwoContext = () => useContext(Context);
function AwoContext({ children, awo } : { children: ReactNode, awo: AwoContextType }) {
  return (
    <Context.Provider value={awo}>
      {children}
    </Context.Provider>
  );
}
export default AwoContext;
