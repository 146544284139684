import React from 'react';
import { Widget } from '@typeform/embed-react';
import { useLocation } from 'react-router-dom';

const typeformWidgets: Record<string, string> = {
  '/hub/dog-adopter-day-10-survey': 'tdyG9TOu',
  '/hub/cat-adopter-day-10-checkin': 'yEcGoS7z',
  '/hub/adoption-review': 'APrSPnwQ',
  '/hub/petcademy-review': 'GW6sjaND',
};

function Typeform() {
  const location = useLocation();
  const widgetTypeformId = typeformWidgets[location.pathname];

  if (!widgetTypeformId) {
    return null;
  }

  return (
    <Widget
      id={widgetTypeformId}
      style={{
        height: '100vh',
        width: '100%',
        margin: '0',
        overflow: 'hidden',
      }}
    />
  );
}

export default Typeform;
