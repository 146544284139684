import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { Control } from 'react-hook-form';
import { CheckboxElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const feedbackScoresSchema = {
  feedback1: yup.boolean(),
  feedback2: yup.boolean(),
  feedback3: yup.boolean(),
  feedback4: yup.boolean(),
  feedback5: yup.boolean(),
};

function FeedbackScoresPartial(
  { control }:
    {
      control: Control<any, any>,
    },
) {
  return (
    <>
      <Typography variant="body1">Feedback scores</Typography>
      <Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Says things with their pet are 5 out of 5"
            name="feedback5"
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Says things with their pet are 4 out of 5"
            name="feedback4"
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Says things with their pet are 3 out of 5"
            name="feedback3"
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Says things with their pet are 2 out of 5"
            name="feedback2"
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Says things with their pet are 1 out of 5"
            name="feedback1"
          />
        </Box>
      </Box>
    </>
  );
}
export default FeedbackScoresPartial;
