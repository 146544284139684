import React from 'react';
import OnboardingDialog from './OnboardingDialog';

function Welcome() {
  return (
    <OnboardingDialog />
  );
}

export default Welcome;
