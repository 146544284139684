import React, { ReactNode } from 'react';
import {
  Box, Typography,
  Card, Button,
  Link,
} from '@mui/material';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import mixpanel from 'mixpanel-browser';
import OutlinedIconBadge from './OutlinedIconBadge';
import { colors } from '../../theme';
import { phoneFormatter } from '../../utilities';

function AskBadge({ phoneNumber, inactiveMembership }: { phoneNumber: string, inactiveMembership: boolean }) {
  return inactiveMembership ? (
    <Box>
      <Card variant="outlined" sx={{ border: 'none', background: colors.grey['200'] }}>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ flexGrow: 1, color: colors.primary.main }} variant="body2">
            Enable unlimited messaging with our certified trainers
          </Typography>
          <Button
            onClick={() => {
              mixpanel.track('upgrade_now', undefined, () => {
                window.open('https://petcademy.org/pricing/', '_blank', 'noopener');
              });
            }}
            sx={{ ml: 2, whiteSpace: 'nowrap', flexShrink: 0 }}
            variant="contained"
          >
            Upgrade now
          </Button>
        </Box>
      </Card>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Link
        onClick={() => {
          mixpanel.track('send_text_click');
        }}
        href={`sms:${phoneNumber}`}
        sx={{ textDecoration: 'none', display: { md: 'none' } }}
      >
        <OutlinedIconBadge
          icon={<SmsOutlinedIcon />}
          title={`Send a text to ${phoneFormatter(phoneNumber)}`}
          subheader="Trainers respond within 24 hours"
          background={colors.grey[50]}
          iconOpacity={100}
          iconBackground={colors.primary[50]}
          iconColor={colors.primary.main}
        />
      </Link>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <OutlinedIconBadge
          icon={<SmsOutlinedIcon />}
          title={`Send a text to ${phoneFormatter(phoneNumber)}`}
          subheader="Trainers respond within 24 hours"
          background={colors.grey[50]}
          iconOpacity={0.12}
          iconBackground="white"
          iconColor="black"
        />
      </Box>
    </Box>
  );
}

export default AskBadge;
