import { Box, FormGroup, Typography } from '@mui/material';
import {
 Control, Controller, ControllerRenderProps,
} from 'react-hook-form';
import React from 'react';
import PhoneInput from 'react-phone-input-2';

export function CheckBoxControl(
  {
 fieldName, control, renderItems,
}:
    {
      fieldName: string,
      control: Control<any>;
      renderItems: (value: Set<string>) => JSX.Element,
    },
) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
      const { onChange, value, ...props } = field;
      return (
        <FormGroup
          {...props}
          onChange={((event) => {
            const { name, checked } = (event.target as HTMLInputElement);
            if (checked) {
              value.add(name);
              onChange(value);
            } else {
              value.delete(name);
              onChange(value);
            }
          })}
          sx={{ marginTop: '16px' }}
        >
          {renderItems(value)}
        </FormGroup>
      );
    }}
    />
  );
}

export function AutocompleteControl(
  { fieldName, control, render }:
    {
      fieldName: string,
      control: Control<any>;
      render: (field: ControllerRenderProps<any, string>) => JSX.Element
    },
) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => render(field)}
    />
  );
}

export function RadioControl(
  { fieldName, control, render }:
   {
     fieldName: string,
     control: Control<any>;
     render: (field: ControllerRenderProps<any, string>) => JSX.Element
   },
) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => render(field)}
    />
  );
}

export function PhoneControl({
 fieldName, rules, isValid, control, country,
}:
{ fieldName: string;
  rules: any;
  isValid: (v: string, c: any) => boolean;
  control: Control<any>;
  country: string;
}) {
  return (
    <Controller
      name={fieldName}
      rules={rules}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <style>
            {`
              .react-tel-input .flag-dropdown {
                border: none;
                background: none;
                top: -5px;
                height: 30px;
              }
              .react-tel-input .form-control {
                padding: 0 48px;
                height: 30px;
                width: 100%;
                font-size: 1rem;
                line-height: 1.4375em;
                border: none;
                border-bottom: 2px solid #3700B3;
                border-radius: 0;
                top: -4px;
              }
              .react-tel-input .form-control.invalid-number, .react-tel-input .form-control.invalid-number:focus {
                border: none;
                border-bottom: 2px solid #d79f9f;
                background: none;
              }
              .react-tel-input .invalid-number-message {
                left: 0;
                top: 27px;
                font-size: 0.75rem;
                padding: 0;
                background: none;
              }
            `}
          </style>
          <Typography sx={{ marginTop: '-2px' }} variant="caption">Phone Number</Typography>
          <PhoneInput
            autoFormat={false}
            country={country}
            containerClass="MuiFormControl-root"
            containerStyle={{ height: '100%' }}
            defaultErrorMessage="Phone number not valid"
            onlyCountries={['us', 'au', 'ca']}
            disableCountryGuess
            placeholder=""
            value={value}
            onChange={onChange}
            isValid={isValid}
          />
        </Box>
      )}
    />
  );
}
