import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import NetlifyIdentityContext, { useIdentityContext } from 'react-netlify-identity-gotrue';
import { get } from 'lodash';
import moment from 'moment';
import CacheBuster from 'react-cache-buster';
import { Widget } from '@typeform/embed-react';
import { useQuery, useMutation } from '@apollo/client';
import packageInfo from '../package.json';
import { CREATE_TOKEN_SIGN_IN, GET_IS_AUTHENTICATED } from './queries';
import AdminContainer from './components/Admin';
import Courses from './components/Courses';
import CreatePassword from './components/CreatePassword';
import Dashboard from './components/Dashboard';
import FeedbackContainer from './components/Feedback';
import ForgotPassword from './components/Forgot';
import ForgotPasswordSubmission from './components/ForgotSubmission';
import Login from './components/Login';
import Profiles from './components/Profiles';
import PhotosContainer from './components/Photos';
import ScrollToTop from './components/ScrollToTop';
import ErrorPage from './components/ErrorPage';
import WithSideNav from './components/withSideNav';
import { beforeCapture } from './utilities';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import QRCode from './components/QRCode';
import { useAwoContext } from './components/AwoContextProvider';
import Cases from './components/Cases';
import Organizations from './components/Organizations';
import SingleOrganization from './components/Organizations/Single';
import SettingsPanel from './components/Organizations/SettingsPanel';
import MembersPanel from './components/Organizations/MembersPanel';
import Settings from './components/Settings';
import SMSContainer from './components/SMS';
import TagReviewContainer from './components/TagReview';
import HubContainer from './components/Hub';
import Welcome from './components/Hub/Welcome';
import Home from './components/Hub/Home';
import MyPets from './components/Hub/MyPets';
import Ask from './components/Hub/Ask';
import Typeform from './components/Hub/Typeform';

function InvitePage() {
  const { awo, loading } = useAwoContext();
  if (loading) return null;
  const isProduction = window.location.hostname.includes('app.petcademy');
  let widgetTypeformId = isProduction ? 'zOiUOhW6' : 'H0moK7G1';

  if (awo?.preSurrenderDashboard) {
    widgetTypeformId = isProduction ? 'wrbGG2Ke' : 'obn0GjSK';
  }
  if (awo?.coSheltering) {
    widgetTypeformId = isProduction ? 'c4THUJ6w' : 'vYP4iKbo';
  }

  return (
    <Widget
      id={widgetTypeformId}
      style={{
        height: '100%',
        width: '100%',
        margin: '0',
        overflow: 'hidden',
      }}
      medium="awo-invite"
      hidden={{ awo: awo.name, coordinators: awo.coShelteringCoordinators ? 'yes' : 'no' }}
      iframeProps={{ title: 'Invite Pet Parent' }}
    />
  );
}

function AuthenticateToken() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  localStorage.setItem('petcademyToken', token);
  const { loading, data } = useQuery(GET_IS_AUTHENTICATED, {
    variables: {
      token: searchParams.get('token'),
    },
  });
  const [createTokenSignIn] = useMutation(CREATE_TOKEN_SIGN_IN, {
    ignoreResults: true,
  });

  if (loading) return null;
  if (data && data.isAuthenticated) {
    createTokenSignIn({
      variables: {
        token,
      },
    });
    const to = searchParams.get('redirect') || '/hub/home';
    return <Navigate to={to} state={{ firstLogIn: true }} />;
  }
  return <Navigate to="/" state={{ authError: 'You need to login before continuing.', comingFrom: location.pathname + location.hash }} replace />;
}

function RequireTokenAuth({ children }) {
  const location = useLocation();
  const token = localStorage.getItem('petcademyToken');
  const isProduction = process.env.NODE_ENV === 'production';

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ authError: 'You need to login before continuing.', comingFrom: location.pathname + location.hash }} replace />;
  }

  if (location.pathname === '/hub/welcome' && !location.state?.firstLogIn) {
    return <Navigate to="/hub/home" replace />;
  }

  return (
    <Sentry.ErrorBoundary
      key={location.pathname}
      fallback={<ErrorPage />}
      beforeCapture={beforeCapture}
    >
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} // If false, the library is disabled.
        isVerboseMode={false} // If true, the library writes verbose logs to console.
        loadingComponent={children}
      >
        {children}
      </CacheBuster>
    </Sentry.ErrorBoundary>
  );
}

function RequireAuth({ children, roles }) {
  const identity = useIdentityContext();
  const location = useLocation();
  const tokenString = localStorage.getItem('ni.goTrueToken');
  if (tokenString === null) {
    return <Navigate to="/" state={{ authError: 'You need to login before continuing.', comingFrom: location.pathname + location.hash }} replace />;
  }

  const tokenJSON = tokenString ? JSON.parse(tokenString) : { expires_at: 0 };
  const isProduction = process.env.NODE_ENV === 'production';

  const tokenExpired = moment.utc().isAfter(tokenJSON.expires_at);
  if (tokenExpired) {
    // don't do anything - wait for re-render
    return '';
  }

  if (!identity.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ authError: 'You need to login before continuing.', comingFrom: location.pathname + location.hash }} replace />;
  }

  if (roles && get(identity, 'user.app_metadata.roles[0]') !== roles) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ authError: 'This page is for admins only.' }} replace />;
  }

  return (
    <WithSideNav>
      <Sentry.ErrorBoundary
        key={location.pathname}
        fallback={<ErrorPage />}
        beforeCapture={beforeCapture}
      >
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={isProduction} // If false, the library is disabled.
          isVerboseMode={false} // If true, the library writes verbose logs to console.
          loadingComponent={children}
        >
          {children}
        </CacheBuster>
      </Sentry.ErrorBoundary>
    </WithSideNav>
  );
}

function RequireInviteToken({ children }) {
  const location = useLocation();
  if (!(location.hash.includes('invite_token') || location.hash.includes('recovery_token'))) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <NetlifyIdentityContext url="https://app.petcademy.org">
      <Router>
        <ScrollToTop />
        <SentryRoutes>
          <Route path="/" element={<Login />} />
          <Route
            path="/create-password"
            element={(
              <RequireInviteToken>
                <CreatePassword />
              </RequireInviteToken>
            )}
          />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/forgot-submission" element={<ForgotPasswordSubmission />} />
          <Route
            path="/dashboard"
            element={(
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            )}
          />
          <Route
            path="/authenticate"
            element={(
              <AuthenticateToken />
            )}
          />
          <Route
            path="/hub"
            element={(
              <RequireTokenAuth>
                <HubContainer />
              </RequireTokenAuth>
            )}
          >
            <Route
              path="home"
              element={(
                <RequireTokenAuth>
                  <Home />
                </RequireTokenAuth>
              )}
            />
            <Route
              path="my-pets"
              element={(
                <RequireTokenAuth>
                  <MyPets />
                </RequireTokenAuth>
              )}
            />
            <Route
              path="ask"
              element={(
                <RequireTokenAuth>
                  <Ask />
                </RequireTokenAuth>
              )}
            />
            <Route
              path="ask"
              element={(
                <Ask />
              )}
            />
            <Route
              path="dog-adopter-day-10-survey"
              element={(
                <Typeform />
              )}
            />
            <Route
              path="cat-adopter-day-10-checkin"
              element={(
                <Typeform />
              )}
            />
            <Route
              path="adoption-review"
              element={(
                <Typeform />
              )}
            />
            <Route
              path="petcademy-review"
              element={(
                <Typeform />
              )}
            />
            <Route
              path="welcome"
              element={(
                <RequireTokenAuth>
                  <Welcome />
                </RequireTokenAuth>
              )}
            />
          </Route>
          <Route
            path="/feedback"
            element={(
              <RequireAuth>
                <FeedbackContainer />
              </RequireAuth>
            )}
          />
          <Route
            path="/courses"
            element={(
              <RequireAuth>
                <Courses />
              </RequireAuth>
            )}
          />
          <Route
            path="/profiles"
            element={(
              <RequireAuth>
                <Profiles />
              </RequireAuth>
            )}
          />
          <Route
            path="/photos"
            element={(
              <RequireAuth roles="admin">
                <PhotosContainer />
              </RequireAuth>
            )}
          />
          <Route
            path="/sms"
            element={(
              <RequireAuth roles="admin">
                <SMSContainer />
              </RequireAuth>
            )}
          />
          <Route
            path="/typeforms"
            element={(
              <RequireAuth roles="admin">
                <AdminContainer />
              </RequireAuth>
            )}
          />
          <Route
            path="/invite"
            element={(
              <RequireAuth>
                <InvitePage />
              </RequireAuth>
            )}
          />
          <Route
            path="/qr-code"
            element={(
              <RequireAuth>
                <QRCode />
              </RequireAuth>
            )}
          />
          <Route
            path="/cases"
            element={(
              <RequireAuth>
                <Cases />
              </RequireAuth>
            )}
          />
          <Route
            path="/organizations"
            element={(
              <RequireAuth roles="admin">
                <Organizations />
              </RequireAuth>
            )}
          />
          <Route
            path="/organizations/:organizationId"
            element={(
              <RequireAuth roles="admin">
                <SingleOrganization />
              </RequireAuth>
            )}
          >
            <Route
              path="settings"
              element={<SettingsPanel />}
            />
            <Route
              path="members"
              element={<MembersPanel />}
            />
          </Route>
          <Route
            path="/settings"
            element={(
              <RequireAuth>
                <Settings />
              </RequireAuth>
            )}
          />
          <Route
            path="/tag-review"
            element={(
              <RequireAuth roles="admin">
                <TagReviewContainer />
              </RequireAuth>
            )}
          />
          <Route
            path="/tag-review/:ticketId"
            element={(
              <RequireAuth roles="admin">
                <TagReviewContainer />
              </RequireAuth>
            )}
          />
        </SentryRoutes>
      </Router>
    </NetlifyIdentityContext>
  );
}

export default App;
