import React, { MouseEventHandler, ReactNode } from 'react';
import {
  ListItemButton, ListItemIcon, ListItemText, Skeleton, SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useNavigate } from 'react-router-dom';

type NavigationItemProps = {
  name: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & { muiName: string; };
  title: ReactNode;
  visible?: boolean;
  selected?: string;
};

function NavigationItem({
  name, onClick, Icon, title, visible, selected,
}: NavigationItemProps) {
  const navigate = useNavigate();
  if (!visible) return null;

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    } else {
      navigate(`/${name}`);
    }
  };

  const isSelected = selected && selected.startsWith(`/${name}`);
  return (
    <ListItemButton
      onClick={onClickHandler}
      sx={{
        bgcolor: isSelected ? '#fff' : undefined,
        color: isSelected ? 'primary.dark' : undefined,
        '&:hover': {
          bgcolor: isSelected ? '#EEECFB' : undefined,
          color: isSelected ? 'primary.dark' : undefined,
        },
      }}
    >
      <ListItemIcon>
        {
          Icon
            ? <Icon sx={{ color: isSelected ? 'primary.dark' : 'primary.contrastText' }} />
            : <Skeleton sx={{ bgcolor: 'primary.contrastText' }} variant="circular" width={24} height={24} />
        }

      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
}

export default NavigationItem;
