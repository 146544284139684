import React from 'react';
import { Link, Typography } from '@mui/material';

function Copyright(color) {
  return (
    <>
      <Typography variant="caption" color={color} display="block">
        <Link
          href="/privacy-policy"
          underline="hover"
          color={color}
          rel="noopener"
          target="_blank"
        >
          Privacy Policy
        </Link>
        {' '}
        •
        <Link
          href="/terms-and-conditions"
          underline="hover"
          color={color}
          rel="noopener"
          target="_blank"
        >
          Terms
        </Link>
      </Typography>
      <Typography variant="caption" color={color} display="block">
        © Petcademy
        {' '}
        {new Date().getFullYear()}
      </Typography>
    </>
  );
}

export default Copyright;
