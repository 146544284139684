import React, { useMemo } from 'react';
import { capitalize, isEmpty, isNil } from 'lodash';
import {
  Box, IconButton, ListItem, ListItemText, Snackbar,
} from '@mui/material';
import {
  DatePickerElement, FieldValues, FormContainer, SelectElement, TextFieldElement,
} from 'react-hook-form-mui';
import { CheckOutlined, CloseOutlined, LoopOutlined } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { PhoneControl } from '../FormControls';
import phoneCountries from '../../resources/phoneCountries';
import EditingModeContext from './editingContext';
import { UPDATE_PET_PARENT_ANIMAL } from '../../queries';
import { beforeCapture } from '../../utilities';
import { useAwoContext } from '../AwoContextProvider';

function ListItemEditable({
  action,
  primary,
  secondary,
  editable,
}: any) {
  const { awo } = useAwoContext();
  const {
    editableFieldParentLabel,
    setEditableFieldParentLabel,
    petParent,
    refetch,
  } = React.useContext(EditingModeContext);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [country, setCountry] = React.useState<any>(null);
  const defaultValues = useMemo(() => (petParent ? {
    id: petParent.id,
    firstName: capitalize(petParent.firstName),
    lastName: capitalize(petParent.lastName),
    email: petParent.email.toLowerCase(),
    phoneNumber: petParent.phoneNumber,
    streetAddress: petParent.streetAddress,
    state: petParent.state,
    country: petParent.country,
    animals: petParent.animals.map((a) => ({
      ...a,
      name: !isEmpty(a.name) ? capitalize(a.name) : 'No  name',
      careType: a.careType,
      animalType: a.animalType === 'Kitten' ? 'Cat' : a.animalType,
      gender: !isEmpty(a.gender) ? a.gender : '',
      birthDate: a.birthDate ? moment(a.birthDate).format('M/DD/YYYY') : null,
      exitDate: a.exitDate ? moment(a.exitDate).format('M/DD/YYYY') : null,
      caseNumber: a.caseNumber || '',
    })),
  } : {}), [petParent]);
  const [editingMode, setEditingMode] = React.useState(false);
  const editingHandler = () => {
    if (editable && !editableFieldParentLabel) {
      setEditingMode(true);
      // @ts-ignore
      setEditableFieldParentLabel(editable.parentLabel);
    }
  };
  const closeHandler = () => {
    setEditingMode(false);
    // @ts-ignore
    setEditableFieldParentLabel(false);
  };

  const phoneControlRule = (value: string) => {
    const invalidMessage = 'Phone number not valid';
    if (value || defaultValues?.phoneNumber) {
      const emptyPassLimit = country?.iso2 === 'au' ? 3 : 2;
      const empty = value.length < emptyPassLimit;
      const format = value.replace(/[\D]/g, '').length === country.format.replace(/[^.]/g, '').length;
      const auFormat = (country?.iso2 === 'au' && value.replace(/[\D]/g, '').length === country.format.replace(/[^.]/g, '').length - 1);
      return empty || format || auFormat || invalidMessage;
    }
    return true;
  };

  const phoneControlValidation = (value: string, vCountry: any) => {
    if (isNil(vCountry) || isNil(vCountry.format)) return false;
    setCountry(vCountry);
    const emptyPassLimit = vCountry.iso2 === 'au' ? 3 : 2;
    const empty = isEmpty(defaultValues?.phoneNumber) && value.length < emptyPassLimit;
    const format = value.length === vCountry.format.replace(/[^.]/g, '').length;
    const auFormat = (vCountry.iso2 === 'au' && value.length === vCountry.format.replace(/[^.]/g, '').length - 1);
    return empty || format || auFormat;
  };

  const cleanUpPhoneNumber = (value: any) => {
    if (value.phoneNumber === '1' || value.phoneNumber === '61') {
      return { ...value, phoneNumber: '' };
    }
    return value;
  };

  const [updatePetParentAnimal, { loading }] = useMutation(UPDATE_PET_PARENT_ANIMAL, {
    onCompleted: (d) => {
      setSnackbarOpen(true);
      setSnackbarMessage('Profile saved');
      closeHandler();
      if (typeof refetch === 'function') {
        refetch();
      }
    },
    onError: (e) => {
      Sentry.withScope((scope) => {
        beforeCapture(scope, e);
        Sentry.captureException(e);
      });
      setSnackbarOpen(true);
      setSnackbarMessage('Error updating pet parent');
    },
  });

  const formContext = useForm({ defaultValues });

  const updateProfileHandler = async (d: FieldValues) => {
    console.log(d);
    await updatePetParentAnimal({ variables: { oldData: defaultValues, newData: cleanUpPhoneNumber(d) } });
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <FormContainer
        formContext={formContext}
        onSuccess={updateProfileHandler}
      >
        <ListItem
          onClick={editingHandler}
          dense
          style={{ padding: '0px 24px' }}
          sx={editable && (!editingMode ? {
            cursor: 'pointer',
            transition: 'background 400ms',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.04)',
            },
          } : {
            pointerEvents: 'none',
            opacity: 0,
          })}
          secondaryAction={action}
        >
          <Box sx={{ width: '100%' }}>
            <ListItemText
              primaryTypographyProps={{ variant: 'caption', sx: (editableFieldParentLabel && !editingMode) ? { color: 'rgba(0, 0, 0, 0.38)' } : { color: 'rgba(0, 0, 0, 0.6)' } }}
              secondaryTypographyProps={{
              variant: 'body2',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              sx: (editableFieldParentLabel && !editingMode) ? { color: 'rgba(0, 0, 0, 0.38)' } : { color: 'rgba(0, 0, 0, 1)' },
            }}
              {...{ primary, secondary }}
            />
          </Box>
        </ListItem>
        {
        editingMode && (
          <ListItem
            sx={{
              display: 'flex', alignItems: 'center', top: '8px', left: '0px', padding: '0 24px', position: 'absolute', width: '100%', gap: '8px',
            }}
          >
            {
              !editable.type && (
                <TextFieldElement
                  autoFocus
                  fullWidth
                  size="small"
                  required
                  label={editable.label}
                  name={editable.name}
                  validation={editable.validation}
                  InputLabelProps={{
                    shrink: true,
                    sx: { letterSpacing: ' 0.032rem' },
                  }}
                  variant="standard"
                />
              )
            }
            {
              editable.type === 'select' && (
                <SelectElement
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                    sx: { letterSpacing: ' 0.032rem' },
                  }}
                  label={editable.label}
                  name={editable.name}
                  options={editable.options}
                  variant="standard"
                />
              )
            }
            {
              editable.type === 'phone' && (
                <PhoneControl
                  fieldName={editable.name}
                  country={awo?.country ? phoneCountries[awo?.country] : 'us'}
                  control={formContext.control}
                  rules={{
                    validate: phoneControlRule,
                  }}
                  isValid={phoneControlValidation}
                />
              )
            }
            {
              editable.type === 'date' && (
                <DatePickerElement
                  inputProps={{
                    fullWidth: true,
                    variant: 'standard',
                    size: 'small',
                    InputLabelProps: {
                      shrink: true,
                      sx: { letterSpacing: ' 0.032rem' },
                    },
                  }}
                  disableFuture
                  label={editable.label}
                  name={editable.name}
                  validation={editable.validation}
                  onChange={(value) => formContext.setValue(editable.name, value ? value.format('M/DD/YYYY') : null)}
                />
              )
            }
            {
              editable.type === 'address' && (
                <>
                  <TextFieldElement
                    fullWidth
                    label="State"
                    name="state"
                    size="small"
                    required
                    InputLabelProps={{
                      shrink: true,
                      sx: { letterSpacing: ' 0.032rem' },
                    }}
                    variant="standard"
                  />
                  <TextFieldElement
                    fullWidth
                    label="Country"
                    name="country"
                    size="small"
                    required
                    InputLabelProps={{
                      shrink: true,
                      sx: { letterSpacing: ' 0.032rem' },
                    }}
                    variant="standard"
                  />
                </>
              )
            }
            {
              loading ? (
                <IconButton type="button" size="small">
                  <LoopOutlined
                    sx={{
                      transform: 'scaleX(-1)',
                      animation: 'animate-rotation 3s ease-in-out infinite',
                    }}
                    fontSize="small"
                  />
                </IconButton>
              ) : (
                <IconButton type="submit" size="small">
                  <CheckOutlined color="primary" fontSize="small" />
                </IconButton>
              )
            }
            <IconButton type="button" onClick={closeHandler} size="small">
              <CloseOutlined color={loading ? 'disabled' : 'primary'} fontSize="small" />
            </IconButton>
          </ListItem>
        )
      }
      </FormContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        open={snackbarOpen}
        message={snackbarMessage}
        key={snackbarMessage}
      />
    </Box>
  );
}

export default ListItemEditable;
