import React from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TablePaginationActions from './TablePaginationActions';
import { Awo } from '../types/Awo';
import { automatedSMSNames } from '../utilities';
import { UsePaginationReturn } from './Organizations/usePagination';

interface OrganizationsTableProps {
  pagination: UsePaginationReturn;
  rowsPerPageOptions: number[];
  count: number;
  loading: boolean;
  organizations: Awo[];
  networkStatus: NetworkStatus;
}

function OrganizationsTable({
  pagination,
  rowsPerPageOptions,
  count,
  loading,
  organizations,
  networkStatus,
}: OrganizationsTableProps) {
  const {
    rowsPerPage,
    page,
    order,
    orderBy,
    handleChangeOrder,
    handleChangePage,
    handleChangeRowsPerPage,
  } = pagination;

  const headCells = [{
    id: 'name',
    label: 'Name',
    width: '30%',
  }, {
    id: 'city',
    label: 'City',
    width: '15%',
  }, {
    id: 'state',
    label: 'State',
    width: '10%',
  }, {
    id: 'country',
    label: 'Country',
    width: '10%',
  }, {
    id: 'members',
    label: 'Members',
    width: '5%',
  }, {
    id: 'sms_name',
    label: 'Software',
    width: '10%',
  }, {
    id: 'status',
    label: 'Status',
    width: '10%',
  }, {
    id: 'lastSyncDate',
    label: 'Last sync',
    width: '10%',
  }];

  const smsNamesDict: Record<string, string> = {
    Shelterluv: 'Shelterluv',
    'Shelter Buddy': 'Shelter Buddy',
    Petstablished: 'Petstablished',
    Pawlytics: 'Pawlytics',
    'Animal Shelter Manager': 'ASM',
    'Animals First': 'Animals First',
    Chameleon: 'Chameleon',
    Petpoint: 'Petpoint',
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleChangeOrder(event, property);
  };
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table size="small" sx={{ minWidth: '500px' }} aria-label="Organizations Table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{ width: headCell.width }}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || networkStatus === NetworkStatus.refetch ? <tr><td colSpan={8} aria-label="Loading progress"><LinearProgress /></td></tr> : null}
          {!loading && organizations.map((row) => (
            <TableRow
              key={`${row.id}_${row.name}`}
              hover
              onClick={() => {
                navigate(`/organizations/${row.id}/settings`);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {row.name}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {row.city}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {row.state}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {row.country}
              </TableCell>
              <TableCell>{row.members}</TableCell>
              <TableCell>
                {smsNamesDict[row.smsName] || row.smsName}
              </TableCell>
              <TableCell>
                <Chip
                  label={row.status}
                  size="small"
                  color={row.status === 'Deactivated' ? 'default' : 'primary'}
                  disabled={row.status === 'Deactivated'}
                  sx={{
                    '& .MuiChip-label': {
                      textTransform: 'capitalize',
                    },
                  }}
                />
              </TableCell>
              {
                !automatedSMSNames.includes(row.smsName)
                  || row.status === 'Deactivated'
                  ? (
                    <TableCell>
                      <Chip
                        label="N/A"
                        size="small"
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Chip
                        label={row.lastSyncDate ? 'Success' : 'Error'}
                        size="small"
                        sx={{
                          '& .MuiChip-label': {
                            textTransform: 'capitalize',
                          },
                        }}
                      />
                    </TableCell>
                  )
              }
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={8}
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default OrganizationsTable;
