import React from 'react';
import Button from '@mui/material/Button';
import {
  Box, TextField, IconButton, InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { useNavigate } from 'react-router-dom';
import SidebarPage from '../SidebarPage';

function CreatePassword() {
  const identity = useIdentityContext();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState('');
  const [passwordRepeat, setPasswordRepeat] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = React.useState(false);
  const [makingRequest, setMakingRequest] = React.useState(false);

  const invalidPassword = () => password.length >= 1 && password.length < 8;
  const invalidPasswordRepeat = () => password !== passwordRepeat;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMakingRequest(true);
    await identity.completeUrlTokenTwoStep({ password }).then(() => {
      setMakingRequest(false);
      navigate('/dashboard', { replace: true });
    // eslint-disable-next-line no-console
    }).catch(() => console.log('Having an issue.. please try later'));
  };

  return (
    <SidebarPage
      mobileHeader="Reset Password"
      greenWord="Create"
      headerText=" your Petcademy password"
      handleSubmit={handleSubmit}
      FormComponent={(
        <>
          <Box sx={{
            width: {
              xs: '100%',
              sm: '327px',
            },
          }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={invalidPassword()}
              helperText={invalidPassword() ? 'Password must be at least 8 characters long' : 'Enter your password'}
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="repeat-password"
              label="Repeat Password"
              type={showPasswordRepeat ? 'text' : 'password'}
              id="repeat-password"
              autoComplete="current-password"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              error={passwordRepeat.length >= 1 && invalidPasswordRepeat()}
              helperText={passwordRepeat.length >= 1 && invalidPasswordRepeat() ? 'Passwords must match' : 'Repeat your password'}
              disabled={password.length === 0 || invalidPassword()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                    >
                      {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={passwordRepeat.length === 0 || invalidPasswordRepeat() || makingRequest}
            sx={{ mt: 3, mb: 2, mr: 2 }}
          >
            Set Password
          </Button>
        </>
      )}
    />
  );
}

export default CreatePassword;
