import React from 'react';
import {
  Box, Button, CardActions, CardContent, CardHeader, Dialog, Divider, Snackbar, TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMutation } from '@apollo/client';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { get } from 'lodash';
import moment from 'moment';
import { PetParent } from '../../types/PetParent';
import { STOP_SUPPORT_UNSUBSCRIBE } from '../../queries';

type DialogProps = { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>>; petParent: PetParent; };
const ACTION = 'change_start_date';

function ChangeStartDateDialog({
 open, setOpen, petParent,
}: DialogProps) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(moment().add(1, 'day'));
  const [stopSupportUnsubscribe, { loading }] = useMutation(STOP_SUPPORT_UNSUBSCRIBE, {
    onCompleted: () => {
      setOpen(false);
    },
    onError: () => {
      setOpen(false);
      setSnackbarOpen(true);
      setSnackbarMessage('Error stopping support for pet parent');
    },
    refetchQueries: [
      'GetPetParent',
      'GetTypeformResponses',
    ],
  });
  const identity = useIdentityContext();
  return (
    <>
      <Dialog
        open={open}
        scroll="paper"
        onClose={() => setOpen(false)}
        aria-labelledby="change-start-date-dialog"
        aria-describedby="change-start-date-description"
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '584px',
            maxWidth: '90vw',
          },
        }}
      >
        <CardHeader
          title="Change start date"
          sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.12)' }}
          /* eslint-disable-next-line max-len */
          subheader={`Welcome messages and support period for ${petParent.activeConversation?.animal.name} will restart on the date selected`}
        />
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box sx={{
              position: 'relative',
              width: '320px',
            }}
            >
              <DatePicker
                value={startDate}
                onChange={(value) => {
                setStartDate(value);
              }}
                PopperProps={{
                  placement: 'bottom-start',
                }}
                label="Date"
                minDate={moment().add(1, 'day')}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => stopSupportUnsubscribe({
              variables: {
                email: petParent.email,
                actionerEmail: get(identity, 'user.email'),
                action: ACTION,
                newStartDate: startDate!.format('M/DD/YYYY'),
              },
            })}
          >
            Change start date
          </Button>
          <Button variant="text" onClick={() => setOpen(false)}>Cancel</Button>
        </CardActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        open={snackbarOpen}
        message={snackbarMessage}
        key={snackbarMessage}
      />
    </>
  );
}

export default ChangeStartDateDialog;
