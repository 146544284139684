import React from 'react';
import {
  Close, SettingsOutlined,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme, useMediaQuery, MenuItem, MenuList,
} from '@mui/material';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { isNil, get } from 'lodash';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GET_PET_PARENT } from '../queries';
import ErrorPage from './ErrorPage';
import { beforeCapture, hashids, isInactiveAnimal } from '../utilities';
import 'react-phone-input-2/lib/style.css';
import ProfileSkeleton from './WithProfile/profileSkeleton';
import ProfileContent from './WithProfile/profileContent';
import IconFilter from './IconFilter';
import CopyToClipboard from './CopyToClipboard';
import StopSupportDialog from './WithProfile/stopSupportDialog';
import { PetParent } from '../types/PetParent';
import ChangeStartDateDialog from './WithProfile/changeStartDateDialog';

interface WithProfileProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  setUserId: React.Dispatch<React.SetStateAction<number | null>>
}

function getDisplayName(WrappedComponent: React.ComponentType<WithProfileProps>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withProfile(WrappedComponent: React.ComponentType<WithProfileProps>) {
  function WithProfile() {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [stopSupportDialogOpen, setStopSupportDialogOpen] = React.useState(false);
    const [showInactiveAnimals, setShowInactiveAnimals] = React.useState(false);
    const [changeStartDateDialogOpen, setChangeStartDateDialogOpen] = React.useState(false);
    const [userId, setUserId] = React.useState<number | null>(null);
    const navigation = useNavigate();
    const location = useLocation();
    const identity = useIdentityContext();
    const isAdmin = get(identity, 'user.app_metadata.roles[0]') === 'admin';

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
      noSsr: true,
    });

    const {
      loading,
      error,
      data,
      refetch,
    } = useQuery<{ petParent: PetParent }>(GET_PET_PARENT, {
      variables: { id: Number.isNaN(userId) ? null : Number(userId) },
      skip: !userId,
      onError: (e) => {
        Sentry.withScope((scope) => {
          beforeCapture(scope, e);
          Sentry.captureException(e);
        });
      },
    });

    React.useEffect(() => window.scrollTo(0, 0), []);

    const closeDrawer = () => {
      setDrawerOpen(false);
      navigation(location.pathname);
    };

    const disableStopSupportButton = (admin: boolean, petParent: PetParent) => {
      let disable = false;
      if (admin) {
        disable = isNil(petParent.activeConversation)
          && !isNil(petParent.unsubscribeDate)
          && !isNil(petParent.smsOptOutDate);
      } else {
        disable = isNil(petParent.activeConversation);
      }
      return disable;
    };

    // eslint-disable-next-line
    const disableShowInactiveAnimals = (animals: PetParent['animals'][0][]) => animals.filter(a => isInactiveAnimal(a)).length === 0;

    const settingsMenu = (
      <CopyToClipboard message="Profile URL copied">
        {
          (copyText) => (
            <IconFilter
              id="settings-label"
              labelId="search-label"
              disabled={loading}
              icon={<SettingsOutlined fontSize="small" />}
              color="primary"
              placement="bottom-end"
              disablePortal
            >
              {
                (open, handleClose, handleListKeyDown) => (
                  <MenuList
                    autoFocusItem={open}
                    id="settings-composition-menu"
                    aria-labelledby="settings-composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {isAdmin && (
                      <MenuItem
                        onClick={(e) => {
                          setChangeStartDateDialogOpen(true);
                          handleClose(e);
                        }}
                        disabled={data && isNil(data.petParent.activeConversation)}
                      >
                        Change start date
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(e) => {
                        setStopSupportDialogOpen(true);
                        handleClose(e);
                      }}
                      value="stop"
                      disabled={data && disableStopSupportButton(isAdmin, data.petParent)}
                    >
                      Stop support
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        if (userId && typeof userId === 'string') {
                          copyText(`${window.location.origin}/profiles#id=${hashids.encode(userId)}`);
                        } else {
                          copyText(window.location.href);
                        }
                        handleClose(e);
                      }}
                      value="copy"
                    >
                      Copy profile URL
                    </MenuItem>
                    <MenuItem
                      disabled={data && disableShowInactiveAnimals(data.petParent.animals)}
                      onClick={(e) => {
                        setShowInactiveAnimals(!showInactiveAnimals);
                        handleClose(e);
                      }}
                    >
                      {showInactiveAnimals ? 'Hide inactive animals' : 'Show inactive animals'}
                    </MenuItem>
                  </MenuList>
                )
              }
            </IconFilter>
          )
        }
      </CopyToClipboard>
    );

    let content = null;
    if (error) {
      content = <ErrorPage />;
    } else if (loading || !data) {
      content = <ProfileSkeleton />;
    } else {
      content = (
        <ProfileContent
          petParent={data.petParent}
          refetch={refetch}
          isAdmin={isAdmin}
          showInactiveAnimals={showInactiveAnimals}
        />
      );
    }

    return (
      <>
        <Box>
          <WrappedComponent
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            setUserId={setUserId}
          />
        </Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Drawer
            anchor={isDesktop ? 'right' : 'bottom'}
            open={drawerOpen}
            onClose={closeDrawer}
            PaperProps={{
              sx: {
                minHeight: '100%',
                border: 'none',
                width: 'inherit',
                pt: {
                  xs: 7,
                  md: 0,
                },
              },
            }}
            sx={{
              width: {
                xs: '100%',
                md: '50%',
              },
              flexShrink: 0,
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Box sx={{
                position: 'sticky', inset: 0, background: '#fff', zIndex: 2,
              }}
              >
                <Box sx={{
                  px: 2,
                  py: 2.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                >
                  <Typography variant="overline">Profile</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    {settingsMenu}

                    <IconButton
                      color="primary"
                      size="small"
                      disabled={loading}
                      onClick={closeDrawer}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
              </Box>
              {content}
            </Box>
          </Drawer>
          {
            data && data.petParent
            && (
              <>
                <StopSupportDialog
                  petParent={data.petParent}
                  open={stopSupportDialogOpen}
                  setOpen={setStopSupportDialogOpen}
                />
                <ChangeStartDateDialog
                  petParent={data.petParent}
                  open={changeStartDateDialogOpen}
                  setOpen={setChangeStartDateDialogOpen}
                />
              </>
            )
          }
        </LocalizationProvider>
      </>
    );
  }
  WithProfile.displayName = `WithProfile(${getDisplayName(WrappedComponent)})`;
  return WithProfile;
}

export default withProfile;
