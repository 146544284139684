/* eslint-disable */
import { Box, Typography } from '@mui/material';
import React from 'react';

function PrivacyPolicy() {
  return (
    <Box sx={{
      p: {
        xs: 2,
        lg: 3,
      },
      maxWidth: '800px',
      margin: '0 auto',
    }}
    >
      <Typography variant="h4">Privacy Policy</Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy provides a website `}<a href="https://app.petcademy.org/">https://app.petcademy.org/</a>{` that offers behavioral support to new pet parents and allows animal shelters to see how the animals are doing after they leave the shelter.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy is committed to keeping any and all personal information collected of those individuals that visit our website and make use of our online facilities and services accurate, confidential, secure and private. Our privacy policy has been designed and created to ensure those affiliated with Petcademy of our commitment and realization of our obligation not only to meet but to exceed most existing privacy standards.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`This Privacy Policy governs the collection and use of all the information collected from all of our sources, whether the Product, Website, etc. Any terms not defined herein shall have the meaning given to them in the associated Terms of Use available at `}<a href="https://app.petcademy.org/terms-and-conditions">https://app.petcademy.org/terms-and-conditions</a>
      </Typography>
      <br />
      <Typography variant="body1">
        {`Your privacy is important to Petcademy, Inc. (“Petcademy,” “We,” “Us,” or “Our”).`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`ACCEPTANCE OF TERMS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Through the use of this website, you are hereby accepting the terms and conditions stipulated within the aforementioned Privacy Policy Agreement. If you are not in agreement with our terms and conditions, then you should refrain from further use of our sites. In addition, your continued use of our website following the posting of any updates or changes to our terms and conditions shall mean that you are in agreement and acceptance of such changes.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`1. QUESTIONS; CONTACTING PETCADEMY; REPORTING VIOLATIONS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`If you have any questions, concerns or complaints about Our Privacy Policy or Our data collection or processing practices, or if you want to report any security violations to Us, please contact `}<a href="mailto:bailey@petcademy.org">bailey@petcademy.org</a>{`.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`2. USER CONSENT`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`By submitting Personal Data (as defined below) through the Website or having such Personal Data collected through your use of the Website, you agree to the terms of this Privacy Policy, and you expressly consent to the collection, use and disclosure of your Personal Data in accordance with this Privacy Policy.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`3. CHILDREN UNDER AGE OF 13`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy does not knowingly collect personal identifiable information from children under the age of thirteen (13) without verifiable parental consent. If it is determined that such information has been inadvertently collected on anyone under the age of thirteen (13), we shall immediately take the necessary steps to ensure that such information is deleted from our system’s database. Anyone under the age of thirteen (13) must seek and obtain parent or guardian permission to use this website.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`4. TYPES OF DATA WE COLLECT`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`We get information and “Personal Data” about you in a range of ways. “Personal Data” means data that allows someone to identify or contact you, including, for example, your name, address, telephone number, e-mail address, as well as any other non-public information about you that is associated with or linked to any of the foregoing data. We collect Personal Data as described below.`}
      </Typography>
      <br />
      <Typography variant="body1" sx={{ ml: 3 }}>
        {`A. Information you provide to us`}
      </Typography>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {` We may collect Personal Data from you, such as your first and last name, gender, e-mail and mailing addresses, picture, phone numbers, any relevant background information, credit card and billing information and password when you create an account to log in to Our network and/or when you order Products through our Website (“Account”).`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {` If we offer a mobile application that you access on your mobile device, We may collect your phone number and the unique device id number.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {` We may also collect Personal Data at other points on our Website that state that Personal Data is being collected.`}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ ml: 3 }}>
        {`B. Information collected via Technology`}
      </Typography>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {` Information Collected by Our Servers. To make Our Website more useful to you, Our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (“IP”) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time stamp for your visit.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {` Log Files. As is true of most websites and applications, We gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider (“ISP”), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information to analyze trends, provide you with access to the Website, track users’ movements around the Website, gather demographic information about Our user base as a whole, and better tailor the Website and Product to Our users’ needs. For example, some of the information may be collected so that when you visit the Website again, it will recognize you and the information could then be used to serve advertisements and other information appropriate to your interests. Except as noted in this Privacy Policy, We do not link this automatically-collected data to Personal Data.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {` Cookies. Like many online websites, We use cookies to collect information. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience. This type of information is collected to make the Website more useful to you and to tailor the experience with Us to meet your special interests and needs.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Pixel Tags. In addition, We use “Pixel Tags” (also referred to as clear Gifs, Web beacons, or Web bugs). Pixel Tags are tiny graphic images with a unique identifier, similar in function to Cookies, which are used to track online movements of Web users. In contrast to Cookies, which are stored on a user’s computer hard drive, Pixel Tags are embedded invisibly in Web pages. Pixel Tags also allow Us to send e-mail messages in a format users can read, and they tell Us whether emails have been opened to ensure that We are sending only messages that are of interest to Our users. We may use this information to reduce or eliminate messages sent to a user. We do not tie the information gathered by Pixel Tags to Our users’ Personal Data.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`○ `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Mobile Services. In the event that We offer a mobile application, We may also collect non-personal information from your mobile device if you have downloaded Our mobile application. This information is generally used to help Us deliver the most relevant information to you. Examples of information that may be collected and used include how you use the mobile application and information about the type of device you use. In addition, in the event Our mobile application crashes on your mobile device, We may receive information about your mobile device model software version and device carrier, which allows Us to identify and fix bugs and otherwise improve the performance of Our mobile application. This information is sent to Us as aggregated information and is not traceable to any individual and cannot be used to identify an individual.`}
        </Typography>
      </Box>
      <br />
      <Typography variant="h6">
        {`5. USE OF YOUR PERSONAL DATA`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`In general, the Personal Data you submit to Us is used to respond to requests that you make, to aid Us in serving you better, improving our Product, providing the Website to you. We use your Personal Data in the following ways:`}
      </Typography>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`• `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Facilitate the creation of and secure your Account on Our network;`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`• `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Facilitate and process payments you make for Products that you purchase on the Website;`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`• `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Improve the quality of experience when you use our Website;`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`• `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Send you a Welcome email to verify ownership of the e-mail address provided when your Account was created;`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`• `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Send you administrative e-mail notifications, such as security, or support and maintenance advisories;`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 6 }}>
        <Typography variant="body1">
          {`• `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {`Send newsletters, surveys, offers, and other promotional materials related to the Website, Product and services made available by third parties and for other marketing purposes of Petcademy.`}
        </Typography>
      </Box>
      <br />
      <Typography variant="h6">
        {`6. DISCLOSURE OF YOUR PERSONAL DATA`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy.`}
      </Typography>
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`A. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`Third Party Service Providers. We may share your Personal Data with third party service providers to: provide you with the Website, including to conduct quality assurance testing; to facilitate creation of accounts; to provide technical support; and/or to provide other services to Petcademy.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`B. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`Corporate Restructuring. We may share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition or dissolution, transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of Our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires Our company, business, or assets, that company will possess the Personal Data a collected by Us and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`C. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`Other Disclosures. Regardless of any choices you make regarding your Personal Data (as described below), Petcademy may disclose Personal Data if it believes in good faith that such disclosure is necessary (a) in connection with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas or warrants served on Petcademy; (c) to protect or defend the rights or property of Petcademy, its customers and its users of the Website; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or Our Terms of Use.`}
        </Typography>
      </Box>
      <br />
      <Typography variant="h6">
        {`7. THIRD PARTY WEBSITES`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Our Website may contain links to third party websites. When you click on a link to any other website or location, you will leave Our Website and go to another website and another entity may collect Personal Data from you. We have no control over, do not review, and cannot be responsible for, these outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or to any collection of your Personal Data after you click on links to such outside websites. We encourage you to read the privacy policies of every website you visit. The links to third party websites or locations are for your convenience and do not signify Our endorsement of such third parties or their products, content or websites.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`8. CHOICES REGARDING YOUR INFORMATION`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`You have several choices regarding Use of information that you provide when accessing the Website and/or ordering or purchasing the Product:`}
      </Typography>
      <br />
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`A. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`Email Communications. We may periodically send you free newsletters and emails that directly promote the Use of Our Website and/or Products. When you receive newsletters or promotional communications from Us, you may indicate a preference to stop receiving further communications from Us and you will have the opportunity to “opt out” by following the unsubscribe instructions provided in the email you receive or by contacting Us directly (please see contact information above). Despite your indicated email preferences, We may send you service related communications, including notices of any updates to Our Terms of Use or Privacy Policy.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`B. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`Cookies. If you decide at any time that you no longer wish to accept cookies from the Website for any of the purposes described above, then you can instruct your browser, by changing its settings, to stop accepting cookies or to prompt you before accepting a cookie from the Websites you visit. Consult your browser’s technical information. If you do not accept cookies, however, you may not be able to Use all portions of the Website or all functionality of the Website. If you have any questions about how to disable or modify cookies, please let Us know at the contact information provided above.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`C. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`Changing, Requesting or Deleting Your Personal Data. All Users may review, update, correct or delete the Personal Information in their User account by contacting Us or by editing their profile via the Website. If you completely delete all of your Personal Information, then your User account may become deactivated, and you may no longer be able to utilize the Website. We will Use commercially reasonable efforts to honor your request. We may retain an archived copy of your records as required by law or for legitimate business purposes. The Directive gives you the right to access information held about you. If you wish to exercise this right in accordance with the Directive, please contact Us at bailey@petcademy.org (“Petcademy Privacy Contact”). You may send requests about Personal Data to Our Petcademy Privacy Contact. You can request to change contact choices, opt out of our sharing with others, and update your Personal Data.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`D. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`NOTICE TO CALIFORNIA RESIDENTS – YOUR CALIFORNIA PRIVACY RIGHTS (AS PROVIDED BY CALIFORNIA CIVIL CODE SECTION 1798.83 A CALIFORNIA RESIDENT WHO HAS PROVIDED PERSONAL DATA TO A BUSINESS WITH WHOM HE/SHE HAS ESTABLISHED A BUSINESS RELATIONSHIP FOR PERSONAL, FAMILY, OR HOUSEHOLD PURPOSES (A “CALIFORNIA CUSTOMER”) MAY REQUEST INFORMATION ABOUT WHETHER THE BUSINESS HAS DISCLOSED PERSONAL DATA TO ANY THIRD PARTIES FOR THE THIRD PARTIES’ DIRECT MARKETING PURPOSES. IN GENERAL, IF THE BUSINESS HAS MADE SUCH A DISCLOSURE OF PERSONAL DATA, UPON RECEIPT OF A REQUEST BY A CALIFORNIA CUSTOMER, THE BUSINESS IS REQUIRED TO PROVIDE A LIST OF ALL THIRD PARTIES TO WHOM PERSONAL DATA WAS DISCLOSED IN THE PRECEDING CALENDAR YEAR, AS WELL AS A LIST OF THE CATEGORIES OF PERSONAL DATA THAT WERE DISCLOSED. CALIFORNIA CUSTOMERS MAY REQUEST FURTHER INFORMATION ABOUT OUR COMPLIANCE WITH THIS LAW BY EMAILING BAILEY@PETCADEMY.ORG. PLEASE NOTE THAT WE ARE REQUIRED TO RESPOND TO ONLY ONE REQUEST PER CALIFORNIA CUSTOMER EACH YEAR AND WE ARE NOT REQUIRED TO RESPOND TO REQUESTS MADE BY MEANS OTHER THAN THROUGH THIS EMAIL ADDRESS.`}
        </Typography>
      </Box>
      <Box sx= {{ display: "flex", ml: 2.5 }}>
        <Typography variant="body1">
          {`E. `}
        </Typography>
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          {`Mobile Applications. In the event that We offer a mobile application and You choose to download and use such mobile application, You can stop all collection of information by the mobile application(s) by uninstalling the mobile application(s). You may Use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.`}
        </Typography>
      </Box>
      <br />
      <Typography variant="h6">
        {`9. SECURITY`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy shall endeavor and shall take every precaution to maintain adequate physical, procedural and technical security with respect to its offices and information storage facilities so as to prevent any loss, misuse, unauthorized access, disclosure or modification of the user’s personal information under our control.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`The company also uses Secure Socket Layer (SSL) for authentication and private communications in an effort to build users’ trust and confidence in the internet and website use by providing simple and secure access and communication of credit card and personal information.  `}
      </Typography>
      <br />
      <Typography variant="h6">
        {`10. CHANGES TO PRIVACY POLICY AGREEMENT`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy reserves the right to update and/or change the terms of our privacy policy, and as such we will post those changes to our website, so that our users and/or visitors are always aware of the type of information we collect, how it will be used, and under what circumstances, if any, we may disclose such information. If at any point in time Petcademy decides to make use of any personally identifiable information on file, in a manner vastly different from that which was stated when this information was initially collected, the user or users shall be promptly notified by email. Users at that time shall have the option as to whether or not to permit the use of their information in this separate manner.`}
      </Typography>
      <br />
    </Box>
  );
}

export default PrivacyPolicy;
