import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { AwoUser } from '../../types/Awo';
import { CREATE_AWO_USER, UPDATE_AWO_USER } from '../../queries';

const membersSchema = {
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Not a valid email').required('Email is required'),
};

interface MembersDialogProps {
  title: string,
  subtitle: string,
  buttonText: string,
  setDialog: React.Dispatch<React.SetStateAction<boolean>>,
  member?: AwoUser | undefined,
  awoId: string,
  onSuccess: (message: string) => void,
}

function MembersDialog({
  title,
  subtitle,
  buttonText,
  setDialog,
  member,
  awoId,
  onSuccess,
}: MembersDialogProps) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [upsertAwoUser, { loading, error }] = useMutation(member?.id ? UPDATE_AWO_USER : CREATE_AWO_USER, {
    onCompleted: (d) => {
      const { response } = d;
      if (!response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      } else {
        onSuccess(response.message);
      }
    },
  });

  const formSubmitHandler = async (values: Required<AwoUser>) => {
    const variables = {
      id: member?.id ? member.id : undefined,
      awoUser: {
        ...values,
        preSurrenderNotificationEmail: true,
        adoptionNotificationEmail: true,
        fosterNotificationEmail: true,
        taskNotificationsEmail: true,
        ownClientNotificationEmail: true,
        awoId: `${awoId}`,
      },
    };
    await upsertAwoUser({ variables });
  };

  const {
    register, control, formState, setValue, getValues, ...rest
  } = useForm({
    defaultValues: {
      firstName: member?.firstName || '',
      lastName: member?.lastName || '',
      email: member?.email || '',
    },
    resolver: yupResolver(yup.object(membersSchema)),
  });

  if (error) {
    setSnackbarOpen(true);
    setSnackbarMessage('Error in creating a new member. We\'re working on it.');
  }

  return (
    <>
      <Dialog
        open
        onClose={() => setDialog(false)}
        aria-labelledby="filter-dialog-title"
        aria-describedby="filter-dialog-description"
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '585px',
            maxWidth: '90vw',
          },
        }}
      >
        <FormContainer
          formContext={{
            register, control, formState, setValue, getValues, ...rest,
          }}
          onSuccess={formSubmitHandler as any}
        >
          <CardHeader title={title} subheader={subtitle} />
          <DialogContent>
            <TextFieldElement
              autoFocus
              name="firstName"
              sx={{ marginTop: '16px' }}
              fullWidth
              label="First Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextFieldElement
              name="lastName"
              sx={{ marginTop: '16px' }}
              fullWidth
              label="Last Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextFieldElement
              name="email"
              sx={{ marginTop: '16px' }}
              fullWidth
              label="Email"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'start' }}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading ? 'Loading' : buttonText}
            </Button>
            <Button onClick={() => setDialog(false)}>Cancel</Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={snackbarMessage}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}

export default MembersDialog;
