import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function DateFilter({ fullWidth, selectedDate, onChange }) {
  return (
    <FormControl fullWidth={fullWidth} variant="outlined" size="small" sx={{ bgcolor: 'white' }}>
      <Select
        id="adoption-date-label"
        value={selectedDate}
        label=""
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value={-1}>All time</MenuItem>
        <MenuItem value={7}>Last week</MenuItem>
        <MenuItem value={30}>Last month</MenuItem>
        <MenuItem value={180}>Last 6 months</MenuItem>
        <MenuItem value={365}>Last 12 months</MenuItem>
      </Select>
    </FormControl>
  );
}

export default DateFilter;
