import React from 'react';
import {
  Box, Button, Grid, MenuItem, MenuList, TextField, Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import withProfile from '../withProfile';
import { hashids } from '../../utilities';
import { GET_PET_PARENTS } from '../../queries';
import CasesTable from '../CasesTable';
import SmallScreenPlaceholder from '../SmallScreenPlaceholder';
import IconFilter from '../IconFilter';

function CasesContainer(
  { setDrawerOpen, setUserId }:
    { setDrawerOpen: (value: boolean) => void; setUserId: (id: number) => void },
) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState('sms_sync_date');
  const [order, setOrder] = React.useState('desc');
  const [searchText, setSearchText] = React.useState('');
  const [searchField, setSearchField] = React.useState<'caseNumber' | 'name' | 'email'>('email');

  const searchOptions: Record<'caseNumber' | 'name' | 'email', string> = {
    email: 'email',
    name: 'pet parent name',
    caseNumber: 'case number',
  };

  const location = useLocation();
  if (location.hash) {
    const id = location.hash.split('=')[1];
    const decodedId = hashids.decode(id);
    setDrawerOpen(true);
    setUserId(decodedId[0] as number);
  }

  const [getPetParents, {
    loading, error, data, networkStatus, refetch, fetchMore,
  }] = useLazyQuery<
    any,
    {
      offset: number;
      limit: number;
      order: string;
      orderBy: string;
      searchField?: string;
      searchText?: string;
      onlyGroupByPetParent?: boolean;
    }
  >(GET_PET_PARENTS, {
    variables: {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      order,
      orderBy,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleChangeOrder = (_: any, field: string) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchMore({
      variables: {
        offset: 0,
        limit: newRowsPerPage,
      },
    });
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
    fetchMore({
      variables: {
        offset: newPage * rowsPerPage,
      },
    });
  };

  // initial load
  React.useEffect(() => {
    getPetParents();
  }, [getPetParents]);

  React.useEffect(() => {
    refetch({
      order,
      orderBy,
      searchField,
      searchText,
      onlyGroupByPetParent: true,
    });
    // eslint-disable-next-line
  }, [order, orderBy, refetch]);

  if (error) throw error;

  return (
    <Box sx={{
      padding: 3,
      minHeight: '100vh',
    }}
    >
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Typography variant="overline">Cases</Typography>
      </Box>
      <Grid container>
        <Grid
          item
          xs={0}
          lg={12}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <Grid container>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  gap={2}
                >
                  <Box width="30%" maxWidth="300px">
                    <TextField
                      fullWidth
                      margin="normal"
                      name="text-search"
                      type="text"
                      id="text-search"
                      value={searchText}
                      variant="standard"
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          refetch({
                            searchText,
                            searchField,
                          });
                        }
                      }}
                      label={`Search for a Petcademy user by ${searchOptions[searchField]}`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box>
                    <IconFilter
                      id="search-icon-filter"
                      labelId="search-label"
                      icon={<SettingsOutlinedIcon />}
                    >
                      {
                        (open, handleClose, handleListKeyDown) => (
                          <MenuList
                            autoFocusItem={open}
                            id="search-composition-menu"
                            aria-labelledby="search-composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            {
                              Object.entries(searchOptions).map(([key, value]) => (
                                <MenuItem
                                  onClick={(e) => {
                                    setSearchField(key as 'caseNumber' | 'name' | 'email');
                                    handleClose(e);
                                  }}
                                  key={key}
                                  value={key}
                                >
                                  {`Search by ${value}`}
                                </MenuItem>
                              ))
                            }
                          </MenuList>
                        )

                      }
                    </IconFilter>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  {order === 'desc' && orderBy === 'sms_sync_date' ? (null) : (
                    <Box>
                      <Button
                        component="button"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setOrder('desc');
                          setOrderBy('sms_sync_date');
                        }}
                      >
                        Reset Sorting
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CasesTable
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
                order={order}
                orderBy={orderBy}
                handleChangeOrder={handleChangeOrder}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                count={data?.petParentCount?.count || 0}
                loading={loading}
                petParents={data?.petParents || []}
                networkStatus={networkStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <SmallScreenPlaceholder />
      </Grid>
    </Box>
  );
}

export default withProfile(CasesContainer);
