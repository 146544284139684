import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import { CheckboxElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const otherTasksSchema = {
  asksQuestion: yup.boolean(),
  emailTypo: yup.boolean(),
  phoneTypo: yup.boolean(),
  adoptionReview: yup.boolean(),
  fosterReview: yup.boolean(),
  unresponsive: yup.boolean(),
  completedSupport: yup.boolean(),
};

function OtherTasksPartial(
  { control, preSurrenderDashboard, coSheltering }:
    {
      control: Control<any, any>,
      preSurrenderDashboard?: boolean,
      coSheltering?: boolean,
    },
) {
  return (
    <>
      <Typography variant="body1">Other tasks</Typography>
      <Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Asks a question"
            name="asksQuestion"
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Has a typo in their email address"
            name="emailTypo"
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Has an invalid phone number"
            name="phoneTypo"
          />
        </Box>
        {
          !coSheltering && (
          <Box>
            {preSurrenderDashboard ? (
              <>
                <Box>
                  <CheckboxElement
                    control={control}
                    label="Has been unresponsive after 7 days"
                    name="unresponsive"
                  />
                </Box>
                <Box>
                  <CheckboxElement
                    control={control}
                    label="Has completed 30 days of support"
                    name="completedSupport"
                  />
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <CheckboxElement
                    control={control}
                    label="Reviews their adoption experience"
                    name="adoptionReview"
                  />
                </Box>
                <Box>
                  <CheckboxElement
                    control={control}
                    label="Reviews their foster experience"
                    name="fosterReview"
                  />
                </Box>
              </>
            )}
          </Box>
          )
        }
      </Box>
    </>
  );
}
export default OtherTasksPartial;
