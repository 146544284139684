import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const namesSchema = {
  name: yup.string().required('Name is required'),
  displayName: yup.string().required('Display name is required'),
};

function NamesPartial() {
  return (
    <>
      <TextFieldElement
        name="name"
        sx={{ marginTop: 3 }}
        fullWidth
        label="Name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText="Used in the AWO Dashboard and formal purposes. Example: ‘NYC Main Street Pet Society’"
      />
      <TextFieldElement
        name="displayName"
        sx={{ marginTop: 3 }}
        fullWidth
        label="Display name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText="The shorter name used for SMS messages to pet parents and in the Petcademy Hub. Example: ‘Main Street Pets’ "
      />
    </>
  );
}
export default NamesPartial;
