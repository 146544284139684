import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const donationLinkSchema = {
  donationLink: yup.string().url('Donation link must be a valid URL (including http:// or https://)'),
};

function DonationLinkPartial() {
  return (
    <TextFieldElement
      name="donationLink"
      sx={{ marginTop: 2 }}
      fullWidth
      label="Donation link (optional)"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
}

export default DonationLinkPartial;
