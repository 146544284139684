import {
  FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { RadioControl } from '../FormControls';

function ActiveStatusPartial(
  { control, errors }:
    {
      control: Control<any, any>,
      errors: FieldErrors<{ activeStatus: string | null }>
    },
) {
  return (
    <>
      <Typography variant="body1">Active status</Typography>
      <FormLabel
        sx={{ fontSize: '12px' }}
      >
        Organizations that are not currently using Petcademy are ‘Deactivated.’ Courses and SMS services are turned off.
      </FormLabel>
      <RadioControl
        fieldName="activeStatus"
        control={control}
        render={({ onChange, ...props }) => (
          <>
            {errors.activeStatus && (
            <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error>
              {errors.activeStatus?.message}
            </FormHelperText>
          )}
            <RadioGroup
              {...props}
              onChange={(event, value) => onChange({ target: { name: 'activeStatus', value } })}
              aria-labelledby="active-status-radio-group-label"
              row
              sx={{ justifyContent: 'space-between' }}
            >
              <FormControlLabel
                color={errors.activeStatus ? 'error' : 'primary'}
                sx={{ flexGrow: 1 }}
                value="Active"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                color={errors.activeStatus ? 'error' : 'primary'}
                sx={{ flexGrow: 1 }}
                value="Deactivated"
                control={<Radio />}
                label="Deactivated"
              />
            </RadioGroup>
          </>
        )}
      />
    </>
  );
}
export default ActiveStatusPartial;
