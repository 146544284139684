import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';
import { Awo } from '../../types/Awo';
import { phoneFormatter } from '../../utilities';

export const phoneNumberSchema = {
  orgPhoneNumber: yup.string()
    .when('country.value', {
      is: (val: string) => ['USA', 'Canada'].includes(val),
      then: (schema) => schema.matches(
        /^\(\d{3}\) \d{3}-\d+$/,
        {
          message: 'Phone number must be in the format (123) 456-7890',
          excludeEmptyString: true,
        },
      ),
    })
    .when('country.value', {
      is: (val: string) => ['Australia'].includes(val),
      then: (schema) => schema.matches(
        /^0\d{3} \d{3} \d+$/,
        {
          message: 'Phone number must be in the format 0123 456 789',
          excludeEmptyString: true,
        },
      ),
    }),
};

function PhoneNumberPartial({
  country,
  onPhoneNumberChange,
}: {
  country?: Awo['country'];
  onPhoneNumberChange: (phoneNumber: string) => void;
}) {
  return (
    <TextFieldElement
      name="orgPhoneNumber"
      sx={{ marginTop: '16px' }}
      fullWidth
      label="Phone number (optional)"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        onPhoneNumberChange(phoneFormatter(e.target.value, country));
      }}
    />
  );
}

export default PhoneNumberPartial;
