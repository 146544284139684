import React from 'react';
import {
  Box, Card, CardActionArea, CardContent, Typography, useTheme,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { PETCADEMY_SHADOW } from '../../utilities';

const PurpleTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${theme.palette.tooltip.fill}`,
    maxWidth: '200px',
  },
}));

function DashboardStatCard({
  title, text, subtextNumber, subtextLabel, href, icon, outline, tooltipTitle,
}) {
  const theme = useTheme();
  const subtextSuccessErrorColor = subtextNumber >= 0 ? 'secondary.main' : 'error.main';
  const cardContent = (
    <CardContent>
      <Typography variant="overline">{title}</Typography>
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="h6bold" component="h6">{text}</Typography>
          <Typography
            variant="caption"
            sx={{
              color: subtextNumber !== undefined ? subtextSuccessErrorColor : 'secondary.text',
            }}
          >
            {`${subtextNumber >= 0 ? '+' : ''}${subtextNumber !== undefined ? `${subtextNumber}%` : ''} ${subtextLabel}`}
          </Typography>
        </Box>
        <Box sx={{
          color: 'primary.light',
        }}
        >
          {icon}
        </Box>
      </Box>
    </CardContent>
  );

  return (
    <Card
      sx={{ boxShadow: outline ? `inset 0 0 0 2px ${theme.palette.primary.main}` : PETCADEMY_SHADOW, height: '100%' }}
    >
      <PurpleTooltip title={tooltipTitle} placement="top-start" enterDelay={250} followCursor>
        {href ? (
          <CardActionArea component={Link} to={href}>
            {cardContent}
          </CardActionArea>
        ) : cardContent}
      </PurpleTooltip>
    </Card>
  );
}

export default DashboardStatCard;
