import React from 'react';
import countriesOptions from '../../resources/countriesData';

export type UseFiltersProps = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type UseFiltersReturn = {
  applied: Record<'status' | 'smsName' | 'country' | 'state', string[]>;
  getAvailableStates: (countries: string[]) => string[];
  handleApplyFilters: () => void;
  handleCloseFilters: () => void;
  handleResetFilters: () => void;
  handleLocationChange: (type: 'country' | 'state', newSelection: string[]) => void;
  orgStatus: Set<string>;
  setOrgStatus: React.Dispatch<React.SetStateAction<Set<string>>>;
  orgSMSName: Set<string>;
  setOrgSMSName: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedCountries: string[];
  selectedStates: string[];
};

export default function useFilters({
  setPage,
  setOpen,
}: UseFiltersProps): UseFiltersReturn {
  const [orgStatus, setOrgStatus] = React.useState(new Set<string>());
  const [orgSMSName, setOrgSMSName] = React.useState(new Set<string>());
  const [applied, setApplied] = React.useState<Record<'status' | 'smsName' | 'country' | 'state', string[]>>({
    status: [],
    smsName: [],
    country: [],
    state: [],
  });
  const [selectedCountries, setSelectedCountries] = React.useState<string[]>([]);
  const [selectedStates, setSelectedStates] = React.useState<string[]>([]);

  const convertStatesToAbbreviations = (countries: string[], states: string[]) => countriesOptions
    .filter((country) => countries.includes(country.value))
    .flatMap((country) => country.states.filter((state) => states.includes(state.label)))
    .map((state) => state.abbreviation);

  const handleApplyFilters = () => {
    setOpen(false);
    setApplied({
      status: orgStatus.size > 0 ? Array.from(orgStatus) : [],
      smsName: orgSMSName.size > 0 ? Array.from(orgSMSName) : [],
      country: selectedCountries,
      state: convertStatesToAbbreviations(selectedCountries, selectedStates),
    });
    setOrgStatus(new Set<string>());
    setOrgSMSName(new Set<string>());
    setPage(0);
  };

  const handleCloseFilters = () => {
    setOpen(false);
  };

  const handleResetFilters = () => {
    setApplied({
      status: [],
      smsName: [],
      country: [],
      state: [],
    });
    setOrgStatus(new Set<string>());
    setOrgSMSName(new Set<string>());
    setSelectedCountries([]);
    setSelectedStates([]);
    setPage(0);
  };

  const getAvailableStates = (countries: string[]) => countriesOptions
    .filter((country) => countries.includes(country.value))
    .flatMap((country) => country.states.map((state) => state.label));

  const handleLocationChange = (
    type: 'country' | 'state',
    newSelection: string[],
  ) => {
    if (type === 'country') {
      setSelectedCountries(newSelection);
      setSelectedStates((prevStates) => prevStates.filter((state) => getAvailableStates(newSelection).includes(state)));
    } else {
      setSelectedStates(newSelection);
    }
  };

  return {
    applied,
    getAvailableStates,
    handleApplyFilters,
    handleCloseFilters,
    handleResetFilters,
    handleLocationChange,
    orgStatus,
    setOrgStatus,
    orgSMSName,
    setOrgSMSName,
    selectedCountries,
    selectedStates,
  };
}
