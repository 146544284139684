import React, { ReactNode } from 'react';
import {
  Box, SxProps, Typography,
  Card, CardContent, Button,
  CardActions, CardMedia, Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';
import { useQuery } from '@apollo/client';
import Slider from 'react-slick';
import '../slick-custom.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import mixpanel from 'mixpanel-browser';
import OutlinedIconBadge from './OutlinedIconBadge';
import { colors } from '../../theme';
import { GET_HUB_PET_PARENT } from '../../queries';
import CustomContainer from './CustomContainer';
import AskBadge from './AskBadge';

function CtaCard({
  title,
  subtitle,
  background,
  color,
  image,
  buttonColor,
  buttonText,
  onClick,
  sx,
}: {
  title: string,
  subtitle: string,
  background: string,
  color?: string,
  image: string,
  buttonColor?: string,
  buttonText: string,
  onClick?: () => void,
  sx?: SxProps,
}) {
  return (
    <Card sx={{
      width: 340,
      height: 256,
      flexShrink: 0,
      position: 'relative',
      background,
      color,
      mr: 2,
      mb: 2,
      ...sx,
    }}
    >
      <CardContent
        sx={{ px: 4, pt: 5, pb: 14 }}
      >
        <Typography variant="sectionTitle">{title}</Typography>
        <Typography sx={{ mt: 1 }} variant="body2">{subtitle}</Typography>
      </CardContent>
      <Box sx={{
        position: 'absolute', left: 0, bottom: 0, height: 90,
      }}
      >
        <img style={{ height: '100%' }} src={image} alt="" />
      </Box>
      <CardActions sx={{
        justifyContent: 'end', padding: 4, pt: 0, position: 'absolute', right: 0, bottom: 0,
      }}
      >
        <Button onClick={onClick} sx={{ color: buttonColor }}>{buttonText}</Button>
      </CardActions>
    </Card>
  );
}

const dogLessonCards = [
  {
    title: 'Getting Your New Dog Settled',
    subtitle: '3-minute lesson: Essential tips to help your new dog feel at home.',
    image: 'https://import.cdn.thinkific.com/256766%2Fcustom_site_themes%2Fid%2F6jP1vlg5TZe4qQNB8HBF_Screenshot%202024-11-05%20at%202.45.39%E2%80%AFPM.png',
    linkUrl: 'https://learn.petcademy.org/pages/helping-your-new-dog-settle',
  }, {
    title: 'House Training',
    subtitle: '4-minute lesson: Actionable steps for successful house training.',
    image: 'https://import.cdn.thinkific.com/256766%2Fcustom_site_themes%2Fid%2FNvp2k5pMRlC0Cjw4XRhD_House%20training.png',
    linkUrl: 'https://learn.petcademy.org/pages/house-training',
  }, {
    title: 'Introducing Dogs',
    subtitle: '4-minute lesson: Facilitate a smooth introduction between your dogs.',
    image: 'https://import.cdn.thinkific.com/256766%2Fcustom_site_themes%2Fid%2F1nF8uqUURVW1xHk5Sg8s_Introductions%20to%20Dogs.png',
    linkUrl: 'https://learn.petcademy.org/pages/introducing-dogs',
  },
];

const catLessonCards = [
  {
    title: 'Getting Your New Cat Settled',
    subtitle: '3-minute lesson: Essential tips to help your new cat feel at home.',
    image: 'https://import.cdn.thinkific.com/256766%2Fcustom_site_themes%2Fid%2FrIbrdmiaTmGy2JQOnMaa_cat%20settled.png',
    linkUrl: 'https://learn.petcademy.org/pages/helping-your-new-cat-settle',
  }, {
    title: 'Introducing Cats',
    subtitle: '4-minute lesson: Facilitate a smooth introduction between your cats.',
    image: 'https://import.cdn.thinkific.com/256766%2Fcustom_site_themes%2Fid%2F7hEivYKCRHKzLNAS3tzE_Introducing%20Cats.png',
    linkUrl: 'https://learn.petcademy.org/pages/introducing-cats',
  }, {
    title: 'Litter Box Training',
    subtitle: '4-minute lesson: Actionable steps to set your cat up for litter box success.',
    image: 'https://import.cdn.thinkific.com/256766%2Fcustom_site_themes%2Fid%2F96zMXdHjTtqAW9yJ2dKl_Litter%20Box%20Issues.png',
    linkUrl: 'https://learn.petcademy.org/pages/litter-box-issues',
  },
];

function LessonCard({
  title,
  subtitle,
  image,
  linkUrl,
}: {
  title: string,
  subtitle: string,
  image: string,
  linkUrl: string,
}) {
  return (
    <Card
      onClick={() => {
        mixpanel.track('lesson_page_view', {
          link: linkUrl,
        }, () => { window.open(linkUrl, '_blank'); });
      }}
      sx={{
        width: 329, mr: 2, mb: 2, display: 'block', cursor: 'pointer',
      }}
      href={linkUrl}
      target="_blank"
    >
      <CardMedia
        sx={{ height: 180 }}
        image={image}
        title={title}
      />
      <CardContent>
        <Typography variant="h6" component="div">{title}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
      </CardContent>
    </Card>
  );
}

const dogProductCards = [{
  title: 'Freedom No Pull Dog Harness ($42)',
  subtitle: 'Created by 2 Hounds Design',
  image: '/hub/product_dog_1.jpg',
  linkUrl: 'https://prf.hn/l/41QnQNe',
}, {
  title: 'Fun Feeder Interactive Dog Bowl ($15)',
  subtitle: 'Created by Outward Hound',
  image: '/hub/product_dog_2.jpg',
  linkUrl: 'https://prf.hn/l/reD8Vo7',
}, {
  title: 'Toppl Treat Dispensing Dog Toy ($21)',
  subtitle: 'Created by West Paw Design',
  image: '/hub/product_dog_3.jpg',
  linkUrl: 'https://prf.hn/l/75EbDZ7',
}];

const catProductCards = [{
  title: 'Kitty’s Cat Grass Garden ($12)',
  subtitle: 'Created by Smart Cat',
  image: '/hub/product_cat_2.jpg',
  linkUrl: 'https://prf.hn/l/rwGZn29',
}, {
  title: 'Magic Meowshroom Catnip Toy ($7)',
  subtitle: 'Created by Mad Cat',
  image: '/hub/product_cat_1.jpg',
  linkUrl: 'https://prf.hn/l/W4B3O0q/',
}, {
  title: 'Bird with Feathers Cat Wand ($5)',
  subtitle: 'Created by Frisco',
  image: '/hub/product_cat_3.jpg',
  linkUrl: 'https://prf.hn/l/6neEJxX',
}];

function ProductCard({
  title,
  subtitle,
  image,
  linkUrl,
}: {
  title: string,
  subtitle: string,
  image: string,
  linkUrl: string,
}) {
  return (
    <Card
      onClick={() => {
        mixpanel.track('recommended_product_click', {
          link: linkUrl,
        }, () => { window.open(linkUrl, '_blank'); });
      }}
      sx={{
        width: 329, mr: 2, mb: 2, cursor: 'pointer',
      }}
    >
      <CardMedia
        sx={{ height: 180 }}
        image={image}
        title={title}
      />
      <CardContent>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
      </CardContent>
    </Card>
  );
}

function CustomCarouselContainer({ children }: { children: ReactNode }) {
  const settings = {
    className: 'slider variable-width',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    nextArrow:
      // eslint-disable-next-line react/jsx-indent
      <div>
        <KeyboardArrowRightIcon />
      </div>,
    prevArrow:
      // eslint-disable-next-line react/jsx-indent
      <div>
        <KeyboardArrowLeftIcon />
      </div>,
  };
  return (
    <Box sx={{
      mx: {
        xs: -2.5,
        sm: -1,
      },
    }}
    >
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
}

function Home() {
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_HUB_PET_PARENT);
  if (loading) return null;

  const hasDogs = (data?.petParent?.animals || []).find((a) => a.animalType === 'Dog');
  const lessonCards = hasDogs ? dogLessonCards : catLessonCards;
  const productCards = hasDogs ? dogProductCards : catProductCards;

  const ctaCards = [
    {
      title: 'Your pet’s records are ready to view',
      subtitle: 'Access your pet’s medical records, adoption packet and microchip number',
      background: '#1E1E1E',
      color: 'white',
      buttonColor: 'white',
      buttonText: 'view records',
      image: '/hub/hub_records_cta.png',
      onClick: () => {
        mixpanel.track('view_records_click', undefined, () => navigate('/hub/my-pets'));
      },
    },
    {
      title: 'Support shelter animals at the Petcademy Shop',
      subtitle: `50% of profits donated to ${data.petParent.awo.name}`,
      background: 'white',
      buttonText: 'go to shop',
      image: '/hub/hub_shop_cta.png',
      onClick: () => {
        mixpanel.track('go_to_store_click', {
          link: 'https://shop.petcademy.org/',
        }, () => {
          window.open('https://shop.petcademy.org/', '_blank');
        });
      },
    },
  ];
  return (
    <Box>
      <CustomContainer sx={{ pt: 4, gap: 2 }}>
        <Typography variant="sectionTitle">{`Hi, ${data?.petParent?.firstName}`}</Typography>
        <CustomCarouselContainer>
          {ctaCards.map((card, index) => (
            <CtaCard
              key={index}
              title={card.title}
              subtitle={card.subtitle}
              background={card.background}
              color={card.color}
              buttonColor={card.buttonColor}
              buttonText={card.buttonText}
              image={card.image}
              onClick={card.onClick}
            />
          ))}
        </CustomCarouselContainer>
      </CustomContainer>
      <CustomContainer sx={{ pt: 4, gap: 2 }}>
        <Typography variant="sectionTitle">My learning</Typography>
        <CustomCarouselContainer>
          {lessonCards.map((lesson, idx) => (<LessonCard key={idx} {...lesson} />))}
          <CtaCard
            title="Access 100+ Training Videos & Lessons!"
            subtitle="Enjoy 30 days of access to Petcademy’s learning hub with your adoption. Log in or create an account to get started."
            buttonText="Go to learning hub"
            sx={{ height: 284 }}
            onClick={() => {
              mixpanel.track('go_to_learning_hub_click', undefined, () => {
                window.open(`https://learn.petcademy.org/users/express_signin?email=${data?.petParent?.email}`, '_blank');
              });
            }}
          />
        </CustomCarouselContainer>
      </CustomContainer>
      <CustomContainer sx={{ pt: 4, gap: 2 }}>
        <Typography variant="sectionTitle">Recommended products</Typography>
        <CustomCarouselContainer>
          {
            productCards.map((product, idx) => <ProductCard key={idx} {...product} />)
          }
        </CustomCarouselContainer>
      </CustomContainer>
      <CustomContainer sx={{ pt: 4, gap: 2 }}>
        <Typography variant="sectionTitle">Donate</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {
            !isNil(data.petParent.awo.donationLink) && !isEmpty(data.petParent.awo.donationLink) ? (
              <Link href={data.petParent.awo.donationLink} target="_blank" sx={{ textDecoration: 'none' }}>
                <OutlinedIconBadge
                  icon={<VolunteerActivismOutlinedIcon />}
                  title={`Thank ${data?.petParent?.awo?.name} with a $5 donation`}
                  subheader="A little gift goes a long way"
                  background={colors.grey[50]}
                  iconOpacity={100}
                  iconBackground={colors.primary[50]}
                  iconColor={colors.primary.main}
                  onClick={() => {
                    mixpanel.track('donate_to_awo_click');
                  }}
                />
              </Link>
            ) : null
          }
          <Link href="https://shop.petcademy.org/" target="_blank" sx={{ textDecoration: 'none' }}>
            <OutlinedIconBadge
              icon={<ShoppingCartOutlinedIcon />}
              title="Shop the Petcademy Store"
              subheader={`50% of profits donated to ${data?.petParent?.awo?.name}`}
              background={colors.grey[50]}
              iconOpacity={100}
              iconBackground={colors.primary[50]}
              iconColor={colors.primary.main}
              onClick={() => {
                mixpanel.track('shop_store_click', {
                  link: 'https://shop.petcademy.org/',
                });
              }}
            />
          </Link>
        </Box>
      </CustomContainer>
      <CustomContainer sx={{ pt: 4, gap: 2 }}>
        <Typography variant="sectionTitle">Ask a trainer</Typography>
        <AskBadge
          phoneNumber={data.petParent.awo.twilioPhoneNumber}
          inactiveMembership={data.petParent.startedDaysAgo > 30}
        />
      </CustomContainer>
    </Box>
  );
}

export default Home;
