import {
  Box, Card, CardActionArea, CardActions, CardContent, Typography, useTheme,
} from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import { PETCADEMY_SHADOW } from '../utilities';

function TaskCard({
  completed, petParent, actionText, subtext, nextStep, actions, onClick, outline,
}) {
  const theme = useTheme();
  const cardActions = () => (
    <CardActions disableSpacing sx={{ p: 0, marginX: '-0.5rem' }}>
      {actions}
    </CardActions>
  );
  const cardContent = (children) => (
    <CardContent>
      <Box sx={{ mb: nextStep ? 2.5 : 0 }}>
        <Box>
          <Typography variant="subtitle1" component="span" sx={{ color: 'primary.main' }}>{`${capitalize(petParent.firstName)} ${capitalize(petParent.lastName)} `}</Typography>
          <Typography variant="subtitle1" component="span" dangerouslySetInnerHTML={{ __html: actionText }} />
        </Box>
        <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{subtext}</Typography>
      </Box>
      {nextStep ? (
        <Box sx={{ mb: 0 }}>
          <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Next Step</Typography>
          <Typography variant="body2">{nextStep}</Typography>
        </Box>
      ) : null}
      {actions && children && children()}
    </CardContent>
  );

  return (
    <Card
      variant={outline ? 'outlined' : null}
      sx={{
        border: outline ? `2px solid ${theme.palette.primary.main}` : null,
        backgroundColor: completed ? 'rgba(33, 33, 20, 0.08)' : 'white',
        boxShadow: PETCADEMY_SHADOW,
      }}
    >
      {onClick ? (
        <>
          <CardActionArea onClick={onClick} disableRipple>
            {cardContent()}
          </CardActionArea>
          {
            actions && (
              <Box
                sx={{
                  px: 2,
                  py: 1,
                }}
              >
                {cardActions()}
              </Box>
            )
          }
        </>
      ) : cardContent(cardActions)}
    </Card>
  );
}

export default TaskCard;
