import React, { JSXElementConstructor, ReactElement } from 'react';
import {
    ClickAwayListener, Grow,
    IconButton, Paper, Popper,
} from '@mui/material';

function IconFilter({
 id, labelId, icon, children, disabled, color, disablePortal = false, placement = 'bottom',
}:{
  id: string;
  labelId: string;
  icon: React.ReactNode;
  disabled?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  children: (
    open: boolean,
    handleClose: (event: Event | React.SyntheticEvent) => void,
    handleListKeyDown: (event: React.KeyboardEvent) => void,
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  disablePortal?: boolean;
  placement?: 'bottom' | 'bottom-end' | 'bottom-start' | 'left' | 'left-end' | 'left-start' | 'right' | 'right-end' | 'right-start' | 'top' | 'top-end' | 'top-start';
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (anchorRef.current && prevOpen.current && !open) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        id={id}
        size="small"
        aria-controls={open ? labelId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={disabled}
        color={color}
      >
        {icon}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={placement}
        transition
        disablePortal={disablePortal}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {children(open, handleClose, handleListKeyDown)}
              </ClickAwayListener>
            </Paper>
          </Grow>
            )}
      </Popper>
    </div>
  );
}

export default IconFilter;
