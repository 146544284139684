import React from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import {
  Grid, Paper, Skeleton, useMediaQuery, useTheme,
} from '@mui/material';
import { GET_BEHAVIORAL_ISSUES } from '../../queries';
import { beforeCapture } from '../../utilities';
import BehavioralIssuesCard from './BehavioralIssuesCard';
import ErrorMessage from '../ErrorMessage';
import { useAwoContext } from '../AwoContextProvider';

function SkeletonFeedbackCard() {
  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangle" height="600px" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" />
        </Grid>
      </Grid>
    </Paper>
  );
}

function BehavioralIssuesList({ halfscreen, feedbackDaysAgo }) {
  const { awo } = useAwoContext();
  const {
    loading, error, data, refetch,
  } = useQuery(GET_BEHAVIORAL_ISSUES, {
    variables: { feedbackDaysAgo },
  });

  const theme = useTheme();

  const is1800 = useMediaQuery(theme.breakpoints.up('x1800'));
  const is1600 = useMediaQuery(theme.breakpoints.between('x1600', 'x1800'));
  const is1400 = useMediaQuery(theme.breakpoints.between('x1400', 'x1600'));
  const is1200 = useMediaQuery(theme.breakpoints.between('x1200', 'x1400'));
  const is1000 = useMediaQuery(theme.breakpoints.between('x1000', 'x1200'));
  const is800 = useMediaQuery(theme.breakpoints.between('x800', 'x1000'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'x800'));
  const is600 = useMediaQuery(theme.breakpoints.between('x600', 'md'));
  const is400 = useMediaQuery(theme.breakpoints.between('x400', 'x600'));
  const is200 = useMediaQuery(theme.breakpoints.between('x200', 'x400'));

  let dogLabelOffset = 0;
  let dogAspectRatio = 0.75;
  if (is1800) {
    dogLabelOffset = -30;
    dogAspectRatio = 0.90;
  } else if (is1600) {
    dogLabelOffset = -27;
    dogAspectRatio = 0.8;
  } else if (is1400) {
    dogLabelOffset = -25;
    dogAspectRatio = 0.75;
  } else if (is1200) {
    dogLabelOffset = -25;
    dogAspectRatio = 0.65;
  } else if (is1000) {
    dogLabelOffset = -25;
    dogAspectRatio = 0.55;
  } else if (is800 || isMd) {
    dogLabelOffset = -23;
    dogAspectRatio = 0.45;
  } else if (is600) {
    dogLabelOffset = -27;
    dogAspectRatio = 0.8;
  } else if (is400) {
    dogLabelOffset = -23;
    dogAspectRatio = 0.65;
  } else if (is200) {
    dogLabelOffset = -21;
    dogAspectRatio = 0.50;
  }

  React.useEffect(() => {
    refetch({
      feedbackDaysAgo,
    });
  }, [feedbackDaysAgo, refetch]);

  if (loading) {
    return (
      <>
        <Grid item xs={12} md={halfscreen ? 6 : 12} sx={{ paddingTop: '0px !important' }}>
          <SkeletonFeedbackCard />
        </Grid>
        <Grid item xs={12} md={halfscreen ? 6 : 12} sx={{ paddingTop: !awo.dogAdopt || halfscreen ? '0px !important' : undefined }}>
          <SkeletonFeedbackCard />
        </Grid>
      </>
    );
  }
  if (error) throw error;

  return (
    <>
      {awo.dogAdopt && data.awoAdoptedDogBehavioralIssues.numResponses > 0 ? (
        <Grid item xs={12} md={halfscreen ? 6 : 12} sx={{ paddingTop: '0px !important' }}>
          <BehavioralIssuesCard
            awoName={awo.name}
            title="Behavioral concerns for adopted dogs 🐶"
            subtext={`${awo.name} versus the average for all Petcademy partners`}
            bottomText="Of the dogs adopted, these are the top behavioral issues along with the baseline for other animal welfare organizations using Petcademy."
            shelterStats={data?.awoAdoptedDogBehavioralIssues}
            otherStats={data?.otherAdoptedDogBehavioralIssues}
            labelOffset={dogLabelOffset}
            aspectRatio={dogAspectRatio}
            maintainAspectRatio={false}
          />
        </Grid>
      ) : null}
      {awo.dogFoster && data.awoFosterDogBehavioralIssues.numResponses > 0 ? (
        <Grid item xs={12} md={halfscreen ? 6 : 12} sx={{ paddingTop: !awo.dogAdopt || halfscreen ? '0px !important' : undefined }}>
          <BehavioralIssuesCard
            awoName={awo.name}
            title="Behavioral concerns for foster dogs 🐶"
            subtext={`${awo.name} versus the average for all Petcademy partners`}
            bottomText="Of the dogs fostered, these are the top behavioral issues along with the baseline for other animal welfare organizations using Petcademy."
            shelterStats={data?.awoFosterDogBehavioralIssues}
            otherStats={data?.otherFosterDogBehavioralIssues}
            labelOffset={dogLabelOffset}
            aspectRatio={dogAspectRatio}
            maintainAspectRatio={false}
          />
        </Grid>
      ) : null}
      {awo.catAdopt && data.awoAdoptedCatBehavioralIssues.numResponses > 0 ? (
        <Grid item xs={12} md={halfscreen ? 6 : 12} sx={{ paddingTop: (!awo.dogAdopt && !awo.dogFoster) || (halfscreen && !(awo.dogAdopt && awo.dogFoster)) ? '0px !important' : undefined }}>
          <BehavioralIssuesCard
            awoName={awo.name}
            title="Behavioral concerns for adopted cats 🐱"
            subtext={`${awo.name} versus the average for all Petcademy partners`}
            bottomText="Of the cats adopted, these are the top behavioral issues along with the baseline for other animal welfare organizations using Petcademy."
            shelterStats={data?.awoAdoptedCatBehavioralIssues}
            otherStats={data?.otherAdoptedCatBehavioralIssues}
            labelOffset={dogLabelOffset}
            aspectRatio={dogAspectRatio}
            maintainAspectRatio
          />
        </Grid>
      ) : null}
      {awo.catFoster && data.awoFosterCatBehavioralIssues.numResponses > 0 ? (
        <Grid item xs={12} md={halfscreen ? 6 : 12} sx={{ paddingTop: (!awo.dogAdopt && !awo.dogFoster && !awo.catAdopt) || (halfscreen && (awo.dogAdopt ? (!awo.dogFoster && !awo.catAdopt) : !(awo.dogFoster && awo.catAdopt))) ? '0px !important' : undefined }}>
          <BehavioralIssuesCard
            awoName={awo.name}
            title="Behavioral concerns for foster cats 🐱"
            subtext={`${awo.name} versus the average for all Petcademy partners`}
            bottomText="Of the cats fostered, these are the top behavioral issues along with the baseline for other animal welfare organizations using Petcademy."
            shelterStats={data?.awoFosterCatBehavioralIssues}
            otherStats={data?.otherFosterCatBehavioralIssues}
            labelOffset={dogLabelOffset}
            aspectRatio={dogAspectRatio}
            maintainAspectRatio
          />
        </Grid>
      ) : null}
    </>
  );
}

export default Sentry.withErrorBoundary(BehavioralIssuesList, {
  fallback: (
    <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
      <ErrorMessage />
    </Grid>
  ),
  beforeCapture,
});
