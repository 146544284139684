import React, { useState } from 'react';
import {
  Box, Button, Card, CardActions, CardHeader, Dialog, DialogTitle, Divider, Typography,
} from '@mui/material';
import {
  FileOpenOutlined, LocalOfferOutlined, QuestionAnswerOutlined, SchoolOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import { colors } from '../../theme';
import OutlinedIconBadge from './OutlinedIconBadge';
import { GET_HUB_PET_PARENT } from '../../queries';

const infoCards = [
  {
    icon: <QuestionAnswerOutlined />,
    title: 'Ask a trainer',
    subheader: 'Text our team anytime for training tips, answers and solutions',
  }, {
    icon: <FileOpenOutlined />,
    title: 'Pet records & documents',
    subheader: 'Access your pet’s health history and adoption packet',
  }, {
    icon: <SchoolOutlined />,
    title: 'Learning hub access',
    subheader: 'Enjoy 30 days of unlimited access to 100+ training videos and lessons',
  }, {
    icon: <LocalOfferOutlined />,
    title: 'Pet parent must-haves',
    subheader: 'Discover our favorite products and services, tailored for you',
  },
];

function OnboardingDialog() {
  const [open] = useState(true);
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_HUB_PET_PARENT);
  if (loading) return null;

  const hasDogs = data.petParent.animals.find((a) => a.animalType === 'Dog');

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: 'calc(100vw - 32px)',
          margin: '16px',
        },
      }}
    >
      <DialogTitle sx={{ px: 3 }}>
        <Typography color={colors.primary.dark} sx={{ lineHeight: '26.5px' }} variant="h6bold">{`Hi ${data?.petParent?.firstName}, Welcome to Petcademy`}</Typography>
      </DialogTitle>
      <Divider />
      <Box sx={{ px: 3 }}>
        <Typography sx={{ py: 2 }} variant="body1">
          {`We heard you adopted a ${hasDogs ? 'dog' : 'cat'} from ${data.petParent.awo.name} 🎉🥳 The team at
          Petcademy is here to support you with 30 days of expert guidance and resources:`}
        </Typography>
        <Divider />
        {infoCards.map((card) => (
          <>
            <OutlinedIconBadge icon={card.icon} title={card.title} subheader={card.subheader} />
            <Divider />
          </>
        ))}
        <Typography variant="body2" sx={{ py: 2 }}>
          {`Welcome (or ${hasDogs ? 'woof' : 'meow'}) from your new friends at Petcademy!
          We're thrilled to be part of your pet parent journey.`}
        </Typography>
      </Box>
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          variant="contained"
          onClick={() => {
            mixpanel.track('start_sniffing_click', undefined, () => navigate('/hub/home'));
          }}
        >
          start sniffing around
        </Button>
      </CardActions>
    </Dialog>
  );
}

export default OnboardingDialog;
