import React from 'react';
import {
  Box, Grid, Paper, Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { round } from 'lodash';
import { Bar } from 'react-chartjs-2';
import { PETCADEMY_SHADOW } from '../../utilities';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function BehavioralIssuesCard({
  awoName, title, subtext, bottomText, shelterStats, otherStats, labelOffset, aspectRatio, maintainAspectRatio,
}) {
  const labels = Object.keys(shelterStats.issues);

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: {
        left: -12,
      },
    },
    scales: {
      xAxis: {
        min: 0,
        max: 100,
        display: false,
      },
      yAxis: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          mirror: true,
          labelOffset,
          color: 'rgb(0, 0, 0)',
          font: {
            size: 14,
            weight: 500,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio,
    aspectRatio,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${round(context.parsed.x)}%`,
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: awoName,
        data: Object.values(shelterStats.issues).map((v) => (v / shelterStats.numResponses) * 100),
        borderColor: 'rgb(70, 44, 213)',
        backgroundColor: 'rgb(70, 44, 213)',
        categoryPercentage: 0.5,
        barPercentage: 0.8,
        minBarLength: 5,
      },
      {
        label: 'Petcademy Baseline',
        data: Object.values(otherStats.issues).map((v) => (v / otherStats.numResponses) * 100),
        borderColor: 'rgb(166, 166, 166)',
        backgroundColor: 'rgb(166, 166, 166)',
        categoryPercentage: 0.5,
        barPercentage: 0.8,
        minBarLength: 5,
      },
    ],
  };
  return (
    <Paper sx={{ p: 2 }} elevation={PETCADEMY_SHADOW}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6bold">{title}</Typography>
          <Typography variant="body2">{subtext}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ pt: '10px !important' }}>
          <Box height={maintainAspectRatio ? undefined : '75vh'}>
            <Bar options={options} data={chartData} />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ pt: '0px !important' }}>
          <Typography variant="body2" color="rgba(0, 0, 0, 0.60)">{bottomText}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BehavioralIssuesCard;
