import React from 'react';
import { Button, Link } from '@mui/material';
import { getUrlParameterByName } from '../../utilities';

function MediaThumbnail({
  setDialogImg, setDialogDate, setImgDialogOpen, animalName, animalMedia, index,
}: any) {
  if (animalMedia.fileType.startsWith('video') && animalMedia.url.includes('youtube')) {
    return (
      <Button
        sx={{
          p: 0, width: '80px', height: '80px', background: 'url(/photo_placeholder.png)', backgroundColor: '#E6E6E6', borderRadius: '0px',
        }}
        onClick={() => {
          setDialogImg(`https://www.youtube.com/embed/${getUrlParameterByName('v', animalMedia.url)}`);
          setDialogDate(animalMedia.dateCreated);
          setImgDialogOpen(true);
        }}
      >
        <img
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          key={animalMedia.url}
          src={`https://img.youtube.com/vi/${getUrlParameterByName('v', animalMedia.url)}/default.jpg`}
          alt={`${animalName}-${index}`}
          loading="lazy"
          width="80px"
          height="80px"
        />
      </Button>
    );
  }
  if (animalMedia.fileType.startsWith('video')) {
    return (
      <Link
        href={animalMedia.url}
        underline="hover"
        rel="noopener"
        target="_blank"
        variant="body2"
      >
        {getUrlParameterByName('name', animalMedia.url) || `Video ${index}`}
      </Link>
    );
  }
  return (
    <Button
      sx={{
        p: 0, width: '80px', height: '80px', background: 'url(/photo_placeholder.png)', backgroundColor: '#E6E6E6', borderRadius: '0px',
      }}
      onClick={() => {
        setDialogImg(animalMedia.url);
        setDialogDate(animalMedia.dateCreated);
        setImgDialogOpen(true);
      }}
    >
      <img
        style={{ objectFit: 'cover', objectPosition: 'center' }}
        key={animalMedia.url}
        src={animalMedia.url}
        alt={`${animalName}-${index}`}
        loading="lazy"
        width="80px"
        height="80px"
      />
    </Button>
  );
}

export default MediaThumbnail;
