import { Portal, Snackbar } from '@mui/material';
import React from 'react';

async function copyTextToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
}

export default function CopyToClipboard(
  { message, children }:
    { message?: string, children: (copyText: (text: string) => void) => React.ReactElement },
) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const copyText = async (text: string) => {
    await copyTextToClipboard(text);
    setSnackbarOpen(true);
  };
  return (
    <>
      {children(copyText)}
      <Portal container={() => document.getElementById('root')}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
          message={message || 'Copied to clipboard successfully'}
          onClose={() => {
          setSnackbarOpen(false);
        }}
        />
      </Portal>
    </>
  );
}
