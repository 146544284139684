import React from 'react';
import {
 Dialog, DialogActions, CardContent, Box, FormGroup, Typography, FormControl, Autocomplete, TextField, Button,
 Grid,
 FormControlLabel,
 Checkbox,
} from '@mui/material';
import countriesOptions from '../../resources/countriesData';
import { chunk } from '../../utilities';
import { UseFiltersReturn } from './useFilters';

interface FilterDialogProps {
  open: boolean;
  locationOptions: {
    countries: typeof countriesOptions;
    states: string[];
  };
  filters: UseFiltersReturn;
}

function FilterDialog({
  open,
  filters,
  locationOptions,
}: FilterDialogProps) {
  const {
    handleCloseFilters,
    handleApplyFilters,
    handleResetFilters,
    selectedCountries,
    selectedStates,
    handleLocationChange,
    applied,
    orgStatus,
    setOrgStatus,
    orgSMSName,
    setOrgSMSName,
  } = filters;

  const options = {
    status: {
      label: 'Status',
      getState: orgStatus,
      setState: setOrgStatus,
      variants: [{
        label: 'Active',
        value: 'active',
      }, {
        label: 'Deactivated',
        value: 'deactivated',
      }],
    },
    smsName: {
      label: 'Software',
      getState: orgSMSName,
      setState: setOrgSMSName,
      variants: [{
        label: 'ASM',
        value: 'Animal Shelter Manager',
      }, {
        label: 'Animals First',
        value: 'Animals First',
      }, {
        label: 'Chameleon',
        value: 'Chameleon',
      }, {
        label: 'Pawlytics',
        value: 'Pawlytics',
      }, {
        label: 'Petpoint',
        value: 'Petpoint',
      }, {
        label: 'Petstablished',
        value: 'Petstablished',
      }, {
        label: 'Shelter Buddy',
        value: 'Shelter Buddy',
      }, {
        label: 'Shelterluv',
        value: 'Shelterluv',
      }, {
        label: 'Other',
        value: 'Other',
      }],
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseFilters}
      aria-labelledby="filter-dialog-title"
      aria-describedby="filter-dialog-description"
      maxWidth={false}
      PaperProps={{
      sx: {
        width: '585px',
        maxWidth: '90vw',
      },
    }}
    >
      <Typography variant="h6" sx={{ padding: 2 }}>Filters</Typography>
      <CardContent sx={{ paddingTop: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormGroup sx={{ flexGrow: 1 }}>
            <Typography variant="overline" sx={{ color: 'secondary.text', mb: 1 }}>Location</Typography>
            <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            flexGrow: 1,
          }}
            >
              <FormControl>
                <Autocomplete
                  multiple
                  value={countriesOptions.filter((country) => selectedCountries.includes(country.value))}
                  onChange={(_, newValue) => {
                  handleLocationChange('country', newValue.map((country) => country.value));
                }}
                  options={locationOptions.countries}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                      sx: {
                        lineHeight: '1.5rem',
                      },
                    }}
                      label="Country"
                    />
                )}
                  ChipProps={{
                  size: 'small',
                }}
                />
              </FormControl>
              <FormControl>
                <Autocomplete
                  multiple
                  value={selectedStates}
                  onChange={(_, newValues) => {
                    handleLocationChange('state', newValues);
                  }}
                  options={locationOptions.states}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                      sx: {
                        lineHeight: '1.5rem',
                      },
                    }}
                      label="State"
                    />
                )}
                  ChipProps={{
                  size: 'small',
                }}
                  disabled={selectedCountries.length === 0}
                />
              </FormControl>
            </Box>
          </FormGroup>
          {
              Object.entries(options).map(([key, option]) => (
                <FormGroup key={key} sx={{ flexGrow: 1 }}>
                  <Typography variant="overline" sx={{ color: 'secondary.text' }}>{option.label}</Typography>
                  <Grid container spacing={2}>
                    {chunk(option.variants, 5).map((columnVariants, columnIndex) => (
                      <Grid item xs={6} key={columnIndex}>
                        {columnVariants.map((variant) => (
                          <Box key={variant.value}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={
                                      option.getState.has(variant.value)
                                      || applied[key as keyof typeof options].includes(variant.value)
                                    }
                                  onChange={(e) => {
                                      if (e.target.checked) {
                                        option.getState.add(variant.value);
                                      } else {
                                        option.getState.delete(variant.value);
                                      }
                                      option.setState(new Set(option.getState));
                                    }}
                                />
                                )}
                              label={variant.label}
                            />
                          </Box>
                          ))}
                      </Grid>
                      ))}
                  </Grid>
                </FormGroup>
              ))
            }
        </Box>
      </CardContent>
      <DialogActions sx={{ justifyContent: 'start' }}>
        <Button variant="contained" onClick={handleApplyFilters}>Apply Filters</Button>
        <Button onClick={handleCloseFilters}>Cancel</Button>
        <Button variant="text" onClick={handleResetFilters}>Clear filters</Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;
