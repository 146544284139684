import React from 'react';
import { useQuery } from '@apollo/client';
import {
 Outlet, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import {
  Box,
  Chip,
  Grid,
  Tab, Tabs,
  Typography,
} from '@mui/material';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { GET_SINGLE_AWO } from '../../queries';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const tz = dayjs.tz.guess();

const tabsOptions = [
  {
    label: 'Settings',
    url: 'settings',
  },
  {
    label: 'Members',
    url: 'members',
  },
];

function SingleOrganization() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { loading, error, data } = useQuery(GET_SINGLE_AWO, {
    variables: { id: parseInt(params.organizationId || '', 10) },
  });

  const [value] = React.useState(location.pathname.includes('members') ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const { url } = tabsOptions[newValue];
    const path = `/organizations/${params.organizationId}/${url}`;
    navigate(path);
  };

  if (loading) return null;
  if (error) throw error;
  return (
    <Box sx={{
        padding: 3,
        minHeight: '100vh',
      }}
    >
      <Box sx={{
          position: 'sticky',
          top: 0,
          background: '#fff',
          zIndex: 1,
          px: 3,
          mx: -3,
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Grid
          sx={{
            mb: 3,
          }}
          spacing={2}
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">{data.awo.name}</Typography>
          </Grid>
          <Grid item>
            <Chip label={`Last updated: ${dayjs.utc(data.awo.dateUpdated).tz(tz).format('M/D/YYYY [@] h:mm:ss a z')}`} />
          </Grid>
        </Grid>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="shelter settings">
            {
              tabsOptions.map((tab, idx) => (
                <Tab label={tab.label} key={tab.label} value={idx} />
              ))
            }
          </Tabs>
        </Box>
      </Box>
      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={12}>
          <Box role="tabpanel" sx={{ px: { xs: 2 } }}>
            <Outlet context={{ awo: data.awo }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SingleOrganization;
