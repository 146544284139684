import React from 'react';
import {
 Box, Grid, Tab, ThemeProvider, Typography,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { nextTheme } from '../../theme';
import MembersPanel from '../Organizations/MembersPanel';
import SettingsPanel from './SettingsPanel';
import { useAwoContext } from '../AwoContextProvider';
import ReportsPanel from './ReportsPanel';

function SettingsContainer() {
  const { awo } = useAwoContext();
  const [activeTab, setActiveTab] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <ThemeProvider theme={nextTheme}>
      <Box sx={{
        pt: 3,
        pb: {
          xs: 2,
          lg: 3,
        },
        minHeight: '100vh',
      }}
      >
        <Grid sx={{ px: { xs: 2, md: 3 } }} spacing={2} container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h5">{awo?.name}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ pt: { xs: 2, md: 3 } }}>
          <Grid item xs={12}>
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 3 }}>
                <TabList onChange={handleChange} aria-label="shelter settings">
                  <Tab label="Settings" value="1" />
                  <Tab label="Members" value="2" />
                  {!awo?.preSurrenderDashboard && !awo?.coSheltering ? (
                    <Tab label="Reports" value="3" />
                  ) : null}
                </TabList>
              </Box>
              <TabPanel sx={{ px: { xs: 2 } }} value="1">
                <SettingsPanel awo={awo} />
              </TabPanel>
              <TabPanel sx={{ p: { xs: 2 } }} value="2">
                <MembersPanel awo={awo} />
              </TabPanel>
              {!awo?.preSurrenderDashboard && !awo?.coSheltering ? (
                <TabPanel sx={{ p: { xs: 2 } }} value="3">
                  <ReportsPanel />
                </TabPanel>
              ) : null}
            </TabContext>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default SettingsContainer;
