import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAwoContext } from '../AwoContextProvider';
import { phoneFormatter } from '../../utilities';

function QRCodeContainer() {
  const { awo } = useAwoContext();
  const pn = phoneFormatter(awo?.twilioPhoneNumber);
  return (
    <Box display="flex" width="100%" height="100vh" alignItems="center" justifyContent="center" flexDirection="column">
      <Box>
        <img
          alt="Signup QR Code"
          height="240px"
          width="240px"
          src="/signup_qr_code.png"
          onError={(e) => { e.target.style.display = 'none'; }}
        />
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" color="primary.contrastLight" align="center">
          {'Have the pet parent scan the QR code or text "woof" to '}
          {`${pn} to receive 30 days of Petcademy support`}
        </Typography>
      </Box>
    </Box>
  );
}

export default QRCodeContainer;
