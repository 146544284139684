import React from 'react';
import { Divider, List, ListItemText } from '@mui/material';
import { capitalize } from 'lodash';
import { grey } from '@mui/material/colors';
import DateHeader from './DateHeader';
import { PETCADEMY_USER_IDS, setMaxWidthOnImg } from '../utilities';
import MediaThumbnail from './WithProfile/MediaThumbnail';
import StyledPaper from './StyledPaper';
import { ZendeskTicket } from '../types/TicketReview';

interface ZendeskCardProperties {
  zendeskTicket: ZendeskTicket;
  isDesktop: boolean;
  firstName: string;
  shaded?: boolean;
  setDialogDate: React.Dispatch<React.SetStateAction<string>>;
  setDialogImg: React.Dispatch<React.SetStateAction<string>>;
  setImgDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ZendeskCard({
  zendeskTicket,
  isDesktop,
  firstName,
  setDialogDate,
  setDialogImg,
  setImgDialogOpen,
  shaded = true,
}: ZendeskCardProperties) {
  return (
    <StyledPaper key={zendeskTicket.id} color={shaded ? grey[50] : 'white'}>
      <DateHeader title="Asked a question" date={zendeskTicket.dateUpdated} isDesktop={isDesktop} />
      <List component="div" sx={{ pt: 0, pb: 0 }}>
        {zendeskTicket.comments.map((c, idx, arr) => (
          <React.Fragment key={c.id}>
            <ListItemText
              primary={PETCADEMY_USER_IDS.includes(c.authorId) ? 'Petcademy Team' : `${capitalize(firstName)}`}
              primaryTypographyProps={{ variant: 'caption', sx: { color: 'rgba(0, 0, 0, 0.6)' } }}
              secondary={<div dangerouslySetInnerHTML={{ __html: setMaxWidthOnImg(c.htmlBody) }} />}
              secondaryTypographyProps={{ variant: 'body2', color: 'black' }}
            />
            {c.medias.map((m, i) => (
              <MediaThumbnail
                setDialogDate={setDialogDate}
                setDialogImg={setDialogImg}
                setImgDialogOpen={setImgDialogOpen}
                animalMedia={m}
                animalName=""
                index={i}
                key={m.id}
              />
            ))}
            {idx < arr.length - 1 && <Divider sx={{ mb: 1.5 }} />}
          </React.Fragment>
        ))}
      </List>
    </StyledPaper>
  );
}

export default ZendeskCard;
