import React from 'react';
import { Box, Skeleton } from '@mui/material';

function TagReviewLoader() {
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ width: '120px' }}>
            <Skeleton height={12} />
          </Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Skeleton width={74} height={30} />
            <Skeleton width={150} height={30} />
            <Skeleton width={175} height={30} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex', flexDirection: 'column', gap: '8px', padding: '16px 0px',
          }}
        >
          <Skeleton height={1000} />
        </Box>
      </Box>
    );
  }

  export default TagReviewLoader;
