import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const faqLinkSchema = {
  faqLink: yup.string().url('FAQ link must be a valid URL (including http:// or https://)'),
};

function FAQLinkPartial() {
  return (
    <TextFieldElement
      name="faqLink"
      sx={{ marginTop: 2 }}
      fullWidth
      label="FAQ for adopters (optional)"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
}

export default FAQLinkPartial;
