import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const websiteSchema = {
  website: yup.string().url('Website must be a valid URL (including http:// or https://)'),
};
function WebsitePartial() {
  return (
    <TextFieldElement
      name="website"
      sx={{ marginTop: 2 }}
      fullWidth
      label="Website (optional)"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
}
export default WebsitePartial;
