import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Box, Button, FormControlLabel, Grid, Switch, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { get } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { GET_FEEDBACK } from '../../queries';
import withProfile from '../withProfile';
import DateFilter from '../DateFilter';
import TasksList from '../TasksList';
import BehavioralIssuesList from './BehavioralIssuesList';
import TaskFiltersDialog from '../TaskFiltersDialog';
import { DEFAULT_END_DAYS_AGO } from '../../utilities';

function FeedbackContainer({
  setDrawerOpen, setUserId,
}) {
  const { loading, error, data } = useQuery(GET_FEEDBACK);
  const [taskDaysAgo, setTaskDaysAgo] = React.useState(-1);
  const [feedbackDaysAgo, setFeedbackDaysAgo] = React.useState(-1);
  const [showCompleted, setShowCompleted] = React.useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [careType, setCareType] = React.useState(null);
  const [appliedCareType, setAppliedCareType] = React.useState(null);

  const identity = useIdentityContext();
  const isPlus = get(identity, 'user.app_metadata.roles[1]') === 'plus' || get(identity, 'user.app_metadata.roles', []).includes('admin');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const filtersModalHandler = () => {
    if (appliedCareType) {
      setCareType(null);
      setAppliedCareType(null);
      return;
    }
    setFilterDialogOpen(true);
  };

  if (loading) return '';
  if (error) throw error;

  const totalBehavioralResponses = data.behavioralIssues.numResponses;
  const totalTasks = data.taskCount.count;

  const grids = [];

  if (totalBehavioralResponses > 0 && isPlus) {
    grids.push((
      <Grid
        key="adoptedDogbehavioralIssues"
        item
        xs={12}
        md={totalTasks > 0 ? 6 : 12}
        sx={{
          padding: 3,
          borderRight: {
            xs: 0,
            md: 1,
          },
          borderColor: {
            xs: 'primary.50',
            md: 'primary.50',
          },
        }}
      >
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Typography variant="overline">Feedback</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" sx={{ pb: 5 }}>
          <Box display="flex">
            <Box sx={{ mr: 1, flexGrow: 1 }}>
              <DateFilter
                selectedDate={feedbackDaysAgo}
                onChange={(e) => setFeedbackDaysAgo(e.target.value)}
              />
            </Box>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <BehavioralIssuesList
            halfscreen={totalTasks === 0}
            feedbackDaysAgo={feedbackDaysAgo}
          />
        </Grid>
      </Grid>
    ));
  }

  const taskGrid = (
    <Grid
      key="tasks"
      item
      xs={12}
      md={6}
      sx={{
        padding: 3,
        borderBottom: 1,
        borderColor: 'primary.50',
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Typography variant="overline">Tasks</Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
        <Box display="flex">
          <Box sx={{ mr: 1, flexGrow: 1 }}>
            <DateFilter
              selectedDate={taskDaysAgo}
              onChange={(e) => setTaskDaysAgo(e.target.value)}
            />
          </Box>
          <Button
            startIcon={appliedCareType ? <CloseIcon /> : <FilterAltOutlinedIcon />}
            variant="outlined"
            size="small"
            onClick={filtersModalHandler}
          >
            {`${appliedCareType ? 'Clear filters' : 'Filters'}`}
          </Button>
        </Box>
        <FormControlLabel
          sx={{
            marginRight: 0,
            paddingRight: 0,
            color: 'primary.main',
            width: {
              xs: '100%',
              md: 'auto',
            },
            mt: {
              xs: 2,
              md: 0,
            },
          }}
          control={(
            <Switch
              size="small"
              checked={showCompleted}
              onChange={() => setShowCompleted(!showCompleted)}
              color="primary"
            />
          )}
          label="Show Completed"
        />
      </Box>
      <TasksList
        setUserId={setUserId}
        setDrawerOpen={setDrawerOpen}
        showCompleted={showCompleted}
        careType={appliedCareType}
        initStartDaysAgo={taskDaysAgo}
        initEndDaysAgo={DEFAULT_END_DAYS_AGO}
      />
    </Grid>
  );

  if (totalTasks > 0) {
    if (isDesktop) {
      grids.push(taskGrid);
    } else {
      grids.unshift(taskGrid);
    }
  }

  if (totalTasks === 0 && totalBehavioralResponses === 0) {
    grids.push((
      <Grid
        key="no-data"
        item
        xs={12}
        sx={{
          pr: {
            xs: 2,
            lg: 3,
          },
          borderTop: 1,
          borderColor: 'primary.50',
        }}
      >
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography color="primary" variant="body1" display="inline" component="span">
            No open tasks or feedback. Updates will appear here once pet parents start checking in.
          </Typography>
        </Box>
      </Grid>
    ));
  }

  return (
    <Box>
      <Grid container>
        {grids}
      </Grid>
      <TaskFiltersDialog
        open={filterDialogOpen}
        setOpen={setFilterDialogOpen}
        careType={careType}
        setCareType={setCareType}
        setAppliedCareType={setAppliedCareType}
      />
    </Box>
  );
}

export default withProfile(FeedbackContainer);
