import React, { Dispatch, SetStateAction } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box, Button, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Tab,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../theme';
import { TicketReview } from '../../types/TicketReview';
import TagCustomSearch from './TagCustomSearch';
import { FLAGGED } from '../../utilities';

interface TagReviewSearchProps {
  activeTagName: string,
  handleSelectCategory: (tag: TicketReview) => void,
  ticketsToReview: TicketReview[] | undefined,
  setTicketId: Dispatch<SetStateAction<string | null>>,
  setTicketSearch: Dispatch<SetStateAction<Record<string, any>>>,
  setResultsCount: (count: number) => void,
  initResultsCount: number,
  setCustomSearchActive: (active: boolean) => void,
}

const tagStyles = (condition: boolean, color: string) => ({
  display: 'flex',
  justifyContent: 'space-between',
  p: 2,
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'background 0.3s',
  ...(condition
    ? {
      background: color,
    } : {
      background: colors.grey['50'],
      '&:hover': {
        background: colors.grey['100'],
      },
    }
  ),
});

function TagReviewSearch({
  activeTagName,
  handleSelectCategory,
  ticketsToReview,
  setTicketId,
  setTicketSearch,
  setResultsCount,
  initResultsCount,
  setCustomSearchActive,
}: TagReviewSearchProps) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState('toReview');
  const [initResults] = React.useState(initResultsCount);
  const [showDialog, setShowDialog] = React.useState(false);
  const [enableShowDialog, setEnableShowDialog] = React.useState<boolean>(false);

  return (
    <>
      <TabContext value={activeTab}>
        <Box>
          <TabList
            variant="fullWidth"
            onChange={(e, newValue) => {
              if (newValue === 'customSearch') {
                setTicketId(null);
                setResultsCount(0);
                setActiveTab(newValue);
                setCustomSearchActive(true);
              } else if (enableShowDialog) {
                setShowDialog(true);
              } else {
                setResultsCount(initResults);
                setActiveTab('toReview');
                setCustomSearchActive(false);
              }
            }}
            sx={{ mt: 1.5 }}
            aria-label="review and search"
          >
            <Tab label="To Review" value="toReview" />
            <Tab label="Custom Search" value="customSearch" />
          </TabList>
        </Box>
        <TabPanel
          value="toReview"
        >
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              flexDirection: 'column',
            }}
          >
            {ticketsToReview!.map((tag) => {
              let selectedColor = colors.primary['50'];
              let unselectedColor = colors.secondary.text;
              let selectedUnreviewedCount = colors.primary['200'];
              let unselectedUnreviewedColor = 'rgba(0, 0, 0, 0.38)';
              if (tag.tagName === 'flagged') {
                selectedColor = colors.secondary['50'];
                unselectedColor = colors.secondary.main;
                selectedUnreviewedCount = colors.secondary['200'];
                unselectedUnreviewedColor = colors.secondary.main;
              }
              return (
                <Box
                  key={tag.tagName}
                  sx={tagStyles(activeTagName === tag.tagName, activeTagName === 'flagged' ? colors.secondary.dark : colors.primary.dark)}
                  onClick={() => {
                    handleSelectCategory(tag);
                    navigate('/tag-review');
                  }}
                >
                  <Typography variant="subtitle2" sx={{ color: activeTagName === tag.tagName ? selectedColor : unselectedColor }}>{tag.tagName === 'flagged' ? 'Flagged' : tag.tagName}</Typography>
                  <Typography variant="subtitle2" sx={{ color: activeTagName === tag.tagName ? selectedUnreviewedCount : unselectedUnreviewedColor }}>{tag.unreviewedCount}</Typography>
                </Box>
              );
            })}
          </Box>
        </TabPanel>
        <TabPanel value="customSearch">
          <TagCustomSearch
            setTicketSearch={setTicketSearch}
            setEnableShowDialog={setEnableShowDialog}
          />
        </TabPanel>
      </TabContext>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        PaperProps={{
          sx: {
            width: '600px',
            maxWidth: '90vw',
          },
        }}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you switch tabs, this will clear your custom search
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const tag = ticketsToReview!.find((t) => t.tagName === 'flagged') || FLAGGED;
              setResultsCount(tag.unreviewedCount);
              setTicketId(tag.firstQuestion.ticketId);
              setActiveTab('toReview');
              setShowDialog(false);
              handleSelectCategory(tag);
              navigate('/tag-review');
            }}
          >
            Proceed
          </Button>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TagReviewSearch;
