/* eslint-disable */
import { Box, Typography } from '@mui/material';
import React from 'react';

function TermsAndConditions() {
  return (
    <Box sx={{
      p: {
        xs: 2,
        lg: 3,
      },
      maxWidth: '800px',
      margin: '0 auto',
    }}
    >
      <Typography variant="h4">Terms of Service</Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy, Inc., (“Petcademy”, “we”, “our”) offers a service that provides support to fosters, adopters and Animal Welfare Organizations (“AWOs”). This Terms of Service Agreement (“TOS”) governs your use of and access to Services (defined below).`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING OUR SERVICE, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH PETCADEMY, AND (3) YOU HAVE THE AUTHORITY TO ENTER INTO THESE TERMS OF SERVICE PERSONALLY OR ON BEHALF OF THE COMPANY YOU HAVE NAMED AS THE USER, AND TO BIND THAT COMPANY TO THESE TERMS. THE TERM “YOU” REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE, IDENTIFIED AS THE USER WHEN YOU REGISTERED WITH THE SERVICES. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF SERVICE, YOU MAY NOT ACCESS OR USE THE SERVICES.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`THE TERMS OF SERVICE INCLUDE A CLASS ACTION WAIVER AND REQUIRE BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`We know that your privacy is important. For this reason, we have created a privacy policy that describes our collection, use and disclosure practices regarding any personal information that you provide to us. You can find our privacy policy at this link: `}<a href="https://app.petcademy.org/privacy-policy">https://app.petcademy.org/privacy-policy</a>
      </Typography>
      <br />
      <Typography variant="h5">ACCEPTANCE OF TERMS</Typography>
      <br />
      <Typography variant="body1">
        {`The following Terms of Service is a legally binding agreement that shall govern the relationship with AWOs and others which may interact or interface with Petcademy, in association with the use of the Petcademy website, which includes Petcademy, (the “Site”) and its Services, which shall be defined below.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Please note that these Terms of Service are subject to change by Petcademy in its sole discretion at any time. You will be presented with the updated Terms the next time you make a transaction through the Services.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`1. DESCRIPTION OF WEBSITE SERVICES OFFERED`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`The Site is an online communication platform which has the following description: Petcademy offers post-adoption and foster support to AWOs and new pet parents through email, community forums and SMS messages.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Any and all visitors to our site, despite whether they are registered or not, shall be deemed as “users'' of the herein contained Services provided for the purpose of this TOS. Once an individual registers for our Services, through the process of creating an account, the user shall then be considered a “member.”`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`The user and/or member acknowledges and agrees that the Services provided and made available through our website and applications, which may include some mobile applications and that those applications may be made available on various social media networking sites and numerous other platforms and downloadable programs, are the sole property of Petcademy. At its discretion, Petcademy may offer additional website Services and/or products, or update, modify or revise any current content and Services, and this Agreement shall apply to any and all additional Services and/or products and any and all updated, modified or revised Services unless otherwise stipulated. Petcademy does hereby reserve the right to cancel and cease offering any of the aforementioned Services and/or products. You, as the end user and/or member, acknowledge, accept and agree that Petcademy shall not be held liable for any such updates, modifications, revisions, suspensions or discontinuance of any of our Services and/or products. Your continued use of the Services provided, after such posting of any updates, changes, and/or modifications shall constitute your acceptance of such updates, changes and/or modifications, and as such, frequent review of this Agreement and any and all applicable terms and policies should be made by you to ensure you are aware of all terms and policies currently in effect. Should you not agree to the updated, revised or modified terms, you must stop using the provided Services forthwith.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Furthermore, the user and/or member understands, acknowledges and agrees that the Services offered shall be provided “AS IS” and as such Petcademy shall not assume any responsibility or obligation for the timeliness, missed delivery, deletion and/or any failure to store user content, communication or personalization settings.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`2. MEMBER ACCOUNT, USERNAME, PASSWORD AND SECURITY`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`When you set up an account, you are the sole authorized user of your account. You shall be responsible for maintaining the secrecy and confidentiality of your password and for all activities that transpire on or within your account. It is your responsibility for any act or omission of any user(s) that access your account information that, if undertaken by you, would be deemed a violation of the TOS. It shall be your responsibility to notify Petcademy immediately if you notice any unauthorized access or use of your account or password or any other breach of security. Petcademy shall not be held liable for any loss and/or damage arising from any failure to comply with this term and/or condition of the TOS.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`3. PROPRIETARY RIGHTS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`You do hereby acknowledge and agree that Petcademy’s Services and any essential software that may be used in connection with our Services (“Software”) shall contain proprietary and confidential material that is protected by applicable intellectual property rights and other laws.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Furthermore, you herein acknowledge and agree that any Content which may be contained in any advertisements or information presented by and through our Services or by advertisers is protected by copyrights, trademarks, patents or other proprietary rights and laws. Therefore, except for that which is expressly permitted by applicable law or as authorized by Petcademy or such applicable licensor, you agree not to alter, modify, lease, rent, loan, sell, distribute, transmit, broadcast, publicly perform and/or created any plagiaristic works which are based on Petcademy Services (e.g. Content or Software), in whole or part.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy herein has granted you personal, non-transferable and non-exclusive rights and/or license to make use of the object code or our Software on a single computer, as long as you do not, and shall not, allow any third party to duplicate, alter, modify, create or plagiarize work from, reverse engineer, reverse assemble or otherwise make an attempt to locate or discern any source code, sell, assign, sublicense, grant a security interest in and/or otherwise transfer any such right in the Software. Furthermore, you do herein agree not to alter or change the Software in any manner, nature or form, and as such, not to use any modified versions of the Software, including and without limitation, for the purpose of obtaining unauthorized access to our Services. Lastly, you also agree not to access or attempt to access our Services through any means other than through the interface which is provided by Petcademy for use in accessing our Services.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`4. CONTENT`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`As a user or member of the Site, you herein acknowledge, understand and agree that all information, text, software, data, photographs, music, video, messages, tags or any other content, whether it is publicly or privately posted and/or transmitted, is the expressed sole responsibility of the individual from whom the content originated. In short, this means that you are solely responsible for any and all content posted, uploaded, emailed, transmitted or otherwise made available by way of the Petcademy Services, and as such, we do not guarantee the accuracy, integrity or quality of such content. It is expressly understood that by use of our Services, you may be exposed to content including, but not limited to, any errors or omissions in any content posted, and/or any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or otherwise made available by Petcademy.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy herein reserves the right to pre-screen, refuse and/or delete any content currently available through our Services. In addition, we reserve the right to remove and/or delete any such content that would violate the TOS or which would otherwise be considered offensive to other visitors, users and/or members.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy herein reserves the right to access, preserve and/or disclose member account information and/or content if it is requested to do so by law.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`When you upload content to the Services, you retain any ownership rights you have in your content, but you grant to us the following license to use that content: When your content is created with or submitted to the services, you hereby grant us a worldwide, royalty free, perpetual, irrevocable, non-exclusive, transferable and sublicensable license to use, copy, modify, adapt, prepare derivative works from, distribute, perform, and display your content and any name, username or likeness provided in connection with your content in all media formats and channels now known or later developed. This license includes the right for us to make your content available for syndication, broadcast, distribution, or publication by other companies, organizations, or individuals who partner with us.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`5. COMMERCIAL REUSE OF SERVICES`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`The member or user herein agrees not to replicate, duplicate, copy, trade, sell, resell nor exploit for any commercial reason any part, use of, or access to Petcademy’s sites.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`6. PAYMENTS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`You agree to pay all fees or charges for Products purchased in accordance with the fees in effect at the time the fee or charge is due and payable, as indicated on the Services. To pay for an Order, you will need to provide Petcademy with the information necessary to process an Order from you, including your billing information requested by the Services to pay for such Order. You may pay for your Order via credit card or any other manner then available through the Services. By submitting your payment information to us, you authorize us to charge the applicable payment method at our convenience but within thirty (30) days of credit card authorization. You represent that you will not use any credit card or other form of payment unless you have all necessary authorization to do so. All prices for Products listed on the Services exclude sales taxes, if applicable, which will be added to your total purchase price. You shall use the Product only for personal purposes and not for resale.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`You may have the opportunity to purchase a subscription to certain Products, such that the Products will be delivered to you automatically on a recurring basis. In the event you subscribe to such Products, your subscription will continue indefinitely until terminated. After your initial subscription period, and again after any subsequent subscription period, your subscription will automatically commence on the first day following the end of such period (each a “Renewal Commencement Date”) and continue for an additional equivalent period, at our then-current price for such subscription. You agree that your Account will be subject to this automatic renewal feature unless you cancel your subscription at least (a) thirty (30) days prior to the Renewal Commencement Date (or in the event that you receive a notice from Company that your subscription will be automatically renewed, you will have thirty (30) days from the date of notice). If you do not wish certain Products to renew automatically, or if you want to change or terminate your subscription, please email Petcademy at `}<a href="mailto:info@petcademy.org">info@petcademy.org</a>{`. By subscribing, you authorize us to charge your credit card that you provide us now, and again at the beginning of any subsequent subscription period. Upon renewal of your subscription, if we do not receive payment from your payment provider, (i) you agree to pay all amounts due on your Account upon demand, and/or (ii) you agree that we may either terminate or suspend your subscription and continue to attempt to charge your payment provider until payment is received (upon receipt of payment, your subscription will be activated and for purposes of automatic renewal, your new subscription commitment period will begin as of the day payment was received).`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`7. PROMOTIONAL OFFERS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`We may run promotional offers from time to time through the Services. The terms of any such promotion will be posted on the Services. Unless otherwise indicated, we may establish and modify, in our sole discretion, the terms of such offer and end such offer at any point.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`8. GENERAL RULES OF USER CONDUCT`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`It is our goal to make access to our Services a good experience for all of our users. You agree not to, and represent and warrant that you will not use, reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, your use of the Services, or access to the Services for any purposes other than for which the Services are being provided to you, or do any of the following: Attempt to reverse engineer or jeopardize the correct functioning of the Services, or otherwise attempt to derive the source code of the software (including the tools, methods, processes, and infrastructure) that enables or underlies the Services; Attempt to gain access to secured portions of the Services to which you do not possess access rights; Upload or transmit any form of virus, worm, Trojan horse, or other malicious code; Use any high volume automatic, electronic or manual process to access, search or harvest information from the Services (including without limitation robots, spiders or scripts); Interfere in any way with the proper functioning of the Services or interfere with or disrupt any servers or networks connected to the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Services; or Impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`9. FEEDBACK`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`You acknowledge and agree that any feedback, comments or suggestions you may provide to Petcademy regarding Petcademy or the Services is entirely voluntary and Petcademy is free to use such feedback, comments or suggestions as Petcademy sees fit and without any obligation to you.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`10. MODIFICATIONS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy shall reserve the right at any time it may deem fit, to modify, alter and or discontinue, whether temporarily or permanently, our service, or any part thereof, with or without prior notice. In addition, we shall not be held liable to you or to any third party for any such alteration, modification, suspension and/or discontinuance of our Services, or any part thereof.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`11. TERMINATION`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`As a member of Petcademy, you may cancel or terminate your account, associated email address and/or access to our Services by submitting a cancellation or termination request to `}<a href="mailto:info@petcademy.org">info@petcademy.org</a>.
      </Typography>
      <br />
      <Typography variant="body1">
        {`As a member, you agree that Petcademy may, without any prior written notice, immediately suspend, terminate, discontinue and/or limit your account, any email associated with your account, and access to any of our Services.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Furthermore, you herein agree that any and all terminations, suspensions, discontinuances, and or limitations of access for cause shall be made at our sole discretion and that we shall not be liable to you or any other third party with regards to the termination of your account, associated email address and/or access to any of our Services.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`12. THIRD PARTY CONTENT AND SERVICES`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`As a part of the Services, you may have access to Content or services made available by other users, suppliers, advertisers, and other third parties, or materials that are hosted by another party. You agree that it is impossible for Petcademy to monitor such Content and materials and that you access the Content and materials at your own risk. To the extent you access a third-party service through our Services, the terms of use and privacy policy of that third-party service will govern your relationship with such third-party service, including any transactions and activities made through the third-party service.`}
      </Typography>
      <br />
      <Typography variant="h5">
        {`WARRANTY DISCLAIMERS`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`1. YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`a) THE USE OF PETCADEMY SERVICES AND SOFTWARE ARE AT THE SOLE RISK OF YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN “AS IS” AND/OR “AS AVAILABLE” BASIS. PETCADEMY AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON- INFRINGEMENT.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`b) PETCADEMY AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH WARRANTIES THAT (i) PETCADEMY SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) PETCADEMY SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE PETCADEMY SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v) THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE CORRECTED.`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`c) ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF PETCADEMY SERVICES OR SOFTWARE SHALL BE ACCESSED AT YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF ANY SUCH INFORMATION OR MATERIAL.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`2. LIMITATION OF LIABILITY`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT PETCADEMY AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND RESULT FROM:`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`a) THE USE OR INABILITY TO USE OUR SERVICE;`}
      </Typography>
      <Typography variant="body1">
        {`b) THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;`}
      </Typography>
      <Typography variant="body1">
        {`c) UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA;`}
      </Typography>
      <Typography variant="body1">
        {`d) STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE;`}
      </Typography>
      <Typography variant="body1">
        {`e) AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`3. RELEASE`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`In the event you have a dispute, you agree to release Petcademy (and its officers, directors, employees, agents, parent subsidiaries, affiliates, co-branders, partners and any other third parties) from claims, demands and damages (actual and/or consequential) of every kind and nature, known and unknown, suspected or unsuspected, disclosed and undisclosed, arising out of or in any way connected to such dispute.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`4. EXCLUSION AND LIMITATIONS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`THERE ARE SOME JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OF EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS OF SECTIONS WARRANTY DISCLAIMERS AND LIMITATION OF LIABILITY MAY NOT APPLY TO YOU.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`5. NOTICE`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`Petcademy may furnish you with notices, including those with regards to any changes to the TOS, including but not limited to email, regular mail, MMS or SMS, text messaging, postings on our website Services, or other reasonable means currently known or any which may be hereinafter developed. Any such notices may not be received if you violate any aspects of the TOS by accessing our Services in an unauthorized manner. Your acceptance of this TOS constitutes your agreement that you are deemed to have received any and all notices that would have been delivered had you accessed our Services in an authorized manner.`}
      </Typography>
      <br />
      <Typography variant="h5">
        {`GENERAL INFORMATION`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`1. ENTIRE AGREEMENT`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`This TOS constitutes the entire agreement between you and Petcademy and shall govern the use of our Services, superseding any prior version of this TOS between you and us with respect to Petcademy Services. You may also be subject to additional terms and conditions that may apply when you use or purchase certain other Petcademy Services, affiliate Services, third-party content or third-party software.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`2. CHOICE OF LAW AND FORUM`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`It is at the mutual agreement of both you and Petcademy with regard to the TOS that the relationship between the parties shall be governed by the laws of the state of New York without regard to its conflict of law provisions and that any and all claims, causes of action and/or disputes, arising out of or relating to the TOS, or the relationship between you and Petcademy, shall be filed within the courts having jurisdiction within the County of District Of New York, New York or the U.S. District Court located in said state. You and Petcademy agree to submit to the jurisdiction of the courts as previously mentioned, and agree to waive any and all objections to the exercise of jurisdiction over the parties by such courts and to venue in such courts.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`3. WAIVER AND SEVERABILITY OF TERMS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`At any time, should Petcademy fail to exercise or enforce any right or provision of the TOS, such failure shall not constitute a waiver of such right or provision. If any provision of this TOS is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the TOS remain in full force and effect.`}
      </Typography>
      <br />
      <Typography variant="h6">
        {`4. STATUTE OF LIMITATIONS`}
      </Typography>
      <br />
      <Typography variant="body1">
        {`You acknowledge, understand and agree that regardless of any statute or law to the contrary, any claim or action arising out of or related to the use of our Services or the TOS must be filed within 3 Years year(s) after said claim or cause of action arose or shall be forever barred.`}
      </Typography>
      <br />
    </Box>
  );
}

export default TermsAndConditions;
