import React, { useState } from 'react';
import {
  Button, CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

type SetCoordinatorsFilters = (coordinatorsSet: Set<string>) => void;

function TaskFiltersDialog({
  open,
  setOpen,
  coordinatorFilters,
  setCoordinatorFilters,
  coordinators,
}: {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  coordinatorFilters: Set<string>,
  setCoordinatorFilters: React.Dispatch<React.SetStateAction<Set<string>>> | SetCoordinatorsFilters,
  coordinators: Record<'id' | 'firstName' | 'lastName', string>[],
}) {
  const [appliedTemporary, setAppliedTemporary] = useState<Set<string>>(coordinatorFilters || new Set([]));

  const handleClose = () => {
    setOpen(false);
    setAppliedTemporary(coordinatorFilters || new Set([]));
  };

  const applyFilters = () => {
    setOpen(false);
    setCoordinatorFilters(appliedTemporary);
    if (appliedTemporary && appliedTemporary.size > 0) {
      localStorage.setItem('coordinatorFilters', JSON.stringify(Array.from(appliedTemporary)));
    } else {
      localStorage.removeItem('coordinatorFilters');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="filter-dialog-title"
      aria-describedby="filter-dialog-description"
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: '90vw',
        },
      }}
      maxWidth={false}
    >
      <DialogTitle id="logout-dialog-title">
        <Typography variant="h6bold">Filters</Typography>
      </DialogTitle>
      <CardContent>
        <FormGroup>
          <Typography variant="body1">Coordinator</Typography>
          {
            coordinators.map((coordinator) => (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={appliedTemporary.has(coordinator.id)}
                    onChange={(e) => {
                      const changedList = new Set([...appliedTemporary]);
                      if (e.target.checked) {
                        changedList.add(coordinator.id);
                      } else {
                        changedList.delete(coordinator.id);
                      }
                      setAppliedTemporary(changedList);
                    }}
                  />
                )}
                label={`${coordinator.firstName} ${coordinator.lastName}`}
              />
            ))
          }
        </FormGroup>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={applyFilters}>Apply Filters</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </CardActions>
    </Dialog>
  );
}

export default TaskFiltersDialog;
