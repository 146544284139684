import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChevronRight, ContactPageOutlined, Link } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { isNil, values } from 'lodash';
import dayjs from 'dayjs';
import { GET_TICKET, UPDATE_TICKET } from '../../queries';
import ZendeskCard from '../ZendeskCard';
import { TicketReview, ZendeskTicket } from '../../types/TicketReview';
import MediaDialog from '../MediaDialog';
import { ELIGIBLE_TAG_LIST } from '../../utilities';
import TagReviewLoader from './TagReviewLoader';
import { colors } from '../../theme';

interface TicketDisplayProps {
  id: string | null,
  ticketSearch: any,
  tag: TicketReview,
  setDrawerOpen: (value: boolean) => void,
  setUserId: (id: number) => void,
  setResultsCount: (count: number) => void,
  customSearchActive: boolean,
}

function EmptyTagsMessage() {
  return (
    <Box
      sx={{
      px: 3,
      py: 2.5,
      background: colors.grey['50'],
      borderRadius: '8px',
      lineHeight: 0,
    }}
    >
      <Typography variant="subtitle1">No tickets to display</Typography>
      <Typography variant="caption" sx={{ color: 'secondary.text' }}>Select another tag or modify your search</Typography>
    </Box>
  );
}

function TicketDisplay({
  id,
  ticketSearch,
  tag,
  setDrawerOpen,
  setUserId,
  setResultsCount,
  customSearchActive,
}: TicketDisplayProps) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [dialogDate, setDialogDate] = React.useState('');
  const [dialogImg, setDialogImg] = React.useState('');
  const [imgDialogOpen, setImgDialogOpen] = React.useState(false);
  const [tagList, setTagList] = React.useState<string[]>([]);
  const [getTicket, { loading, data }] = useLazyQuery<{
    question: ZendeskTicket,
    questionCount: { count: number },
    nextQuestion: ZendeskTicket,
  }>(GET_TICKET, {
    variables: {
      search: {
        id,
        tags: [tag.tagName],
      },
    },
  });

  const getNextTicket = () => getTicket({
    variables: {
      search: {
        id: data?.nextQuestion?.ticketId || '0',
        tags: ticketSearch?.tags || (customSearchActive ? undefined : [tag.tagName]),
        // eslint-disable-next-line no-nested-ternary
        reviewStatus: (customSearchActive ? ticketSearch?.reviewStatus : (tag.tagName === 'flagged' ? undefined : ['Not Reviewed'])),
        careType: ticketSearch?.careType,
        species: ticketSearch?.species,
        awoId: ticketSearch?.awoId,
        awoUserId: ticketSearch?.awoUserId,
      },
    },
  });
  const [updateTicket, { loading: updateLoading }] = useMutation(UPDATE_TICKET, {
    awaitRefetchQueries: true,
  });
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  React.useMemo(() => {
    if (data?.question?.tags) {
      setTagList(data.question.tags.map((t) => t.value));
    }
    if (data?.questionCount?.count != null) {
      setResultsCount(data.questionCount.count);
    }
  }, [data, setTagList, setResultsCount]);

  // initial load
  React.useEffect(() => {
    if (id) {
      getTicket({
        variables: {
          search: {
            id,
            tags: [tag.tagName],
            reviewStatus: tag.tagName !== 'flagged' ? ['Not Reviewed'] : undefined,
          },
        },
      });
    }
  }, [getTicket, id, tag, tag.tagName]);

  React.useEffect(() => {
    if (values(ticketSearch).some((v) => !isNil(v))) {
      getTicket({
        variables: {
          search: ticketSearch,
        },
      });
    }
  }, [getTicket, ticketSearch]);

  if (id === null && !values(ticketSearch).some((v) => !isNil(v))) {
    setResultsCount(0);
    return <EmptyTagsMessage />;
  }
  if (loading || !data) return <TagReviewLoader />;
  if (data.question === null) return <EmptyTagsMessage />;

  // eslint-disable-next-line no-nested-ternary
  const reviewStatus = data?.question.flagDate ? 'Flagged' : (data?.question.reviewDate ? `Reviewed by ${data?.question.reviewedBy.firstName}` : 'Not Reviewed');

  return (
    <Box>
      <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 2,
        }}
      >
        <Box>
          <Typography variant="overline">Tickets</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            endIcon={<ChevronRight />}
            onClick={() => getNextTicket()}
          >
            Skip
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            endIcon={<ChevronRight />}
            disabled={tag.tagName === 'flagged'}
            onClick={() => updateTicket({
              variables: {
                id: data.question.id,
                ticket: {
                  flagDate: dayjs.utc().format('YYYY-MM-DDTHH:mm:ss'),
                },
              },
              onCompleted: () => getNextTicket(),
              refetchQueries: customSearchActive ? undefined : [
                'GetTicketsToReview',
              ],
            })}
          >
            Flag for later
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              minWidth: '175px',
              maxWidth: '175px',
            }}
            endIcon={updateLoading ? null : <ChevronRight />}
            disabled={tagList.length === 0 || updateLoading}
            onClick={() => updateTicket({
              variables: {
                id: data.question.id,
                ticket: {
                  reviewDate: dayjs.utc().format('YYYY-MM-DDTHH:mm:ss'),
                  oldTags: data.question.tags.map((t) => ({ id: t.id, value: t.value, deleted: t.deleted })),
                  newTags: tagList,
                },
              },
              onCompleted: () => getNextTicket(),
              refetchQueries: customSearchActive ? undefined : [
                'GetTicketsToReview',
              ],
            })}
          >
            {updateLoading ? 'Saving...' : 'Mark as reviewed'}
          </Button>
        </Box>
      </Box>
      <Box sx={{ p: 3, bgcolor: grey[50], borderRadius: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant="subtitle1">{data.question.subject}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Chip
              label={reviewStatus}
              color={reviewStatus === 'Flagged' ? 'secondary' : 'default'}
            />
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(`${window.location.origin}/tag-review/${data.question.ticketId}`);
                setSnackbarMessage('Ticket URL copied');
                setSnackbarOpen(true);
              }}
            >
              <Link />
            </IconButton>
            <IconButton
              onClick={() => {
                setDrawerOpen(true);
                setUserId(parseInt(data?.question.petParent.id, 10));
              }}
            >
              <ContactPageOutlined />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{
            mt: 2,
            mb: 2,
            p: 2,
            bgcolor: 'white',
            borderRadius: 2,
          }}
        >
          <Typography sx={{ pb: 2 }} variant="subtitle2">Tags</Typography>
          <Autocomplete
            multiple
            options={ELIGIBLE_TAG_LIST}
            value={tagList}
            onChange={(_, value) => setTagList(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Eligible Tags"
              />
            )}
          />
        </Box>
        <ZendeskCard
          firstName={data.question.petParent.firstName}
          isDesktop={isDesktop}
          setDialogDate={setDialogDate}
          setDialogImg={setDialogImg}
          setImgDialogOpen={setImgDialogOpen}
          zendeskTicket={data.question}
          shaded={false}
        />
      </Box>
      <MediaDialog
        dialogDate={dialogDate}
        dialogImg={dialogImg}
        imgDialogOpen={imgDialogOpen}
        setImgDialogOpen={setImgDialogOpen}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={snackbarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </Box>
  );
}

export default TicketDisplay;
