import React from 'react';
import { useQuery } from '@apollo/client';
import {
  List, ListItemText, Paper, Skeleton, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import moment from 'moment';
import { capitalize, orderBy } from 'lodash';
import * as Sentry from '@sentry/react';
import { grey } from '@mui/material/colors';
import {
  beforeCapture, typeformMarkdownFormatter,
} from '../utilities';
import { GET_TYPEFORM_RESPONSES } from '../queries';
import ErrorMessage from './ErrorMessage';
import DateHeader from './DateHeader';
import StyledPaper from './StyledPaper';
import ZendeskCard from './ZendeskCard';

function SkeletonUpdateCard() {
  return (
    <StyledPaper>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="rectangle" height="400px" />
    </StyledPaper>
  );
}

function TypeformResponse({
  petParent, setDialogImg, setDialogDate, setImgDialogOpen,
}) {
  const { loading, error, data } = useQuery(GET_TYPEFORM_RESPONSES, {
    variables: { ppId: petParent.id },
    skip: petParent === null,
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  if (loading) return <SkeletonUpdateCard />;
  if (error) throw error;

  const combinedUpdates = data.responses.concat(data.questions)
    .concat(data.updates)
    .map((d) => ({ ...d, sortTime: d.submittedAt ? moment(d.submittedAt) : moment(d.dateUpdated) }));
  const combinedUpdatesSorted = orderBy(combinedUpdates, 'sortTime', 'desc');
  if (combinedUpdatesSorted.length === 0) {
    return (
      <StyledPaper color={grey[50]}>
        <Typography variant="subtitle2" display="block">No Updates Yet</Typography>
        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{`${capitalize(petParent.firstName)} needs to complete a check-in survey or ask a question for updates to appear. Hang tight.`}</Typography>
      </StyledPaper>
    );
  }

  const cards = combinedUpdatesSorted.map((u) => {
    let card = (
      <StyledPaper color={grey[50]}>
        <ErrorMessage color="black" />
      </StyledPaper>
    );
    if (u.__typename === 'DbTypeformResponse') {
      card = (
        <StyledPaper color={grey[50]} key={u.id}>
          <DateHeader
            title={<span dangerouslySetInnerHTML={{ __html: u.title }} />}
            date={u.submittedAt}
            isDesktop={isDesktop}
          />
          <List component="div" sx={{ pt: 0, pb: 0 }}>
            {u.answers.map((a) => (
              <React.Fragment key={a.id}>
                <ListItemText
                  primary={typeformMarkdownFormatter(a.question)}
                  primaryTypographyProps={{ variant: 'caption', sx: { color: 'rgba(0, 0, 0, 0.6)' } }}
                  secondary={a.text || 'N/A'}
                  secondaryTypographyProps={{ variant: 'body2', color: 'black' }}
                />
              </React.Fragment>
            ))}
          </List>
        </StyledPaper>
      );
    } else if (u.__typename === 'ZendeskQuestion') {
      card = (
        <ZendeskCard
          firstName={petParent.firstName}
          isDesktop={isDesktop}
          setDialogDate={setDialogDate}
          setDialogImg={setDialogImg}
          setImgDialogOpen={setImgDialogOpen}
          zendeskTicket={u}
        />
      );
    } else if (u.__typename === 'Update') {
      card = (
        <StyledPaper color={grey[50]} key={u.id}>
          <DateHeader title={u.title} date={u.dateUpdated} isDesktop={isDesktop} />
          {u.subtitle ? <Typography variant="subtitle2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{`${u.subtitle}`}</Typography> : null}
          {u.answers ? (
            <List component="div" sx={{ pt: 0, pb: 0 }}>
              {u.answers.map((a) => (
                <React.Fragment key={a.id}>
                  <ListItemText
                    primary={typeformMarkdownFormatter(a.question)}
                    primaryTypographyProps={{ variant: 'caption', sx: { color: 'rgba(0, 0, 0, 0.6)' } }}
                    secondary={a.text || 'N/A'}
                    secondaryTypographyProps={{ variant: 'body2', color: 'black' }}
                  />
                </React.Fragment>
              ))}
            </List>
          ) : null}
        </StyledPaper>
      );
    }
    return card;
  });

  return cards;
}

export default Sentry.withErrorBoundary(TypeformResponse, {
  fallback: (
    <Paper elevation={0} sx={{ p: 2, mt: 2, border: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <ErrorMessage color="black" />
    </Paper>
  ),
  beforeCapture,
});
