import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  Box,
  Divider,
  Grid, IconButton,
  List,
  Snackbar,
  Typography,
} from '@mui/material';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { ContentCopy } from '@mui/icons-material';
import * as yup from 'yup';
import { useOutletContext } from 'react-router-dom';
import { Awo } from '../../types/Awo';
import ActiveStatusPartial from '../FormPartials/ActiveStatusPartial';
import NamesPartial, { namesSchema } from '../FormPartials/NamesPartial';
import NotePartial from '../FormPartials/NotePartial';
import WebsitePartial, { websiteSchema } from '../FormPartials/WebsitePartial';
import LocationPartial, { locationSchema } from '../FormPartials/LocationPartial';
import Timezone, { timezoneSchema } from '../FormPartials/TimezonePartial';
import RescueTypePartial, { rescueTypeSchema } from '../FormPartials/RescueTypePartial';
import countriesOptions from '../../resources/countriesData';
import { shelterManagementSchema } from '../FormPartials/ShelterManagementPartial';
import AnimalsPartial, { courseSelectionSchema } from '../FormPartials/AnimalsPartial';
import SettingsListItem from '../SettingsListItem';
import { phoneFormatter, showPet24Controls } from '../../utilities';
import { colors } from '../../theme';
import DonationLinkPartial, { donationLinkSchema } from '../FormPartials/DonationLinkPartial';
import PhoneNumberPartial, { phoneNumberSchema } from './PhoneNumberPartial';
import AdoptionPacketDogsPartial, { adoptionPacketDogsSchema } from '../FormPartials/AdoptionPacketDogsPartial';
import AdoptionPacketCatsPartial, { adoptionPacketCatsSchema } from '../FormPartials/AdoptionPacketCatsPartial';
import FAQLinkPartial, { faqLinkSchema } from '../FormPartials/FAQLinkPartial';
import Pet24OrgNamePartial, { pet24OrgNameSchema } from '../FormPartials/Pet24OrgNamePartial';
import Pet24OrgIdPartial, { pet24OrgIdSchema } from '../FormPartials/Pet24OrgIdPartial';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const tz = dayjs.tz.guess();

function StatusRow({ color, value }: { color: string, value: string }) {
  return (
    <Grid item>
      <Typography
        component="span"
        sx={{
          position: 'absolute',
          width: '8px',
          height: '8px',
          mt: '6px',
          bgcolor: color,
          borderRadius: '8px',
        }}
      />
      <Typography sx={{ pl: 2 }} variant="subtitle2" color="secondary.text">{value}</Typography>
    </Grid>
  );
}

function SettingsPanel() {
  const { awo } = useOutletContext<{ awo: Awo }>();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [selectedCountryOption, setSelectedCountryOption] = React.useState<Record<'label' | 'value' | 'timezones' | 'states', any> | null>(null);

  useEffect(() => {
    const country = countriesOptions.find(
      (option) => option.value === awo.country || option.label === awo.country,
    );
    if (country) {
      setSelectedCountryOption(country);
    }
  }, [awo.country]);

  const supportedAnimals = useMemo(() => {
    const adopted = awo.dogAdopt || awo.catAdopt;
    const fostered = awo.dogFoster || awo.catFoster || awo.kittenFoster;
    const adoptedString = [awo.dogAdopt && 'dogs', awo.catAdopt && 'cats'].filter((item) => item).join(', ');
    const fosteredString = [awo.dogFoster && 'dogs', awo.catFoster && 'cats', awo.kittenFoster && 'kittens'].filter((item) => item).join(', ');
    return `${adopted ? `Adopted (${adoptedString})` : ''}; ${fostered ? `Fostered (${fosteredString})` : ''}`;
  }, [awo]);

  return (
    <>
      <Box sx={{ maxWidth: 800, width: '100%', mx: 'auto' }}>
        <Alert severity="info">Changes made to your settings will be applied to all members within your organization.</Alert>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={3}>
            <Box sx={{ pt: 3 }}>
              <Typography variant="overline" color="primary.text">Required Information</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ pl: { xs: 0 } }}>
            <List>
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Petcademy plan',
                  description: 'Change the status of this organization',
                  displayValue: awo.automationEnabled ? 'Active' : 'Deactivated',
                }]}
                defaultValues={{
                  activeStatus: awo.automationEnabled ? 'Active' : 'Deactivated',
                }}
                schema={yup.object({
                  activeStatus: yup.string().required('Active status is required'),
                })}
              >
                {(props) => (
                  <Grid item>
                    <ActiveStatusPartial {...props} />
                  </Grid>
                )}
              </SettingsListItem>
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Names',
                  description: 'Edit how this organization is labeled in different situations',
                  dialogDescription: 'Edit the name and abbreviation of this organization used in emails and SMS messages',
                  displayValue: awo.name,
                }]}
                defaultValues={{
                  name: awo.name,
                  displayName: awo.displayName,
                }}
                schema={yup.object(namesSchema)}
              >
                {() => (
                  <Grid item>
                    <NamesPartial />
                  </Grid>
                )}
              </SettingsListItem>
              <SettingsListItem
                awo={awo}
                headings={[
                  {
                    title: 'SMS number',
                    description: 'Phone number pet parents can text for support. Not editable',
                    displayValue: phoneFormatter(awo.twilioPhoneNumber, awo.country),
                  },
                ]}
                icon={(
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(phoneFormatter(awo.twilioPhoneNumber, awo.country));
                      setSnackbarMessage('SMS number copied');
                      setSnackbarOpen(true);
                    }}
                  >
                    <ContentCopy
                      sx={{ color: 'secondary.text' }}
                    />
                  </IconButton>
                )}
              />
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Location',
                  description: 'Change the address for this organization',
                  displayValue: [awo.city, awo.state, awo.zipCode, awo.country].filter((item) => item).join(', '),
                }]}
                defaultValues={{
                  country: awo.country,
                  streetAddress: awo.streetAddress,
                  city: awo.city,
                  state: awo.state,
                  postal: awo.zipCode,
                }}
                schema={yup.object(locationSchema)}
              >
                {(props) => (
                  <Grid item>
                    <LocationPartial
                      {...props}
                      defaultValues={{
                        country: awo.country,
                        address: awo.streetAddress,
                        city: awo.city,
                        state: awo.state,
                        postal: awo.zipCode,
                      }}
                      selectedCountryOption={selectedCountryOption}
                      setSelectedCountryOption={setSelectedCountryOption}
                    />
                  </Grid>
                )}
              </SettingsListItem>
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Timezone',
                  description: 'Change time zone used to determine when emails and SMS messages are sent',
                  displayValue: awo.timezone,
                }]}
                defaultValues={{
                  timezone: null,
                }}
                schema={yup.object(timezoneSchema)}
              >
                {(props) => (
                  <Grid item>
                    <Timezone
                      {...props}
                      defaultValues={{
                        timezone: awo.timezone,
                      }}
                      selectedCountryOption={selectedCountryOption}
                    />
                  </Grid>
                )}
              </SettingsListItem>
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Type',
                  description: 'Toggle the type of organization between rescue and shelter',
                  displayValue: awo.orgType,
                }]}
                defaultValues={{
                  rescueType: awo.orgType,
                }}
                schema={yup.object(rescueTypeSchema)}
              >
                {(props) => (
                  <Grid item>
                    <RescueTypePartial {...props} />
                  </Grid>
                )}
              </SettingsListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ pt: 3 }}>
              <Typography variant="overline" color="primary.text">Optional Information</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ pl: { xs: 0 } }}>
            <List>
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Note',
                  description: 'Edit the note for this organization',
                  displayValue: awo.note,
                }]}
                defaultValues={{
                  note: awo.note,
                }}
              >
                {() => (
                  <Grid item>
                    <NotePartial />
                  </Grid>
                )}
              </SettingsListItem>

              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Website',
                  description: 'Edit the website for this organization',
                  displayValue: awo.website,
                }]}
                defaultValues={{
                  website: awo.website,
                }}
                schema={yup.object(websiteSchema)}
              >
                {() => (
                  <Grid item>
                    <WebsitePartial />
                  </Grid>
                )}
              </SettingsListItem>

              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Donation link',
                  description: 'Edit the donation link for this organization',
                  displayValue: awo.donationLink,
                }]}
                defaultValues={{
                  donationLink: awo.donationLink,
                }}
                schema={yup.object(donationLinkSchema)}
              >
                {() => (
                  <Grid item>
                    <DonationLinkPartial />
                  </Grid>
                )}
              </SettingsListItem>

              <SettingsListItem
                awo={awo}
                headings={[
                  {
                    title: 'Phone number',
                    description: 'Edit the phone number for this organization',
                    displayValue: phoneFormatter(awo.orgPhoneNumber, awo.country),
                  },
                ]}
                defaultValues={{
                  orgPhoneNumber: awo.orgPhoneNumber,
                }}
                schema={yup.object(phoneNumberSchema)}
              >
                {({ setValue }) => (
                  <Grid item>
                    <PhoneNumberPartial
                      country={awo.country}
                      onPhoneNumberChange={(phoneNumber) => setValue('orgPhoneNumber', phoneNumber)}
                    />
                  </Grid>
                )}
              </SettingsListItem>

              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Frequently asked questions',
                  description: 'Edit the FAQ URL for this organization',
                  displayValue: awo.faqLink,
                }]}
                defaultValues={{
                  faqLink: awo.faqLink,
                }}
                schema={yup.object(faqLinkSchema)}
              >
                {() => (
                  <Grid item>
                    <FAQLinkPartial />
                  </Grid>
                )}
              </SettingsListItem>

              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Adoption packet for dogs',
                  description: 'Edit the adoption packet URL for this organization',
                  displayValue: awo.adoptionPacketLinkDogs,
                }]}
                defaultValues={{
                  adoptionPacketLinkDogs: awo.adoptionPacketLinkDogs,
                }}
                schema={yup.object(adoptionPacketDogsSchema)}
              >
                {() => (
                  <Grid item>
                    <AdoptionPacketDogsPartial />
                  </Grid>
                )}
              </SettingsListItem>

              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Adoption packet for cats',
                  description: 'Edit the adoption packet URL for this organization',
                  displayValue: awo.adoptionPacketLinkCats,
                }]}
                defaultValues={{
                  adoptionPacketLinkCats: awo.adoptionPacketLinkCats,
                }}
                schema={yup.object(adoptionPacketCatsSchema)}
              >
                {() => (
                  <Grid item>
                    <AdoptionPacketCatsPartial />
                  </Grid>
                )}
              </SettingsListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ pt: 2 }}>
              <Typography variant="overline" color="primary.text">External data link</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ pl: { xs: 0 } }}>
            <List sx={{ pt: 1 }}>
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Shelter management software',
                  description: 'Only engineers can edit the shelter management software once saved.',
                  dialogDescription: 'Change the software this organization uses to manage animals',
                  displayValue: awo.smsName,
                }]}
                defaultValues={{
                  smsName: awo.smsName,
                }}
                schema={yup.object(shelterManagementSchema)}
                icon={null}
              />
            </List>
            <Box sx={{ pl: 2, backgroundColor: colors.grey['50'], borderRadius: '4px' }}>
              <Typography variant="overline" color="primary.text">Status</Typography>
              {awo.status === 'success' ? (
                <Grid container direction="column" spacing={1}>
                  <StatusRow color="success.main" value={`Last sync: ${dayjs.utc(awo.lastSyncDate).tz(tz).format('M/D/YYYY [at] H:mm:ss a z')}`} />
                  <StatusRow color="success.main" value={`${awo.animalsUpdated} animals updated since last sync`} />
                  <StatusRow color="success.main" value="No backend errors" />
                </Grid>
              ) : (
                <Grid container direction="column" spacing={1}>
                  <StatusRow color="error.main" value={`Last sync: ${dayjs.utc(awo.lastSyncDate).tz(tz).format('M/D/YYYY [at] H:mm:ss a z')}`} />
                  <StatusRow color="error.main" value={`${awo.animalsUpdated} animals updated since last sync`} />
                  <StatusRow color="error.main" value="Backend Error:" />
                  <Grid item>
                    <Typography sx={{ fontFamily: 'Courier' }} variant="subtitle2" color="primary.text">{awo.failureReason}</Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
            {showPet24Controls(awo.smsName) && (
              <List sx={{ pt: 1 }}>
                <SettingsListItem
                  awo={awo}
                  headings={[{
                    title: '24Pet Org',
                    description: 'Edit the 24Pet Org for this organization',
                    displayValue: awo.externalOrgName || '',
                  }]}
                  defaultValues={{
                    externalOrgName: awo.externalOrgName || '',
                  }}
                  schema={yup.object(pet24OrgNameSchema)}
                >
                  {() => (
                    <Grid item>
                      <Pet24OrgNamePartial />
                    </Grid>
                  )}
                </SettingsListItem>
                <SettingsListItem
                  awo={awo}
                  headings={[{
                    title: '24Pet ID',
                    description: 'Edit the 24Pet ID for this organization',
                    displayValue: awo.externalOrgId || '',
                  }]}
                  defaultValues={{
                    externalOrgId: awo.externalOrgId || '',
                  }}
                  schema={yup.object(pet24OrgIdSchema)}
                >
                  {() => (
                    <Grid item>
                      <Pet24OrgIdPartial />
                    </Grid>
                  )}
                </SettingsListItem>
              </List>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ pt: 2 }}>
              <Typography variant="overline" color="primary.text">Animals</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ pl: { xs: 0 } }}>
            <List sx={{ pt: 1 }}>
              <SettingsListItem
                awo={awo}
                title="Supported animals"
                headings={[{
                  title: 'Supported animals',
                  description: 'Controls which Thinkific courses are available to pet parents; shows or hides pages from the AWO dashboard; selects the types of emails sent to pet parents; and more',
                  displayValue: awo.preSurrenderDashboard
                    ? 'This organization is pre-surrender'
                    : supportedAnimals,
                }]}
                defaultValues={{
                  preSurrenderDashboard: awo.preSurrenderDashboard,
                  dogAdopt: awo.dogAdopt,
                  catAdopt: awo.catAdopt,
                  dogFoster: awo.dogFoster,
                  catFoster: awo.catFoster,
                  kittenFoster: awo.kittenFoster,
                }}
                schema={yup.object(courseSelectionSchema)}
              >
                {(props) => (
                  <AnimalsPartial {...props} />
                )}
              </SettingsListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={snackbarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </>
  );
}

export default SettingsPanel;
