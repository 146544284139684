import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Box, Button, Card, CardContent, Skeleton, useMediaQuery, useTheme,
} from '@mui/material';
import { capitalize } from 'lodash';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import TaskCard from './TaskCard';
import { GET_NEWSFEED } from '../queries';
import ErrorMessage from './ErrorMessage';
import { beforeCapture, isInactiveAnimal } from '../utilities';

function SkeletonTaskCard() {
  return (
    <Card variant="outline" sx={{ backgroundColor: 'white' }}>
      <CardContent>
        <Box>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </Box>
      </CardContent>
    </Card>
  );
}

const ROWS_PER_PAGE = 25;

function NewsfeedList({
  newsfeedCount, setUserId, setDrawerOpen,
}) {
  const [page, setPage] = React.useState(0);
  const {
    loading, error, data, fetchMore,
  } = useQuery(GET_NEWSFEED, {
    variables: {
      offset: page * ROWS_PER_PAGE,
      limit: ROWS_PER_PAGE,
    },
  });

  const [showMoreNewsfeed, setShowMoreNewsfeed] = React.useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const indices = [];
  for (let i = 0; i < 10; i += 1) {
    indices.push(i);
  }

  if (loading) {
    return (
      <>
        {indices.map((i) => (
          <Box sx={{ pb: 3 }} key={i}>
            <SkeletonTaskCard />
          </Box>
        ))}
      </>
    );
  }
  if (error) throw error;

  const handleShowMoreNewsfeed = () => {
    if (isDesktop) {
      setPage(page + 1);
      fetchMore({
        variables: {
          offset: (page + 1) * ROWS_PER_PAGE,
          limit: ROWS_PER_PAGE,
        },
      });
    } else {
      setShowMoreNewsfeed(!showMoreNewsfeed);
    }
  };

  const newsfeedCards = data.newsfeed.map((nf) => {
    const animalText = nf.petParent.animals.filter((a) => !isInactiveAnimal(a)).map((a) => `${capitalize(a.name)} ${a.animalType === 'Dog' ? '🐶' : '🐱'}`).join(', ');
    return {
      id: nf.id,
      petParent: nf.petParent,
      actionText: nf.actionText,
      subtext: `${animalText ? `${animalText} • ` : ''}${moment(nf.dateUpdated).format('MMM D, YYYY')}`,
      nextStep: nf.nextStep,
      onClick: () => {
        setDrawerOpen(true);
        setUserId(nf.petParent.id);
      },
    };
  });

  return (
    <>
      {newsfeedCards.slice(0, isDesktop || showMoreNewsfeed ? newsfeedCards.length : 3).map((nfc) => (
        <Box sx={{ pb: 3 }} key={nfc.id}>
          <TaskCard
            completed={nfc.completed}
            petParent={nfc.petParent}
            actionText={nfc.actionText}
            subtext={nfc.subtext}
            nextStep={nfc.nextStep}
            actions={nfc.actions}
            onClick={nfc.onClick}
          />
        </Box>
      ))}
      {newsfeedCount > (page + 1) * ROWS_PER_PAGE ? (
        <Box sx={{ pb: 3 }}>
          <Button variant="text" onClick={handleShowMoreNewsfeed}>{`Show ${showMoreNewsfeed ? 'less' : 'more'}`}</Button>
        </Box>
      ) : null}
    </>
  );
}

export default Sentry.withErrorBoundary(NewsfeedList, { fallback: <ErrorMessage />, beforeCapture });
