import React from 'react';
import { useQuery } from '@apollo/client';
import { Awo } from '../../types/Awo';
import { GET_AWOS } from '../../queries';
import { OrganizationsTableFields } from '../../types/Tables';
import { UsePaginationReturn } from './usePagination';
import { UseFiltersReturn } from './useFilters';

type UseOrganizationProps = {
  filters: UseFiltersReturn;
  pagination: UsePaginationReturn;
  sorting: { order: 'desc' | 'asc' | undefined, orderBy: OrganizationsTableFields | undefined };
  searchText: string;
};

export default function useOrganization(
  {
    filters,
    pagination,
    sorting,
    searchText,
  }: UseOrganizationProps,
) {
  const {
    loading, error, data, networkStatus,
  } = useQuery<{ awos: Awo[], awosCount: { count: number } }>(GET_AWOS, {
    variables: {
      filters: {
        name: searchText,
        status: filters.applied.status.length > 0 ? filters.applied.status : undefined,
        smsName: filters.applied.smsName.length > 0 ? filters.applied.smsName : undefined,
        country: filters.applied.country.length > 0 ? filters.applied.country : undefined,
        state: filters.applied.state.length > 0 ? filters.applied.state : undefined,
      },
      offset: pagination.page * pagination.rowsPerPage,
      limit: pagination.rowsPerPage,
      order: sorting.order,
      orderBy: sorting.orderBy,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [organizations, setOrganizations] = React.useState<{ list: Awo[], count: number }>({
    list: [],
    count: 0,
  });

  React.useEffect(() => {
    if (data?.awos && !Number.isNaN(data?.awosCount?.count)) {
      setOrganizations({
        list: data.awos,
        count: data.awosCount.count,
      });
    }
  }, [data]);

  return {
    organizations,
    loading,
    error,
    networkStatus,
  };
}
