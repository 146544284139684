import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Alert, Button, Snackbar } from '@mui/material';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { FileDownloadOutlined } from '@mui/icons-material';
import { getCourse, getEnrollment, beforeCapture } from '../utilities';
import { GET_PET_PARENTS } from '../queries';

function DownloadDataChip({
  onboardingStatus, courseStatus, animalType, careType, daysAgo, order, orderBy, searchText, searchField,
}) {
  const [downloading, setDownloading] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [getPetParents, { error, data }] = useLazyQuery(GET_PET_PARENTS, {
    onError: (e) => {
      Sentry.withScope((scope) => {
        beforeCapture(scope, e);
        Sentry.captureException(e);
      });
      setSnackbarOpen(true);
      setDownloading(false);
    },
  });

  if (downloading && data && data.petParents) {
    const transformedData = data.petParents.map((pp) => ({
      'First Name': pp.firstName,
      'Last Name': pp.lastName,
      Email: pp.email,
      Course: getCourse(pp),
      'Date Invited': pp.mailchimpSyncDate ? moment(pp.mailchimpSyncDate).format('MM/DD/YYYY') : 'N/A',
      'Days Since Invite': pp.mailchimpSyncDate ? moment().startOf('day').diff(moment(pp.mailchimpSyncDate), 'days') : 'N/A',
      'Enrollment Status': pp.onboardDate ? 'Enrolled' : 'Invited',
      'Course Status': getEnrollment(pp),
    }));
    csvDownload(transformedData, `Pet_Parents_${new Date().toISOString()}.csv`);
    setDownloading(false);
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={error?.message}
      >
        <Alert severity="error">{'Error downloading CSV. We\'re working on it.'}</Alert>
      </Snackbar>
      <Button
        disabled={downloading}
        variant="outlined"
        size="small"
        startIcon={<FileDownloadOutlined />}
        onClick={() => {
          getPetParents({
            variables: {
              offset: 0,
              limit: 10000,
              onboardingStatus,
              courseStatus,
              animalType,
              careType,
              daysAgo,
              order,
              orderBy,
              searchText,
              searchField,
            },
          });
          setDownloading(true);
        }}
      >
        Download
      </Button>
    </>
  );
}

export default DownloadDataChip;
