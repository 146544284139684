import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from '@apollo/client/utilities';
import {
  createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,
} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import App from './App';
import vMessageTemplateVar from './components/SMS/VMessagesCache';

Sentry.init({
  dsn: 'https://5123581313ad41ebbd4d157ca97e3400@o1317948.ingest.sentry.io/6571447',
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ),
  }), new Sentry.Integrations.Breadcrumbs({
    console: process.env.NODE_ENV !== 'development',
  })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV === 'development' ? 'dev' : process.env.REACT_APP_CONTEXT,
  release: process.env.REACT_APP_COMMIT_REF,
});

const httpLink = createHttpLink({
  uri: '/.netlify/functions/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const tokenString = localStorage.getItem('ni.goTrueToken');
  const tokenJSON = tokenString ? JSON.parse(tokenString) : {};
  const token = tokenJSON?.access_token;
  const petcademyToken = localStorage.getItem('petcademyToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'awo-id': localStorage.getItem('awoId'),
      'petcademy-token': petcademyToken,
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    PetParent: {
      keyFields: ['id', 'careType', 'animalType'],
    },
    TicketReview: {
      keyFields: ['tagName'],
    },
    Query: {
      fields: {
        petParents: {
          ...offsetLimitPagination(['awoId', 'onboardingStatus', 'order', 'orderBy', 'smsStatus', 'interests']),
          read(existing, { args }) {
            const { offset, limit } = args as any;
            return existing && existing.slice(offset, offset + limit);
          },
        },
        newsfeed: {
          ...offsetLimitPagination(),
          merge(existing = [], incoming = []) {
            return [...existing, ...incoming];
          },
        },
        tasks: {
          keyArgs(args) {
            if (args?.startDaysAgo === -1) {
              return ['completed', 'startDaysAgo', 'endDaysAgo', 'careType'];
            }
            return ['completed', 'careType'];
          },
          merge(existing = [], incoming = []) {
            const ids = new Set<any[]>([]);
            const mergedArrays = [] as any[];
            const mergeFn = (e: any) => {
              // eslint-disable-next-line dot-notation,no-underscore-dangle
              if (!ids.has(e.__ref)) {
                // eslint-disable-next-line dot-notation,no-underscore-dangle
                ids.add(e.__ref);
                mergedArrays.push(e);
              }
            };
            existing.forEach(mergeFn);
            incoming.forEach(mergeFn);
            return mergedArrays;
          },
        },
      },
    },
    Campaign: {
      fields: {
        vMessageTemplate: {
          read() {
            return vMessageTemplateVar();
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
