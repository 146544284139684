import React from 'react';
import {
  Button,
  CardContent,
  Checkbox,
  Dialog,
  Box,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography, CardActions,
} from '@mui/material';
import { isEqual } from 'lodash';

function CourseFiltersDialog({
  open, setOpen, animalType, careType, onboardingStatus, courseStatus, setFilters,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="filter-dialog-title"
      aria-describedby="filter-dialog-description"
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: '90vw',
        },
      }}
    >
      <DialogTitle id="logout-dialog-title">
        <Typography variant="h6bold">Filters</Typography>
      </DialogTitle>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            alignItems: 'top',
          }}
        >
          <FormGroup sx={{ width: '100%' }}>
            <Typography variant="body1" sx={{ color: 'secondary.text' }}>Course</Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isEqual(animalType, ['Dog']) && careType === 'Adopted'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, animalType: e.target.checked ? ['Dog'] : null, careType: e.target.checked ? 'Adopted' : null }));
                  }}
                />
              )}
              label="Welcoming Your Adopted Dog Home"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isEqual(animalType, ['Cat']) && careType === 'Adopted'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, animalType: e.target.checked ? ['Cat'] : null, careType: e.target.checked ? 'Adopted' : null }));
                  }}
                />
              )}
              label="Welcoming Your Adopted Cat Home"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isEqual(animalType, ['Dog']) && careType === 'Foster'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, animalType: e.target.checked ? ['Dog'] : null, careType: e.target.checked ? 'Foster' : null }));
                  }}
                />
              )}
              label="Fostering Fundamentals For Dogs"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isEqual(animalType, ['Cat']) && careType === 'Foster'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, animalType: e.target.checked ? ['Cat'] : null, careType: e.target.checked ? 'Foster' : null }));
                  }}
                />
              )}
              label="Fostering Fundamentals For Cats"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isEqual(animalType, ['Kitten']) && careType === 'Foster'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, animalType: e.target.checked ? ['Kitten'] : null, careType: e.target.checked ? 'Foster' : null }));
                  }}
                />
              )}
              label="Fostering Fundamentals For Kittens"
            />
          </FormGroup>
          <FormGroup sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ color: 'secondary.text' }}>Enrollment Status</Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={onboardingStatus === 'onboarded'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, onboardingStatus: e.target.checked ? 'onboarded' : null }));
                  }}
                />
              )}
              label="Enrolled"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={onboardingStatus === 'invited'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, onboardingStatus: e.target.checked ? 'invited' : null }));
                  }}
                />
              )}
              label="Invited"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={onboardingStatus === 'invalid'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, onboardingStatus: e.target.checked ? 'invalid' : null }));
                  }}
                />
              )}
              label="Invalid Email"
            />
          </FormGroup>
          <FormGroup sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ color: 'secondary.text' }}>Course Status</Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={courseStatus === 'Not Started'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, courseStatus: e.target.checked ? 'Not Started' : null }));
                  }}
                />
              )}
              label="Not Started"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={courseStatus === 'Started'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, courseStatus: e.target.checked ? 'Started' : null }));
                  }}
                />
              )}
              label="Started"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={courseStatus === 'Completed'}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, courseStatus: e.target.checked ? 'Completed' : null }));
                  }}
                />
              )}
              label="Completed"
            />
          </FormGroup>
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={() => setOpen(false)}>Apply Filters</Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </CardActions>
    </Dialog>
  );
}

export default CourseFiltersDialog;
