import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';

function DateHeader({ title, date, isDesktop }: { title: string; date: string; isDesktop: boolean; }) {
  return isDesktop ? (
    <Grid container justifyContent="space-between">
      <Typography variant="subtitle2" align="left">{title}</Typography>
      <Typography variant="subtitle2" align="right" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{`${moment(date).format('M/D/YYYY')}`}</Typography>
    </Grid>
  ) : (
    <Box>
      <Typography display="block" variant="subtitle2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{`${moment(date).format('M/D/YYYY')}`}</Typography>
      <Typography display="block" variant="subtitle2">{title}</Typography>
    </Box>
  );
}

export default DateHeader;
