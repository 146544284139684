import React from 'react';
import { Add, Search } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_AWO_USERS } from '../../queries';
import { MembersTableFields } from '../../types/Tables';
import MembersTable from './MembersTable';
import MembersDialog from './MembersDialog';
import { Awo, AwoUser } from '../../types/Awo';
import SmallScreenPlaceholder from '../SmallScreenPlaceholder';
import CopyToClipboard from '../CopyToClipboard';

function MembersPanel({ awo }: { awo: Awo | Record<string, never> }) {
  const [orderBy, setOrderBy] = React.useState<MembersTableFields>('first_name');
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [searchText, setSearchText] = React.useState('');
  const [dialog, setDialog] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const params = useParams();
  const awoId = parseInt(params.organizationId || awo?.id || '', 10);
  const {
    loading,
    error,
    data,
    networkStatus,
    refetch,
  } = useQuery<{ awoUsers: AwoUser[] }>(GET_AWO_USERS, {
    variables: {
      awoId,
      order,
      orderBy,
      name: searchText,
    },
  });

  const handleChangeOrder = (_: any, field: MembersTableFields) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  if (loading) return null;
  if (error) throw error;

  const copyAllEmails = (copy: (message: string) => void) => () => {
    const emails = data?.awoUsers.map((user) => user.email);
    if (!emails) return;
    copy(emails.join(', '));
  };

  return (
    <>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid
          item
          xs={0}
          lg={12}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={3}>
              <TextField
                sx={{ mt: 0 }}
                fullWidth
                autoFocus
                margin="dense"
                name="text-search"
                label="Search by name"
                type="text"
                id="text-search"
                variant="standard"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="search">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <CopyToClipboard message="All email addresses copied">
                {
                  (copy) => (
                    <Button
                      style={{
                        borderColor: 'black',
                        color: 'black',
                      }}
                      size="small"
                      variant="outlined"
                      onClick={copyAllEmails(copy)}
                    >
                      Copy all emails
                    </Button>
                  )
                }
              </CopyToClipboard>
              <Button size="small" sx={{ marginLeft: 2 }} onClick={() => setDialog(true)} variant="contained" startIcon={<Add />}>
                New member
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ ml: -2, pt: 2 }}>
              <MembersTable
                handleChangeOrder={handleChangeOrder}
                order={order}
                orderBy={orderBy}
                loading={loading}
                members={data?.awoUsers || []}
                networkStatus={networkStatus}
                refetch={refetch}
              />
            </Grid>
          </Grid>
        </Grid>
        <SmallScreenPlaceholder />
      </Grid>
      {dialog ? (
        <MembersDialog
          title="Invite new team member"
          subtitle="Sends an invitation email to access the Petcademy dashboard"
          buttonText="Send invite"
          setDialog={setDialog}
          awoId={`${awoId}`}
          onSuccess={(message: string) => {
            setSnackbarOpen(true);
            setSnackbarMessage(message);
            setDialog(false);
            refetch();
          }}
        />
      ) : null}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={snackbarMessage}
        message={snackbarMessage}
      />
    </>
  );
}

export default MembersPanel;
