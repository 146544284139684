import { Box, Skeleton } from '@mui/material';
import React from 'react';

export default function ProfileSkeleton() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      py: 2,
      opacity: 0.5,
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        px: 2,
      }}
      >
        <Skeleton sx={{ mb: 1 }} height={48} width={300} variant="text" />
        <Skeleton height={40} variant="text" />
        <Skeleton height={40} variant="text" />
        <Skeleton height={40} variant="text" />
        <Skeleton height={40} variant="text" />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 2,
        borderRadius: '4px',
      }}
      >
        <Skeleton height={184} variant="text" />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 2,
        borderRadius: '4px',
      }}
      >
        <Skeleton height={184} variant="text" />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 2,
        borderRadius: '4px',
      }}
      >
        <Skeleton height={184} variant="text" />
      </Box>
    </Box>
  );
}
