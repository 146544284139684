import React from 'react';
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from '@mui/material';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { useNavigate } from 'react-router-dom';
import SidebarPage from '../SidebarPage';

function ForgotPassword() {
  const identity = useIdentityContext();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);
  const [makingRequest, setMakingRequest] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setMakingRequest(true);
    identity.sendPasswordRecovery({ email }).then(() => {
      setMakingRequest(false);
      navigate('/forgot-submission', { replace: true });
    }).catch((e) => setError(e.message));
  };

  return (
    <SidebarPage
      mobileHeader="Reset Password"
      greenWord="Login"
      headerText=" to your Petcademy account"
      handleSubmit={handleSubmit}
      FormComponent={(
        <>
          <Box sx={{
            width: {
              xs: '100%',
              sm: '327px',
            },
          }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              helperText="Your email address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error ? (
              <Typography variant="caption" color="error.main">{error}</Typography>
            ) : null}
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={email.length === 0 || makingRequest}
            sx={{ mt: 3, mb: 2, mr: 2 }}
          >
            Reset Password
          </Button>
        </>
      )}
    />
  );
}

export default ForgotPassword;
