import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box, Button, Collapse, IconButton, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField, Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { GET_TYPEFORM_FORMS, ADD_TYPEFORM_FORM } from '../../queries';

function TypeformRow({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.typeformId}</TableCell>
        <TableCell>{row.typeformTitle}</TableCell>
        <TableCell>{row.responseTitle}</TableCell>
        <TableCell>{row.createdAt}</TableCell>
        <TableCell>{row.lastUpdatedAt}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography variant="h5">Questions</Typography>
              <List>
                {row.questions.map((q) => (
                  <ListItem key={q.id}>
                    <ListItemText primary={q.title} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function AdminContainer() {
  const [formId, setFormId] = React.useState('');
  const [title, setTitle] = React.useState('');
  const { loading, error, data } = useQuery(GET_TYPEFORM_FORMS);
  const [addTypeform] = useMutation(ADD_TYPEFORM_FORM, {
    variables: {
      id: formId,
      title,
    },
    refetchQueries: [
      'GetTypeformForms',
    ],
  });

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <Box sx={{
      pl: {
        xs: 2,
        lg: 3,
      },
      pr: {
        xs: 2,
        lg: 3,
      },
      pb: {
        xs: 2,
        lg: 3,
      },
      pt: {
        xs: 10,
        sm: 3,
      },
    }}
    >
      <Box sx={{ mb: 2 }}>
        <TextField
          sx={{ width: '327px', mr: 2 }}
          margin="dense"
          required
          name="formId"
          label="Form ID"
          type="text"
          id="formId"
          autoComplete="form-id"
          value={formId}
          onChange={(e) => setFormId(e.target.value)}
          helperText="Enter the form id of a typeform to input it into our system"
        />
        <TextField
          sx={{ width: '327px', mr: 2 }}
          margin="dense"
          required
          fullWidth
          name="title"
          label="Title"
          type="text"
          id="title"
          autoComplete="title-id"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          helperText="Enter the title for this typeform that should be displayed in the UI"
        />
        <Button
          type="submit"
          variant="contained"
          disabled={formId.length === 0 || title.length === 0}
          sx={{ mt: '1.2rem' }}
          onClick={() => addTypeform()}
        >
          Submit
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '500px' }} aria-label="Typeform Table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Typeform ID</TableCell>
              <TableCell>Typeform Title</TableCell>
              <TableCell>Display Title</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Last Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && data?.typeformForms.map((row) => (
              <TypeformRow key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AdminContainer;
