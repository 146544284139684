import React from 'react';
import {
  Box, Skeleton, Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { cloneDeep, isNil, remove } from 'lodash';
import { useParams } from 'react-router-dom';
import { GET_TICKETS_TO_REVIEW } from '../../queries';
import TagReviewSearch from './TagReviewSearch';
import { TicketReview } from '../../types/TicketReview';
import TicketDisplay from './TicketDisplay';
import withProfile from '../withProfile';
import TagReviewLoader from './TagReviewLoader';
import SmallScreenPlaceholder from '../SmallScreenPlaceholder';
import { FLAGGED } from '../../utilities';

function TagListLoader() {
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: '16px', padding: '18px 16px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Skeleton height={50} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Skeleton height={50} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '19px 24px',
        }}
      >
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </Box>
    </Box>
  );
}

interface TagReviewContainerProps {
  setDrawerOpen: (value: boolean) => void,
  setUserId: (id: number) => void
}

function TagReviewContainer({ setDrawerOpen, setUserId }: TagReviewContainerProps) {
  const params = useParams();
  const [activeTag, setActiveTag] = React.useState<TicketReview | null>(null);
  const [ticketId, setTicketId] = React.useState<string | null>(
    params.ticketId ? params.ticketId : (activeTag?.firstQuestion?.ticketId || null),
  );
  const [ticketSearch, setTicketSearch] = React.useState<Record<string, any>>();
  const [resultsCount, setResultsCount] = React.useState(0);
  const [customSearchActive, setCustomSearchActive] = React.useState(false);

  const { loading, data } = useQuery<{
    ticketsToReview: TicketReview[],
    questionCount: { count: number }
  }>(GET_TICKETS_TO_REVIEW);

  React.useMemo(() => {
    if (data && activeTag === null && !params.ticketId) {
      setActiveTag(data.ticketsToReview.find((t) => t.tagName === 'flagged') || FLAGGED);
    }
    if (data && activeTag !== null) {
      setTicketId(activeTag.firstQuestion.ticketId);
    }
    if (data && !isNil(data.questionCount)) {
      setResultsCount(data.questionCount.count);
    }
  }, [data, activeTag, setActiveTag, params.ticketId]);

  const ticketsToReview = cloneDeep(data?.ticketsToReview || []);
  let flagged = remove(ticketsToReview, (t) => t.tagName === 'flagged');
  if (flagged.length === 0) {
    flagged = [FLAGGED];
  }
  const orderedTickets = flagged.concat(ticketsToReview);

  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <Box sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <Box
            sx={{
              padding: 3,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="overline">Tag Review</Typography>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)' }} variant="caption">{`${resultsCount.toLocaleString()} tickets ${customSearchActive ? 'meet criteria' : 'need review'}`}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <Box sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', maxWidth: '400px', width: '100%' }}>
              {
                loading || !data ? <TagListLoader /> : (
                  <TagReviewSearch
                    activeTagName={activeTag && !params.ticketId ? activeTag.tagName : 'none'}
                    handleSelectCategory={setActiveTag}
                    ticketsToReview={orderedTickets}
                    setTicketId={setTicketId}
                    setTicketSearch={setTicketSearch}
                    setResultsCount={setResultsCount}
                    initResultsCount={resultsCount}
                    setCustomSearchActive={setCustomSearchActive}
                  />
                )
              }
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ padding: '16px 24px' }}>
                {
                  loading || !data
                    ? <TagReviewLoader />
                    : (
                      <TicketDisplay
                        id={ticketId}
                        ticketSearch={ticketSearch}
                        tag={activeTag && !params.ticketId ? activeTag : { tagName: 'none', unreviewedCount: 0, firstQuestion: { ticketId: '0' } }}
                        setDrawerOpen={setDrawerOpen}
                        setUserId={setUserId}
                        setResultsCount={setResultsCount}
                        customSearchActive={customSearchActive}
                      />
                    )
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: 3,
          minHeight: '100vh',
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <Typography variant="overline">Tag Review</Typography>
        <Box sx={{ pt: 3 }}>
          <SmallScreenPlaceholder text="To view this page, access Petcademy on a larger screen" />
        </Box>
      </Box>
    </>
  );
}

export default withProfile(TagReviewContainer);
