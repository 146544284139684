import React from 'react';
import {
  Dialog, Divider, Typography,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import ChangeNameDialogForm from './ChangeNameDialogForm';
import { Animal } from '../../types/Animal';
import { UPDATE_PET_PARENT_ANIMAL } from '../../queries';

function ChangeNameDialog({ petParent, animal, onClose }: { petParent: any, animal: Animal; onClose: any }) {
  const [updatePetParentAnimal, { client }] = useMutation(UPDATE_PET_PARENT_ANIMAL);
  const formSubmitHandler = async ({ name }: { name?: string }) => {
    const newData = {
      ...petParent,
      animals: [{
        ...animal,
        birthDate: animal.birthDate ? dayjs(animal.birthDate).format('M/D/YYYY') : null,
        exitDate: animal.exitDate ? dayjs(animal.exitDate).format('M/D/YYYY') : null,
        name,
        awo: {
          name: petParent.awoName,
        },
      }],
    };
    updatePetParentAnimal({
      variables: {
        oldData: petParent,
        newData,
      },
    });
    client.writeFragment({
      id: `Animal:${animal.id}`,
      fragment: gql`
        fragment MyAnimal on Animal {
          name
        }
      `,
      data: {
        name,
      },
    });
    mixpanel.track('pet_name_save_click', {
      old_pet_name: animal?.name,
    });
    onClose();
  };

  return (
    <Dialog
      open={Boolean(animal)}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: 'calc(100vw - 32px)',
          margin: '16px',
        },
      }}
    >
      <Typography variant="sectionTitle" sx={{ lineHeight: '26.5px', px: 3, py: 2.5 }}>Change pet name</Typography>
      <ChangeNameDialogForm
        name={animal?.name}
        onSubmit={formSubmitHandler}
        onClose={onClose}
      />
    </Dialog>
  );
}

export default ChangeNameDialog;
