import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Box, Button, Fab, FormControlLabel, Switch, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowUpward } from '@mui/icons-material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { GET_AWO_USERS, GET_DASHBOARD } from '../../queries';
import withProfile from '../withProfile';
import DashboardStats from './DashboardStats';
import TasksList from '../TasksList';
import NewsfeedList from '../NewsfeedList';
import TaskFiltersDialog from '../TaskFiltersDialog';
import { useAwoContext } from '../AwoContextProvider';
import CoordinatorFiltersDialog from '../CoordinatorFiltersDialog';

function DashboardContainer({
  drawerOpen, setDrawerOpen, setUserId,
}: {
  drawerOpen: boolean,
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setUserId: React.Dispatch<React.SetStateAction<number | null>>
}) {
  const { awo } = useAwoContext();
  const isPreSurrender = awo?.preSurrenderDashboard;
  const isCoSheltering = awo?.coSheltering;
  const isCoShelteringCoordinators = awo?.coShelteringCoordinators;
  const awoId = parseInt(awo?.id || '', 10);

  const { loading, error, data } = useQuery(GET_DASHBOARD);
  const { data: coordinatorsData } = useQuery(GET_AWO_USERS, {
    variables: {
      awoId,
      order: 'asc',
      orderBy: 'first_name',
    },
    skip: !isCoShelteringCoordinators,
  });

  const coordinators = coordinatorsData?.awoUsers || [];

  const [refetch, setRefetch] = React.useState(false);
  const [y, setY] = React.useState(0);
  const [lastY, setLastY] = React.useState(0);
  const [showCompleted, setShowCompleted] = React.useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);

  const [coordinatorFilterDialogOpen, setCoordinatorFilterDialogOpen] = React.useState(false);
  const [coordinatorFilters, setCoordinatorFilters] = React.useState<Set<string>>(new Set(JSON.parse(localStorage.getItem('coordinatorFilters') || '[]')));

  const [careType, setCareType] = React.useState<{
    adopted: boolean,
    fostered: boolean,
    preSurrender: boolean,
  } | null>(JSON.parse(localStorage.getItem('notificationCareType') || 'null'));
  const [appliedCareType, setAppliedCareType] = React.useState<{
    adopted: boolean,
    fostered: boolean,
    preSurrender: boolean,
  } | null>(JSON.parse(localStorage.getItem('notificationCareType') || 'null'));

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  React.useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY));
  }, []);

  React.useEffect(() => {
    if (drawerOpen && !isDesktop) {
      window.scrollTo(0, 0);
      setLastY(y);
    } else if (!isDesktop) {
      window.scrollTo(0, lastY);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  React.useEffect(() => {
    if (data?.awoUser && !appliedCareType) {
      const notificationStatuses = {
        adopted: data.awoUser.adoptionNotificationEmail,
        fostered: data.awoUser.fosterNotificationEmail,
        preSurrender: data.awoUser.preSurrenderNotificationEmail,
      };
      setAppliedCareType(notificationStatuses);
      setCareType(notificationStatuses);
    }
  }, [appliedCareType, data]);

  if (loading) return null;
  if (error) throw error;

  const grids = [(
    <Grid
      key="dashboard"
      xs={12}
      sx={{
        padding: 3,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="overline">Dashboard</Typography>
      </Box>
      <DashboardStats
        triggerRefetch={refetch}
        setRefetch={setRefetch}
        careType={appliedCareType ? `${appliedCareType.adopted ? 'Adopted' : ''}${appliedCareType.fostered ? 'Fostered' : ''}${appliedCareType.preSurrender ? 'PreSurrender' : ''}` : null}
      />
    </Grid>
  )];

  const filtersLength = Object.values(appliedCareType || {}).filter((value) => value).length;

  if (data.taskCount.count === 0 && data.newsfeedCount.count === 0) {
    grids.push((
      <Grid
        key="no-data"
        xs={12}
        sx={{
          padding: 3,
          borderTop: 1,
          borderColor: 'primary.50',
        }}
      >
        <Box>
          <Typography color="primary" variant="body1" display="inline" component="span">
            No feedback or tasks right now. Updates will appear here after pet parents complete courses and check-in
            surveys.
          </Typography>
        </Box>
      </Grid>
    ));
  } else {
    grids.push((
      <Grid
        key="newsfeed"
        xs={12}
        md={6}
        sx={{
          padding: 3,
          borderRight: {
            xs: 0,
            md: 1,
          },
          borderTop: 1,
          borderColor: {
            xs: 'primary.50',
            md: 'primary.50',
          },
        }}
      >
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Typography variant="overline">Newsfeed</Typography>
        </Box>
        <NewsfeedList
          newsfeedCount={data.newsfeedCount.count}
          setUserId={setUserId}
          setDrawerOpen={setDrawerOpen}
        />
      </Grid>
    ));

    const taskGrid = (
      <Grid
        key="tasks"
        xs={12}
        md={6}
        sx={{
          padding: 3,
          borderTop: 1,
          borderBottom: {
            xs: 1,
            md: 0,
          },
          borderColor: {
            xs: 'primary.50',
            lg: 'primary.50',
          },

        }}
      >
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Typography variant="overline">Tasks</Typography>
        </Box>
        <Box justifyContent={isCoSheltering && !isCoShelteringCoordinators ? 'flex-end' : 'space-between'} alignItems="center" sx={{ display: 'flex', pb: 2 }}>
          {!isPreSurrender && !isCoSheltering && (
            <Button
              startIcon={<FilterAltOutlinedIcon />}
              variant="outlined"
              size="small"
              onClick={() => setFilterDialogOpen(true)}
            >
              {`Filters ${[1, 2].includes(filtersLength) ? `(${Object.values(appliedCareType || {}).filter((value) => value).length})` : ''}`}
            </Button>
          )}
          {isCoSheltering && coordinators.length > 0 && (
            <Button
              startIcon={<FilterAltOutlinedIcon />}
              variant="outlined"
              size="small"
              onClick={() => setCoordinatorFilterDialogOpen(true)}
            >
              {`Filters ${coordinatorFilters.size > 0 ? `(${coordinatorFilters.size})` : ''}`}
            </Button>
          )}
          <Box display="inline">
            <FormControlLabel
              sx={{ marginRight: 0, paddingRight: 0, color: 'primary.main' }}
              control={(
                <Switch
                  size="small"
                  checked={showCompleted}
                  onChange={() => setShowCompleted(!showCompleted)}
                  color="primary"
                />
                )}
              label="Show Completed"
            />
          </Box>
        </Box>
        <TasksList
          setRefetch={setRefetch}
          setUserId={setUserId}
          setDrawerOpen={setDrawerOpen}
          showCompleted={showCompleted}
          careType={appliedCareType ? `${appliedCareType.adopted ? 'Adopted' : ''}${appliedCareType.fostered ? 'Fostered' : ''}${appliedCareType.preSurrender ? 'PreSurrender' : ''}` : null}
          coordinatorFilters={coordinatorFilters}
        />
      </Grid>
    );

    if (isDesktop) {
      grids.push(taskGrid);
    } else {
      grids.unshift(taskGrid);
    }
  }

  return (
    <Box>
      <Grid container>
        {grids}
      </Grid>
      <TaskFiltersDialog
        open={filterDialogOpen}
        setOpen={setFilterDialogOpen}
        careType={careType}
        setCareType={setCareType}
        setAppliedCareType={setAppliedCareType}
      />
      <CoordinatorFiltersDialog
        coordinators={coordinators}
        open={coordinatorFilterDialogOpen}
        setOpen={setCoordinatorFilterDialogOpen}
        coordinatorFilters={coordinatorFilters}
        setCoordinatorFilters={setCoordinatorFilters}
      />
      {y > 0 ? (
        <Fab
          onClick={() => window.scrollTo(0, 0)}
          aria-label="scroll-to-top"
          size="small"
          color="primary"
          sx={{
            position: 'fixed',
            bottom: 32,
            right: `${drawerOpen ? 'calc(33% + 32px)' : '32px'}`,
          }}
        >
          <ArrowUpward />
        </Fab>
      ) : null}
    </Box>
  );
}

export default withProfile(DashboardContainer);
