import React from 'react';
import { Box, SxProps } from '@mui/material';

function CustomContainer({ children, sx }: { children: React.ReactNode, sx?: SxProps }) {
  return (
    <Box
      sx={{
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        ...sx,
        maxWidth: 900,
        mx: 'auto',
      }}
    >
      {children}
    </Box>
  );
}

export default CustomContainer;
