import React from 'react';
import { useQuery } from '@apollo/client';
import AskBadge from './AskBadge';
import CustomContainer from './CustomContainer';
import { GET_HUB_PET_PARENT } from '../../queries';

function Ask() {
  const { data, loading } = useQuery(GET_HUB_PET_PARENT);
  if (loading) return null;
  return (
    <CustomContainer sx={{ pt: 4, gap: 2 }}>
      <AskBadge
        phoneNumber={data.petParent.awo.twilioPhoneNumber}
        inactiveMembership={data.petParent.startedDaysAgo > 30}
      />
    </CustomContainer>
  );
}

export default Ask;
